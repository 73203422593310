import { Typography } from '@mui/material';
import { Box, display, maxHeight } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import dottedLine from '../../../assets/images/dotted-line.png';
import './TraitAnimation/Card.css';
import { ModeCtx } from '../../../context/modeCtx';
import { useContext } from 'react';

const BP1 = '@media (max-width: 560px)';
const BP2 = '@media (max-width: 350px)';
const BP3 = '@media (max-width: 690px)';
const sx = {
  root: {
    margin: 'auto',
    marginTop: '15px',
    marginBottom: '30px',
    maxWidth: '848px',
    width: '100%',
    maxHeight: '86px',
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#FFFFFF',
    webkitBoxShadow: '0px 3px 6px 0px rgba(0,0,0,0.1)',
    mozBoxShadow: '0px 3px 6px 0px rgba(0,0,0,0.1)',
    boxShadow: '0px 3px 6px 0px rgba(0,0,0,0.1)',
    borderRadius: '10px',
    overflow: 'hidden',
    [BP1]: {},
  },
  imageHolder: {
    width: '100%',
    display: 'flex',
    height: '100%',
    [BP1]: {
      display: 'none',
    },
  },
  content: {
    display: 'flex',
    width: '48%',
    gap: '16px',
  },
  contentLeft: {
    display: 'flex',
    width: '48%',
    gap: '16px',
    cursor: 'pointer',
    position: 'relative',
    [BP1]: {
      justifyContent: 'center',
      alignItems: 'center',
    },
  },

  itemHolder: {
    py: '8px',
    textTransform: 'uppercase',
    position: 'absolute',
    [BP1]: {
      position: 'relative',
    },
  },
  titleText: {
    cursor: 'pointer',
    color: '#707070',
  },
  contentRight: {
    display: 'flex',
    width: '48%',
    gap: '16px',
    justifyContent: 'flex-end',
    cursor: 'pointer',
    position: 'relative',
    [BP1]: {
      justifyContent: 'center',
      alignItems: 'center',
    },
  },

  namePrev: {
    fontFamily: 'poppins-semibold',
    lineHeight: '20px',
    fontSize: '18px',
    maxHeight: '45px',
    width: '100%',
    // maxWidth: '180px',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [BP1]: {
      display: 'none',
    },
  },
  nameNext: {
    fontFamily: 'poppins-semibold',
    lineHeight: '20px',
    fontSize: '18px',
    maxHeight: '45px',
    width: '100%',
    // maxWidth: '180px',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [BP1]: {
      display: 'none',
    },
  },
  lineHolder: {
    width: '4%',
    minHeight: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& img': {
      height: '72px',
      [BP1]: {
        height: '50px',
      },
    },
  },
  // textContent: {
  //   [BP3]: {
  //     maxWidth: '150px',
  //   },
  // },
};

const PreviousNextBox = ({
  previousProject,
  nextProject,
  prevId,
  nextId,
  url
}) => {
  
  const modeCtx = useContext(ModeCtx)
  const history = useHistory();
  const route = history.location.pathname;
  const routeArray = route.split('/');
  const projectUrl = routeArray[1];
  const sessionId = routeArray[3];
  const [imgWidth, setImgWidth] = useState(0);

  const openAnotherCard = (id) => {
    if (projectUrl === 'preview') {
      window.open(`#/preview/projects/${sessionId}/${id}`, '_self');
    } else {
      //window.open(`${url}/${id}`, '_self')
      history.push(`${url}/${id}`);
      ;
    }
   // location.reload();
  };

  const onImgLoad = ({ target: img }) => {
    setImgWidth(img.offsetWidth);
  };

  return (
    <Box sx={sx.root} style={{background: !modeCtx.lightTheme ? "linear-gradient(rgb(20, 33, 46), rgb(14, 25, 36))" : "#fff"}}>
      {previousProject  ? (
        <Box
          sx={sx.contentLeft}
          onClick={() => openAnotherCard(prevId)}
          className="previousBox"
        >
          <Box sx={sx.imageHolder}>
            <img
              onLoad={onImgLoad}
              src={previousProject.image}
              alt=""
              style={{ height: '100%', justifyContent: 'start' }}
            />{' '}

            {(previousProject.overlays && previousProject.overlays.length>0) &&
              <img
                style={{
                  zIndex: '1',
                  height: '100%', justifyContent: 'start',
                  pointerEvents: 'none',
                  position:'absolute',
                  top: '0',
                  left:'0'
                }}
                src={previousProject.overlays[0].layers[0]}
                alt="Card overlay"
              />
            }


          </Box>
          <Box
            sx={sx.itemHolder}
            style={{
              textAlign: 'start',
              marginLeft: imgWidth + 16,
            }}
          >
            <Typography
              variant="minuscule"
              sx={sx.titleText}
              className="prevtitleText"
              style={{color:!modeCtx.lightTheme && "#fff9"}}
            >
              Previous
            </Typography>
            <Typography
              sx={sx.namePrev}
              style={{
                textAlign: 'start',
                marginLeft: '0',
                color:!modeCtx.lightTheme && "#fff9"
              }}
            >
              {previousProject.name.length > 25
                ? previousProject.name.substring(0, 24) + '...'
                : previousProject.name}
            </Typography>
          </Box>
        </Box>
      ) : (
        <Box sx={sx.content}></Box>
      )}
      <Box sx={sx.lineHolder}>
        <img src={dottedLine} alt="" />
      </Box>
      {nextProject ? (
        <Box
          sx={sx.contentRight}
          onClick={() => openAnotherCard(nextId)}
          className="nextBox"
        >
          <Box
            sx={sx.itemHolder}
            style={{
              textAlign: 'end',
              marginRight: imgWidth + 16,
            }}
          >
            <Typography
              variant="minuscule"
              sx={sx.titleText}
              className="titleText"
              style={{color:!modeCtx.lightTheme && "#fff9"}}
            >
              Next
            </Typography>
            <Typography
              sx={sx.nameNext}
              style={{ textAlign: 'end', marginRight: '0', color:!modeCtx.lightTheme && "#fff9" }}
            >
              {nextProject.name.length > 25
                ? nextProject.name.substring(0, 24) + '...'
                : nextProject.name}
            </Typography>
          </Box>
          <Box sx={sx.imageHolder} style={{ justifyContent: 'flex-end' }}>
            <img
              src={nextProject.image}
              alt=""
              height={'100%'}
              onLoad={onImgLoad}
            />
            {(nextProject.overlays && nextProject.overlays.length>0) &&
              <img
                style={{
                  zIndex: '1',
                  height: '100%', justifyContent: 'start',
                  pointerEvents: 'none',
                  position:'absolute',
                  top: '0',
                  right:'0'
                }}
                src={nextProject.overlays[0].layers[0]}
                alt="Card overlay"
              />
            }
          </Box>
        </Box>
      ) : (
        <Box sx={sx.content}></Box>
      )}
    </Box>
  );
};

export default PreviousNextBox;
