import React, { useContext, useEffect, useState } from 'react';
import DataContext, { Data } from './DataContext';
import ExplorerWrapper from './ExplorerWrapper';


const CollectionExplorer = ({projectId,metaDataServer,reviewMode}) => {
  return (
	<DataContext>
       <ExplorerWrapper projectId={projectId} reviewMode={reviewMode} metaDataServer={metaDataServer}/>
    </DataContext>

  );
};
export default CollectionExplorer;
