import { useContext, useState } from 'react';
import { SpinnerCircular } from 'spinners-react';
import './ExplorerCard.css';
import { LazyImage } from './LazyImage';
import { Data } from './DataContext';
import { ConnectingAirportsOutlined } from '@mui/icons-material';
import config from '../../config/config';

const ExplorerCard = ({ card, projectId, showToken, reviewMode }) => {
    const [loading, setLoading] = useState(true);
    const { showOverlayForCard, setTheChosenCard } = useContext(Data);

    //console.log(card);

    const CardDetails = () => {
        return (
            <div className="card-details">
                <ul>
                    {card.attributes.map((attribute) => {
                        return (
                            <li
                                style={{ paddingTop: '15px' }}
                                key={attribute.trait_type}
                            >
                                <p className="trait-type">
                                    {Object.values(attribute)[1]}
                                </p>
                                <p className="trait-value">
                                    {Object.values(attribute)[0]}
                                </p>
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    };

    const choseCards = () => {
        card.image = reviewMode?card.sides[0].image:`${config.PREVIEW_IMAGES}${projectId}/1/generated/original/${card.sides[0].dna}.jpg`;

        if(!reviewMode){
            for (let i = 0; i < card.sides.length; i++) {
                card.sides[
                    i
                ].image = `${config.PREVIEW_IMAGES}${projectId}/${card.sides[i].id}/generated/original/${card.sides[i].dna}.jpg`;
            }
        }

        // console.log('THE CARD', card);
        setTheChosenCard(card);
        showOverlayForCard(true);
    };

    return (
        <div className="root" onClick={choseCards}>
            <div className="image-container">
                <div style={{ position: 'relative', minHeight: '100px' }}>
                    <SpinnerCircular
                        color="#000"
                        style={{
                            display: loading ? 'block' : 'none',
                            margin: 'auto',
                            position: 'absolute',
                            left: 0,
                            right: 0,
                            top: 0,
                            bottom: 0,
                        }}
                    />
                    <CardDetails />
                    <LazyImage
                        src={reviewMode?card.sides[0].image:`${config.PREVIEW_IMAGES}${projectId}/1/generated/original/${card.sides[0].dna}.jpg`}
                        imageOnLoad={() => setLoading(false)}
                        alt="Card"
                    />
                </div>
                {!loading && (
                    <div className="card-content" style={{ zIndex: '1' }}>
                        <p className="card-title">COLLECTION / ID</p>
                        <div style={{ display: 'flex', gap: '5px' }}>
                            <p className="card-name">{card.collection_name}</p>
                            {showToken && (
                                <p
                                className='card-id'
                                >
                                    #{card.tokenId}
                                </p>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ExplorerCard;
