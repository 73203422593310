import React from 'react';
import { Box, Typography } from '@mui/material';
import { ModeCtx } from '../../../context/modeCtx';
import { useContext } from 'react';
const sx = {
	root: {
		mt: '5px',
		width: '100%',
		minHeight: '80px',
		maxHeight: '80px',
		display: 'flex',
		justifyContent: 'flex-start',
		backgroundColor: '#1C1D22',
		p: '10px',
		borderRadius: '5px'
	},
	content: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
	},
	label: {
		fontFamily: 'poppins-medium',
		color: '#707070',
		textTransform: 'uppercase',
		fontSize: '12px',
		lineHeight: '15px',
		fontWeight: 'bold'
	},
	value: {
		fontFamily: 'poppins-medium',
		color: '#F8FBFD',
		fontSize: '16px',
		// '&::after': {
		// 	content: '"..."'
		// }
		display: '-webkit-box',
		WebkitBoxOrient: 'vertical',
		WebkitLineClamp: 2,
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		maxWidth: '180px',
		pt: '5px',
		lineHeight: '1.3'
	}
};

const AttributesCard = ({
	trait,
}) => {
	const modeCtx = useContext(ModeCtx);
	return (
		<Box sx={{ ...sx.root }} style={{background: !modeCtx.lightTheme ? "linear-gradient(rgb(20, 33, 46), rgb(14, 25, 36))" : "#fff"}}>
			<Box sx={{ ...sx.content, borderLeft: '13px' }} >
				<Typography sx={sx.label}  style={{color:!modeCtx.lightTheme && "#fff9"}}>{trait.trait_type}</Typography>

				<Typography sx={sx.value}  style={{color:!modeCtx.lightTheme && "#fff9"}}> {trait.value}</Typography>
			</Box>
		</Box>
	)
};

export default AttributesCard;
