import { Box, Button, ButtonGroup, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Contract } from '@ethersproject/contracts';
import { DateTime } from 'luxon';
import config from '../../config/config';
import { ethers } from 'ethers';
import { toast } from 'react-toast';

import useInterval from '../../hooks/useInterval';

import galaxis from '../../assets/images/galaxis.svg';
import InfoBox from './components/InfoBox';
import leftTrick from '../../assets/images/leftTrick.svg';
import rightTrick from '../../assets/images/rightTrick.svg';
import Counter from './components/Counter/Counter';
import Timer from './components/Timer';
import useWeb3Ctx from '../../hooks/useWeb3Ctx';

import saleABI from './abi/SaleV2.json';
import tokenABI from './abi/TokenV2.json';
import CheckoutModal from './components/CheckoutModal';
import BackdropModal from './components/BackdropModal';
import TxProgressModal from './components/TxProgressModal';
import PermissionErrorModal from './components/PermissionErrorModal';
import './Sales.css';
//import sigs from "./signatures/sigs"; // will be downloaded in the future
import { width } from '@mui/system';
import Wallet from './components/Wallet';
import SectionDividers from './components/SectionDividers';
import axios from 'axios';
// import CarouselCounter from './CarouselCaounter';
import { ModeCtx } from '../../context/modeCtx';
import { useContext } from 'react';
const BP2 = '@media (max-width: 1345px)';
const BP4 = '@media (max-width: 600px)';
const BP5 = '@media (max-width: 899px)';
const BP6 = '@media (max-width: 700px)';
const BP3 = '@media (max-width: 384px)';
const BP7 = '@media (max-width: 820px)';
const BP1 = '@media (max-width: 570px)';

const sx = {
  container: {
    margin: 'auto',
    mt: '-5px',
    position: 'relative',
    pt: '50px',
    pb: '100px',
    maxWidth: '1233px',
    overflow: 'hidden',
    transition: 'all .3s',
    backgroundColor: '#FFF',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    px: '20px',
    [BP4]: {
      pt: '39px',
    },
  },
  nameContainer: {
    marginTop: '50px',
    position: 'absolute',
    mx: 'auto',
    display: 'flex',
    maxWidth: '1140px',
    width: '100%',
    zIndex: '1',
    backgroundColor: '#511BA3',
    [BP4]: {
      marginTop: '35px',
    },
  },
  name: {
    position: 'absolute',
    height: '135px',
    width: '135px',
    backgroundColor: '#511BA3',
    borderRadius: '15px',
    bottom: '4px',
    left: '0',
    right: '0',
    marginRight: 'auto',
    marginLeft: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    textAlign: 'center',
    //   paddingTop: "30px"
  },
  badgeContainer: {
    position: 'relative',
    margin: 'auto',
    // maxWidth: "300px",
    borderRadius: '12px',
    border: 'solid 2px #000',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: '12px',
    px: '20px',
  },
  badgeLeft: {
    borderRight: 'solid 2px #000',
    paddingRight: '15px',
    [BP3]: {
      borderRight: 'unset',
      borderBottom: 'solid 2px #000',
      width: '100%',
      paddingRight: '0',
    },
  },
  badgeCenter: {
    borderRight: 'solid 2px #000',
    paddingRight: '15px',
    [BP3]: {
      borderRight: 'unset',
      borderBottom: 'solid 2px #000',
      width: '100%',
      paddingRight: '0',
    },
  },
  badgeRight: {
    [BP3]: {
      width: '100%',
    },
  },
  timeContainer: {
    padding: '4px',
    margin: 'auto',
    mt: '20px',
    maxWidth: '512px',
    borderRadius: '12px',
    webkitboxShadow: '0px 4px 20px -3px rgba(0,0,0,0.1)',
    boxShadow: '0px 4px 20px -3px rgba(0,0,0,0.1)',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: '12px',
    [BP4]: {
      padding: '10px',
    },
  },
  cardContainer: {
    mx: 'auto',
    mt: '50px',
    mb: '20px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap',
    gap: '40px 16px',
  },
  title: {
    fontFamily: 'poppins-semibold',
    fontSize: '36px',
    lineHeight: '38px',
  },
  socialContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: '17px',
    mt: '20px',
    mb: '30px',
  },
  description: {
    fontFamily: 'bau',
    color: '#030000',
    fontSize: '16px',
    lineHeight: '24px',
    maxWidth: '606px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    margin: 'auto',
    mt: '25px',
  },
  nftContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  largeBanner: {
    display: 'block',
    [BP7]: {
      display: 'none',
    },
  },
  smallBanner: {
    display: 'none',
    [BP7]: {
      display: 'block',
    },
  },
  button: {
    backgroundColor: '#fe2c85',
    color: '#FAFAFA',
    fontSize: '14px',
    fontFamily: 'poppins-semibold',
    padding: '14px',
    borderRadius: '8px',
    '&:hover': {
      backgroundColor: '#fe2c85',
    },
  },
  buttonOutlined: {
    backgroundColor: 'transparent',
    color: '#fe2c85',
    fontSize: '14px',
    fontFamily: 'poppins-semibold',
    padding: '14px',
    borderRadius: '8px',
    '&:hover': {
      backgroundColor: '#fe2c85',
      color: 'white',
    },
  },

  tabContainer: {
    justifyContent: 'center',
    paddingTop: '30px',
  },
  tabBtns: {
    color: '#000',
    borderColor: '#000',
    borderWidth: '2px',
    backgroundColor: '#fff',
    fontFamily: 'poppins-semibold',
    padding: '14px',
    '&:hover': {
      borderColor: '#000',
      backgroundColor: '#000',
      borderWidth: '2px',
      color: '#fff',
      borderRightColor: '#000 !important',
    },
    borderRadius: '10px',
  },
  tabBtnsDark: {
    color: '#fff',
    borderColor: '#fff',
    borderWidth: '2px',
    backgroundColor: '#000',
    fontFamily: 'poppins-semibold',
    padding: '14px',
    '&:hover': {
      borderColor: '#fff',
      backgroundColor: '#fff',
      borderWidth: '2px',
      color: '#000',
      borderRightColor: '#fff !important',
    },
    borderRadius: '10px',
  },
  tabSelected: {
    backgroundColor: '#000',
    color: '#fff',
  },
  tabSelectedDark: {
    backgroundColor: '#fff',
    color: '#000',
  },
  buyButton: {
    height: '35px',
    borderRadius: '5px',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    backgroundColor: 'unset',
    border: '2px solid #fe2c85',
    color: '#fe2c85',
    '&:hover': {
      border: '2px solid #fe2c85',
      backgroundColor: '#fe2c85',
      color: '#ffffff',
    },
    '&:disabled': {
      border: '2px solid rgba(0, 0, 0, 0.26);',
    },
    [BP3]: {
      width: '100%',
      px: '14px',
    },
  },
  url: {
    textDecoration: 'none',
    color: '#fe2c85',
    fontWeight: '700',
  },
  description: {
    fontFamily: 'bau',
    color: '#1C1E23',
    fontSize: '16px',
    lineHeight: '28px',
    maxWidth: '856px',
    width: '100%',
    margin: 'auto',
    mt: '27px',
    mb: '40px',
  },
};

export const SALE_STATUS = {
  UPCOMING_PRESALE: 0,
  PRESALE: 1,
  PRESALE_ENDED: 2,
  SALE: 3,
  SALE_ENDED: 4,
};

const SalesV2 = ({ tokenAddress, saleAddress, symbol, whitelist, labels, openSeaName }) => {
  const { onboard, address, ethersProvider, handleConnect, defaultChainId, getProvider, chainId,setChain } =
    useWeb3Ctx();

  const [tab, setTab] = useState(0);

  const [minted, setMinted] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [salePrice, setSalePrice] = useState(0);
  const [discountPrice, setDiscountPrice] = useState(0);
  console.log(discountPrice)
  //sale states needed a different approach, because the sales can overlap each other :/

  const [preSaleStarted, setPreSaleStarted] = useState(false);
  const [preSaleFinished, setPreSaleFinished] = useState(false);

  const [mainSaleStarted, setMainSaleStarted] = useState(false);
  const [mainSaleFinished, setMainSaleFinished] = useState(false);

  const [presaleStartTime, setPresaleStartTime] = useState(null);
  const [saleStartTime, setSaleStartTime] = useState(null);
  const [presaleEndTime, setPresaleEndTime] = useState(null);
  const [saleEndTime, setSaleEndTime] = useState(null);

  const [presaleTimeCounter, setPresaleTimeCounter] = useState(null);
  const [saleTimeCounter, setSaleTimeCounter] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const [tokenContract, setTokenContract] = useState(null);
  const [saleContract, setSaleContract] = useState(null);

  const [txEtherScan, setTxEtherScan] = useState('');
  const [showCheckout, setShowCheckout] = useState(false);
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [txInProgress, setTxInProgress] = useState(false);
  const [approveInProgress, setApproveInProgress] = useState(false);
  const [checkoutIsPresale, setCheckoutIsPresale] = useState(true);
  const [isCreditCard, setIsCreditCard] = useState(false);

  const [signature, setSignature] = useState(null);

  const [maxTokenPerAddress, setMaxTokenPerAddress] = useState(10);

  const [maxDiscountMintable, setMaxDiscountMintable] = useState(10);
  const [maxMintableDuringMainSale, setMaxMintableDuringMainSale] =
    useState(10);
  const [userMaxDiscountMintable, setUserMaxDiscountMintable] = useState(0);

  const [refreshInterval, setRefreshInterval] = useState(null);

  const [signatures, setSignatures] = useState(null);

  const [saleLabels, setSaleLabels] = useState({
    preSaleTitle: 'Pre-Sale',
    preSaleDescription: '',
    publicSaleTitle: 'Public Sale',
    publicSaleDescription: '',
  });

  const [userIsEcHolder, setUserIsEcHolder] = useState(false);

  const [maxMintPerTransaction, setMaxMintPerTransaction] = useState(10);
  const modeCtx = useContext(ModeCtx);
  useEffect(() => {
    if (whitelist) {
      console.log('watlist', whitelist);

      axios
        .get(config.AWS_URL + whitelist)
        .then((res) => {
          console.log(res.status);
          console.log('whitelist from aws', res);
          if (res && res.status === 200) {
            setSignatures(res.data);
          } else {
            toast.error(res.message);
          }
        })
        .catch((e) => console.log);
    } else {
      setSignatures(null);
    }
    setSaleLabels(labels);
  }, []);

  useEffect(()=>{


    if(address && chainId){
      console.log(chainId===config.DEPLOYED_CHAIN_ID?'chain OK':'WRONG CHAIN');
      if(chainId!==config.DEPLOYED_CHAIN_ID){
        setChain(config.DEPLOYED_CHAIN_ID);
      }
    }

  },[address,chainId])

  useEffect(() => {
    (async () => {
      if (sessionStorage.getItem('selectedWallet')) {
        let t = Number(localStorage.getItem('activeTab'));
        if (t === 1) {
          setTab(1);
        } else {
          setTab(0);
        }
      }
    })();
  }, [onboard]);

  useInterval(
    () => {
      if (mainSaleFinished) {
        setRefreshInterval(null);
      } else {
        console.log('interval get sale');
        getSaleInfo();
      }
    },
    refreshInterval,
    false
  );

  useEffect(() => {
    if (address && signatures) {
      let userData = getUserParams();
      if (userData) {
        const _discountPrice = ethers.BigNumber.from(userData.params.eth_price); //info.discountPrice;
        setDiscountPrice(_discountPrice);
        setSignature(userData.signature);
        setUserIsEcHolder(true);
      } else {
        setUserIsEcHolder(false);
      }
    } else {
      setUserIsEcHolder(false);
    }
  }, [address, signatures]);

  useEffect(() => {
    const initContracts = async () => {
      let provider = getProvider(config.DEPLOYED_CHAIN_ID/* saleChain */);
  
      if (
        (tokenContract == null || saleContract === null) &&
        provider !== null
      ) {
        //console.log('EP in contract init',ethersProvider);

        let token = new Contract(tokenAddress, tokenABI.abi, provider);
        if (!token) {
          console.error('Token contract not found on address', tokenAddress);
          return;
        }

        let sale = new Contract(saleAddress, saleABI.abi, provider);
        if (!sale) {
          console.error('Sale contract not found on address', saleAddress);
        }

        console.log('CONTRACTS INITIATED', token, sale);
        setTokenContract(token);
        setSaleContract(sale);
      }
    };

    if (saleAddress && tokenAddress) {
      console.log('props changed',saleAddress,tokenAddress,ethersProvider)
      initContracts();
    }
  }, [saleAddress, tokenAddress]);

  useEffect(() => {
    const getInfo = async () => {
      //console.log('getting sale info start');

      let ms = await tokenContract.maxSupply().catch((e) => console.log);

      if (ms) {
        console.log('MAX SUPPPPPLY', Number(ms));
        setTotalAmount(Number(ms));
      }

      await getSaleInfo();
      //console.log('getting sale info end');
    };

    if (tokenContract !== null && saleContract !== null && signatures != null) {
      //console.log('+++++++++++++++++++++get sale info in effect', tokenContract,saleContract);
      getInfo();
      setRefreshInterval(20000);
    }
  }, [tokenContract, saleContract, signatures]);

  const getUserParams = () => {
    let up = null;
    if (address) {
      //console.log('SIGNATURES-------',signatures);

      const key = Object.keys(signatures).find(
        (key) => key.toLowerCase() == address.toLowerCase()
      );

      //console.log('USER KEY', key);
      if (key) {
        const userParams = signatures[key].params;

        up = {
          params: {
            max_mint: userParams[3],
            receiver: userParams[4],
            valid_from: userParams[5],
            valid_to: userParams[6],
            eth_price: userParams[7],
          },
          raw_params: userParams,
          signature: signatures[key].signature,
        };
      }
    }
    return up;
  };

  const getSaleInfo = async () => {
    console.log('saleInfo',saleContract);
    setIsLoading(true);
    const info = await saleContract
      .tellEverything()
      .catch((e) => console.log('err:', e));
    console.log('****info', info)

    const totalSupply = await tokenContract.totalSupply();
    console.log('TS',totalSupply);

    const presaleStart = Number(info.config.presaleStart);
    const presaleEnd = Number(info.config.presaleEnd);
    const saleStart = Number(info.config.saleStart);
    const saleEnd = Number(info.config.saleEnd);

    const fullPrice = info.config.fullPrice;

    /* const maxTokens = Number(info.maxTokens);
		const userMinted = Number(info.userMinted);

		const presaleIsActive = info.presaleIsActive;
		const saleIsActive = info.saleIsActive; */

    setMaxDiscountMintable(Number(info.config.maxPresalePerAddress));
    setMaxTokenPerAddress(Number(info.config.maxSalePerAddress));

    setMaxMintPerTransaction(Number(info.config.maxMintPerTransaction));

    //	let reserved = info.maxTokens.sub(info.MaxUserMintable);
    //	setMinted(Number(info.userMinted.add(reserved)));
    setMinted(Number(totalSupply));
    //setTotalAmount(Number(info.config.MaxUserMintable?info.config.MaxUserMintable:info._MaxUserMintable)); //maxTokens

    let now = Date.parse(new Date()) / 1000;
    let now2 = Date.parse(new Date()) / 1000;
    //let now = Number(await saleContract.getBlockTimestamp());

    /* console.log('current time', now2)
				console.log('block time', now)
				console.log('presale start time', presaleStart)
				console.log('presale end time', presaleEnd)
				console.log('sale start time', saleStart)
				console.log('sale end time', saleEnd) */

    let presaleIsOver = presaleEnd - now <= 0;
    let saleIsOver = saleEnd - now <= 0;
    let saleIsOn = now >= saleStart && !saleIsOver;
    let presaleIsOn = now >= presaleStart && !presaleIsOver;

    //	let _discountPrice = 0;
    let _discountPrice = ethers.BigNumber.from(fullPrice);

    if (presaleIsOn) {
      let userParams = getUserParams();
      if (userParams) {
        _discountPrice = ethers.BigNumber.from(userParams.params.eth_price); //info.discountPrice;
      }
    }

    //ec holder's hack
    //let ecHolder = getUserParams();
    //console.log('USER IS EC HOLDER', ecHolder);

    /* 	if (ecHolder) {
			_discountPrice = ethers.BigNumber.from(ecHolder.params.eth_price); //info.discountPrice;
			setUserIsEcHolder(true);
			console.log('USER IS EC HOLDER2', ecHolder);
		} else {
			setUserIsEcHolder(false);
		} */

    //setDiscountPrice(ethers.utils.formatEther(_discountPrice));
    // setSalePrice(ethers.utils.formatEther(fullPrice));
    
    setDiscountPrice(_discountPrice);
    setSalePrice(fullPrice);

    setPreSaleStarted(presaleIsOn);
    setPreSaleFinished(presaleIsOver);
    /* 
		setPreSaleStarted(true);
		setPreSaleFinished(true); */

    setMainSaleStarted(saleIsOn);
    setMainSaleFinished(saleIsOver);

    //console.log(presaleStart,presaleEnd,saleStart,saleEnd,maxTokens,fullPrice,discountPrice,userMinted);

    /* 	console.log('presaleIsOver',presaleIsOver,presaleEnd-now);
				console.log('saleIsOver',saleIsOver,saleEnd-now);
				console.log('presaleIsOn',presaleIsOn,now>=presaleStart,presaleIsOver);
				console.log('saleIsOn',saleIsOn,now>=saleStart, !saleIsOver); */

    setPresaleStartTime(new Date(presaleStart * 1000));
    setPresaleEndTime(new Date(presaleEnd * 1000));
    setSaleStartTime(new Date(saleStart * 1000));
    setSaleEndTime(new Date(saleEnd * 1000));

    if (!presaleIsOn && !presaleIsOver) {
      setPresaleTimeCounter(new Date(presaleStart * 1000));
    } else {
      //console.log('presale over, or on');
      if (!presaleIsOver) {
        setPresaleTimeCounter(new Date(presaleEnd * 1000));
      }
    }

    if (!saleIsOn && !saleIsOver) {
      setSaleTimeCounter(new Date(saleStart * 1000));
    } else {
      //console.log('sale over, or on');
      if (!saleIsOver) {
        setSaleTimeCounter(new Date(saleEnd * 1000));
      }
    }
    setIsLoading(false);
  };

  const handleDiscountMint = async () => {


    if(chainId !== config.DEPLOYED_CHAIN_ID/* saleChain */){
      toast.error('Unsupported chain.');
      return;
    }


    let maxMintable = 0;

    //mintInfo =  await saleContract.checkDiscountAvailable(address);

    const userParams = getUserParams();

    if (!userParams) {
      setShowErrorPopup(true);
      return;
    }

    const alreadyMintedByWallet = await saleContract
      ._mintedByWallet(address)
      .catch((e) => console.log);

    //console.log('minted by wallet',alreadyMintedByWallet);

    if (alreadyMintedByWallet) {
      maxMintable = userParams.params.max_mint - Number(alreadyMintedByWallet);
    }

    //console.log('maxMintable', maxMintable);

    if (maxMintable < 1) {
      toast.error('You have already used up your presale quota.');
      return;
    }
    setUserMaxDiscountMintable(maxMintable);
    setCheckoutIsPresale(true);
    setIsCreditCard(false);
    setShowCheckout(true);
  };

  const handleMint = async () => {
    if(chainId !== config.DEPLOYED_CHAIN_ID/* saleChain */){
      toast.error('Unsupported chain.');
      return;
    }
    setApproveInProgress(true);
    console.log('!!!!!!!max tokens per adddres', maxTokenPerAddress);
    const alreadyMintedByWallet = await saleContract
      ._mintedByWallet(address)
      .catch((e) => console.log);

    if (alreadyMintedByWallet) {
      let maxMintableMainSale =
        maxTokenPerAddress - Number(alreadyMintedByWallet);

      if (maxMintableMainSale > 0) {
        setMaxMintableDuringMainSale(
          maxMintableMainSale < maxMintPerTransaction
            ? maxMintableMainSale
            : maxMintPerTransaction
        );

        /* 	if (userIsEcHolder) {
					setUserMaxDiscountMintable(maxMintableMainSale < maxMintPerTransaction ? maxMintableMainSale : maxMintPerTransaction);
				} */

        //	setCheckoutIsPresale(userIsEcHolder);//!!! ec holder hack. it should be false otherwise
        setCheckoutIsPresale(false);
        setIsCreditCard(false);
        setApproveInProgress(false);
        setShowCheckout(true);
      } else {
        setApproveInProgress(false);
        toast.error('You have alredy used up your quota.');
      }
    } else {
      setApproveInProgress(false);
      console.log("can't get already minted tokens");
    }
  };

  const mintDisco = async (amount, withToken) => {
    let sc = saleContract.connect(ethersProvider.getSigner());

    setShowCheckout(false);

    setApproveInProgress(true);

    let userParams = getUserParams();

    if (!userParams) {
      return;
    }

    let tx = null;
    tx = await sc
      .mint_approved([...userParams.raw_params, userParams.signature], amount, {
        value: discountPrice.mul(amount),
      })
      .catch(handleError);

    setApproveInProgress(false);

    if (tx) {
      setTxEtherScan(`${config.ETHERSCAN_URL}/tx/${tx.hash}`);
      setTxInProgress(true);
      await tx.wait().catch((e) => {
        handleError(e);
        setTxInProgress(false);
      });
      setTxInProgress(false);
      getSaleInfo();
      setTab(1); //-> wallet

      localStorage.setItem('activeTab', 1);
    }
  };

  const handleCreditCard = async () => {
    console.log('CC');
    setMaxMintableDuringMainSale(5);
    setCheckoutIsPresale(false);
    setApproveInProgress(false);
    setIsCreditCard(true);
    setShowCheckout(true);
  };

  const mintRegular = async (amount, withToken) => {
   // console.log(amount , price, salePrice);
 
    let sc = saleContract.connect(ethersProvider.getSigner());

    setShowCheckout(false);
    setApproveInProgress(true);
    const tx = await sc
      .mint(amount, { value: salePrice.mul(amount) })
      .catch(handleError);
    setApproveInProgress(false);

    if (tx) {
      setTxEtherScan(`${config.ETHERSCAN_URL}/tx/${tx.hash}`);
      setTxInProgress(true);
      await tx.wait().catch((e) => {
        handleError(e);
        setTxInProgress(false);
      });
      setTxInProgress(false);
      getSaleInfo();
      setTab(1); //-> wallet
      localStorage.setItem('activeTab', 1);
    }
  };

  const handleError = (e) => {
    console.error(e);
    if (e.error && e.error.message) {
      toast.error(e.error.message);
    } else if (e.message) {
      toast.error(e.message);
    } else if (e.reason) {
      toast.error(e.reason);
    }
  };

  const resetSale = async (mode) => {
    const now = Math.floor(new Date().getTime() / 1000);

    let presaleStartFromNow = now;
    let presaleStart = now;
    let presaleEnd = now;
    let saleStart = now;
    let saleEnd = now;

    if (mode === 0) {
      presaleStartFromNow = now + 5 * 60;
    } else if (mode === 1) {
      presaleStartFromNow = now;
    } else if (mode === 2) {
      presaleStartFromNow = now - 30 * 60;
    } else if (mode === 3) {
      presaleStartFromNow = now - 500 * 60;
    }

    presaleStart = presaleStartFromNow;
    presaleEnd = presaleStart + 30 * 60;
    saleStart = presaleEnd;
    saleEnd = saleStart + 300 * 60;

    let sc = saleContract.connect(ethersProvider.getSigner());

    setApproveInProgress(true);
    const tx = await sc
      .Rinkeby_UpdateSaleConfiguration(
        presaleStart,
        presaleEnd,
        saleStart,
        saleEnd
      )
      .catch(handleError);
    setApproveInProgress(false);
    if (tx) {
      setTxEtherScan(`${config.ETHERSCAN_URL}/tx/${tx.hash}`);
      setTxInProgress(true);
      await tx.wait().catch((e) => {
        handleError(e);
        setTxInProgress(false);
      });
      setTxInProgress(false);
      getSaleInfo();
    }
  };

  const getStyle = (num) =>{
	if(modeCtx.lightTheme){
		if(tab == num){
			return sx.tabSelected
		}else{
			return null;
		}
	}else{
		if(tab == num){
			return sx.tabSelectedDark
		}else{
			return null;
		}
	}

  }

  return (
    <Box sx={sx.saleRoot}>
      {/* <Button
				sx={sx.buyButton}
				style={{ margin:"10px", padding:"0 5px", fontSize:'14px', height:'auto'}}
				onClick={()=>resetSale(0)}
				>
				Before pre-sale
			</Button>
			<Button
				sx={sx.buyButton}
				style={{ margin:"10px", padding:"0 5px", fontSize:'14px', height:'auto'}}
				onClick={()=>resetSale(1)}
				>
				pre-sale active
			</Button>
			<Button
				sx={sx.buyButton}
				style={{ margin:"10px", padding:"0 5px", fontSize:'14px', height:'auto'}}
				onClick={()=>resetSale(2)}
				>
				public sale active
			</Button>
			<Button
				sx={sx.buyButton}
				style={{ margin:"10px", padding:"0 5px", fontSize:'14px', height:'auto'}}
				onClick={()=>resetSale(3)}
				>
				sales over
			</Button> */}

      <Box sx={sx.infoContainer}>
        <Box
          sx={sx.badgeContainer}
          style={{ border: !modeCtx.lightTheme && '2px solid #fff' }}
        >
          <InfoBox
            label="ITEMS"
            value={totalAmount}
            sx={sx.badgeLeft}
            style={{ borderRight: !modeCtx.lightTheme && '2px solid #fff' }}
          />
          {minted > 0 && (
            <InfoBox
              label="SOLD"
              value={minted}
              sx={sx.badgeCenter}
              style={{ borderRight: !modeCtx.lightTheme && '2px solid #fff' }}
            />
          )}
          <InfoBox
            label="PRICE"
            value={
              mainSaleStarted || mainSaleFinished ? ethers.utils.formatEther(salePrice) : ethers.utils.formatEther(discountPrice)
            }
            eth
            sx={sx.badgeRight}
          />
        </Box>
      </Box>

      <ButtonGroup
        sx={sx.tabContainer}
        variant="outlined"
        size="medium"
        aria-label="medium outlined button group"
      >
        <Button
         sx={{
			...(modeCtx.lightTheme ? sx.tabBtns : sx.tabBtnsDark),
            ...(getStyle(0)),
          }}
          onClick={() => {
            setTab(0);
            localStorage.setItem('activeTab', 0);
          }}
        >
          SALE INFO
        </Button>
        <Button
          sx={{
			...(modeCtx.lightTheme ? sx.tabBtns : sx.tabBtnsDark),
            ...(getStyle(1)),
          }}
          onClick={() => {
            setTab(1);
            localStorage.setItem('activeTab', 1);
          }}
        >
          COLLECTION WALLET
        </Button>
      </ButtonGroup>

      <SectionDividers />

      {tab === 0 ? (
        <>
          {/* PRESALE */}

          {!preSaleFinished && (
            <>
              <Typography variant='bannerTitle' align="center" sx={sx.title}>
                {saleLabels.preSaleTitle}{' '}
                {!preSaleStarted && !preSaleFinished && (
                  <>
                    <Box component="span">is Coming Soon</Box>
                    {!address && (
                      <Typography
                        sx={sx.description}
                        style={{ marginTop: 0 }}
                      ></Typography>
                    )}
                  </>
                )}
                {preSaleStarted && (
                  <>
                    <Box
                      sx={sx.titleLive}
                      style={{ color: modeCtx.lightTheme ? '#000' : '#fff' }}
                      component="span"
                    >
                      is Live
                    </Box>
                  </>
                )}
                {preSaleFinished && <Box component="span">Ended</Box>}
              </Typography>
              {preSaleStarted && (
                <p style={{ color: modeCtx.lightTheme ? '#000' : '#fff' }}>
                  It ends in
                </p>
              )}

              <Counter
                date={presaleTimeCounter}
                isActive={preSaleStarted}
                onFinish={() => {
                  console.log('presale timer is up');
                  getSaleInfo();
                }}
                id="presale"
              />

              <Box sx={sx.timeContainer}>
                <Timer
                  time={
                    <>
                      {presaleStartTime
                        ? new DateTime.fromMillis(presaleStartTime.getTime())
                            .setZone('America/New_York')
                            .toFormat('dd. MM. hha') + ' EDT'
                        : ''}{' '}
                      -{' '}
                      {presaleEndTime
                        ? new DateTime.fromMillis(presaleEndTime.getTime())
                            .setZone('America/New_York')
                            .toFormat('dd. MM. hha') + ' EDT'
                        : ''}
                    </>
                  }
                />
              </Box>
              <p style={{ marginTop: '15px', color: modeCtx.lightTheme ? '#000' : '#fff' }}>
                {saleLabels.preSaleDescription}
              </p>

              {preSaleStarted && (
                <Box mt={2}>
                  <Button
                    sx={sx.buyButton}
                    style={{ margin: '10px', color: !modeCtx.lightTheme && '#FFF9', borderColor:  !modeCtx.lightTheme && '#FFF9' }}
                    variant="banner"
                    onClick={handleDiscountMint}
                    disabled={!address || isLoading}
                  >
                    Buy Digital Collectible(s)
                  </Button>
                  {!address && (
                    <Button
                      variant="banner"
                      sx={sx.buyButton}
                      style={{ margin: '10px' }}
                      onClick={handleConnect}
                    >
                      CONNECT WALLET
                    </Button>
                  )}
                </Box>
              )}
            </>
          )}

          {/* SALE */}
          {!mainSaleFinished && (
            <>
              <Typography align="center" variant='bannerTitle' mt={0} sx={sx.title}>
                {saleLabels.publicSaleTitle}{' '}
                {!mainSaleStarted && !mainSaleFinished && (
                  <Box component="span">Is Coming Soon</Box>
                )}
                {mainSaleStarted && (
                  <Box
                    sx={sx.titleLive}
                    style={{ color: modeCtx.lightTheme ? '#000' : '#fff' }}
                    component="span"
                  >
                    Is Live
                  </Box>
                )}
                {mainSaleFinished && <Box component="span">Ended</Box>}
              </Typography>

              {mainSaleStarted && (
                <p style={{ color: modeCtx.lightTheme ? '#000' : '#fff' }}>
                  It ends in
                </p>
              )}

              {preSaleFinished && (
                <Counter
                  date={saleTimeCounter}
                  isActive={mainSaleStarted}
                  onFinish={() => {
                    console.log('sale timer is up');
                    getSaleInfo();
                  }}
                  id="sale"
                />
              )}

              {!mainSaleStarted && !mainSaleFinished && (
                <Typography
                  sx={sx.description}
                  style={{ marginTop: '15px', marginBottom: '1rem' }}
                >
                  It starts at
                </Typography>
              )}

              <Box
                sx={sx.timeContainer}
                style={{
                  border: modeCtx.lightTheme
                    ? '2px solid #000'
                    : '2px solid #fff',
                }}
              >
                <Timer
                  time={
                    <>
                      {saleStartTime
                        ? new DateTime.fromMillis(saleStartTime.getTime())
                            .setZone('America/New_York')
                            .toFormat('dd. MM. hha') + ' EDT'
                        : ''}{' '}
                      -{' '}
                      {saleEndTime
                        ? new DateTime.fromMillis(saleEndTime.getTime())
                            .setZone('America/New_York')
                            .toFormat('dd. MM. hha') + ' EDT'
                        : ''}
                    </>
                  }
                />
              </Box>
              <p style={{ marginTop: '15px', color: modeCtx.lightTheme ? '#000' : '#fff' }}>
                {saleLabels.publicSaleDescription}
              </p>

              {mainSaleStarted && (
                <Box mt={2}>
                  {address && (
                    <Button
                      sx={sx.buyButton}
                      style={{ margin: '10px 10px 30px 10px' }}
                      variant="banner"
                      onClick={handleMint}
                      disabled={!address || isLoading}
                    >
                      Buy Digital Collectible(s)
                    </Button>
                  )}

                  {!address && (
                    <Button
                      sx={sx.buyButton}
                      variant="banner"
                      style={{ margin: '10px 10px 30px 10px' }}
                      onClick={handleConnect}
                    >
                      CONNECT WALLET
                    </Button>
                  )}
                  {/* <Button
								sx={sx.buyButton}
								variant="banner"
								style={{ margin: "10px 10px 30px 10px" }}
								onClick={handleCreditCard}
							>
								PAY WITH CREDIT CARD
							</Button> */}
                </Box>
              )}
              <SectionDividers />
            </>
          )}

          {preSaleFinished && mainSaleFinished && (
            <>
              <Typography align="center" variant='bannerTitle' sx={sx.title} style={{ marginBottom: '3rem' }}>
                <Box
                  sx={sx.titleLive}
                  component="span"
                  style={{ color: modeCtx.lightTheme ? '#000' : '#fff' }}
                >
                  The sale is over
                </Box>
              </Typography>

              {openSeaName && <Typography
                sx={sx.description}
                style={{ marginBottom: '3rem' }}
              >
                Missed our NFT sale? Check out the Collection on{' '}
                <Box
                  component="a"
                  href={
                    `${config.OPENSEA_URL}collection/${openSeaName}`
                  }
                  sx={sx.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  OpenSea
                </Box>
              </Typography>}
            </>
          )}
        </>
      ) : (
        <>
          <Wallet tokenContract={tokenContract} />
          <SectionDividers />
        </>
      )}

      <PermissionErrorModal
        isOpen={showErrorPopup}
        setOpen={setShowErrorPopup}
      />

      <CheckoutModal
        tokenName={symbol}
        isOpen={showCheckout}
        setOpen={() => {
          if (!txInProgress && !approveInProgress) {
            setShowCheckout(false);
          }
        }}
        isPresale={checkoutIsPresale}
        withCreditCard={isCreditCard}
        whitelistLimit={
          checkoutIsPresale
            ? userMaxDiscountMintable
            : maxMintableDuringMainSale
        }
        salePrice={ethers.utils.formatEther(salePrice)}
        presalePrice={ethers.utils.formatEther(discountPrice)}
        mintSale={mintRegular}
        mintPresale={mintDisco}
      />
      <BackdropModal isOpen={approveInProgress} />

      <TxProgressModal isOpen={txInProgress} txEtherScan={txEtherScan} />
    </Box>
  );
};
export default SalesV2;
