import { Typography, Box } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { ModeCtx } from '../../../../context/modeCtx';
import useWeb3Ctx from '../../../../hooks/useWeb3Ctx';
import TraitItem from './TraitItem';

const BP1 = '@media (max-width: 871px)';
const BP2 = '@media (max-width: 470px)';
const BP3 = '@media (max-width: 520px)';
const BP4 = '@media (max-width: 900px)';
const BP5 = '@media (max-width: 600px)';

const ERROR_DOWNLOAD_STATS = 'ERROR_DOWNLOAD_STATS';

const sx = {
  traitsBox: {
    maxHeight: '180px',
    overflowY: 'auto',
  },
};

const TraitBox = ({ traits, trait_types, ownerOfToken = '' }) => {
  const modeCtx = useContext(ModeCtx);
  const { address } = useWeb3Ctx();
  const [enableClaimButton, setEnableClaimButton] = useState(false);
  const [theTraits, setTheTraits] = useState([]);

  useEffect(()=>{

    let t = [];

    for(let i=0; i<traits.length;i++){
      if(traits[i].claim_url!==null){
        t.push(traits[i]);
      }
    }
    console.log('new traits',t);
    setTheTraits(t);



  },[traits]);



  useEffect(() => {
    if (ownerOfToken && address && ownerOfToken.toLowerCase() === address.toLowerCase()) {
      setEnableClaimButton(true)
    }else {
      setEnableClaimButton(false)
    }
  }, [address, ownerOfToken])

  return (
      <>
        {theTraits.length>0&&<Box>
          <Typography
            variant='minuscule'
            sx={sx.titleText}
            style={{ color: !modeCtx.lightTheme && '#fff9' }}
          >
            UTILITY TRAITS
          </Typography>
          <Box sx={sx.traitsBox} className='scrollbar-with-padding'>
            {theTraits.map((trait) => (
              <TraitItem
                trait={trait}
                traitType={trait_types}
                mode={modeCtx.lightTheme}
                enableClaimButton={enableClaimButton}
              />
            ))}
          </Box>
        </Box>}
      </>
  );
};

export default TraitBox;
