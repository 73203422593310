import { Typography, Box, Button } from '@mui/material';
import React, { useContext } from 'react';
import { ModeCtx } from '../../../../context/modeCtx';

const sx = {
  collectionName: {
    fontSize: '18px',
    fontFamily: 'poppins-medium',
  },
  contentBox: {
    display: 'flex',
    columnGap: '10px',
    alignItems: 'center'
  },
  utilityImageHolder: {
    width: '40px',
    height: '40px',
    border: '1px solid #030000',
    borderRadius: '7px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#000',
    "& img": {
      maxWidth: '70%',
      height: 'auto'
    }
  },
  utilityTraitHolder: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    mb: '5px',
    justifyContent: 'space-between'
  },
  Button: {
    minHeight: '40px',
    height: '40px',
    width: 'fit-content',
    borderRadius: '5px',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    backgroundColor: 'unset',
    border: '2px solid #fe2c85',
    color: '#fe2c85',
    marginTop: '0',
    marginRight: '5px',
    '&:hover': {
      border: '2px solid #fe2c85',
      backgroundColor: '#fe2c85',
      color: '#ffffff',
    },
  },
};

const TraitItem = ({ trait = {}, traitTypes = [], mode , enableClaimButton}) => {

  const handleClaim = () => {
    if (trait.claim_url) {
      window.open(`${trait.claim_url}`, '_blank')
    }
  }

  return (
    <>
      {trait.claim_url ?
        <Box sx={sx.utilityTraitHolder}>
          <Box sx={sx.contentBox}>
            <Box sx={sx.utilityImageHolder}
              style={{ border: !mode && '1px solid #fff8' }}
            >
              {trait.icon_url ? (
                <img
                  src={trait.icon_url}
                  alt=''
                  style={{
                    height: '70%',
                  }}
                />
              ) : (
                traitTypes.map(
                  (traitType) =>
                    (parseInt(trait.type) === traitType.id)( // Have to correct this line
                      <img
                        src={trait.icon_url}
                        alt=''
                        style={{
                          height: '70%',
                        }}
                      />
                    )
                )
              )}
            </Box>
            <Typography variant='heading' sx={sx.collectionName}
              style={{ color: !mode && '#fff' }}
            >
              {trait.name}
            </Typography>
          </Box>
          {trait.claim_url && enableClaimButton
            &&
            <Button
              variant="banner"
              sx={sx.Button}
              onClick={() => handleClaim()}
            >
              Claim
            </Button>
          }
        </Box>
        : null}
    </>
  );
};

export default TraitItem;
