import './CardOverlay.css'
import { Data } from '../DataContext'
import { useContext } from 'react'
import { GalaxisCard } from 'galaxis-components';
import closeIcon from '../../../assets/images/Explorer/white_close_4x.png';
import config from '../../../config/config';

const CardOverlay = () => {
    const { chosenCard, showOverlayForCard, traitTypes } = useContext(Data);
    return <div className="card-overlay" onClick={() => showOverlayForCard(false)}>
        <button className='close-btn' onClick={() => showOverlayForCard(false)}><img alt='undefined' src={closeIcon} /></button>
        <div style={{width:"100%",height: "100%", maxWidth:"700px"}}>
            <GalaxisCard metadata={chosenCard} traitTypes={traitTypes} apiUrl={config.API_URL} />
        </div>
    </div>
}
export default CardOverlay;