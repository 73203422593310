import { useState } from 'react';
import './CustomFileterSelect.css';
import arrow_orange from '../../../assets/images/arrow_down_orange.svg';
import arrow_black from '../../../assets/images/arrow_down_black.svg';

const CustomFilterSelect = ({ keyname, filters, filterChanged }) => {
    const [selectValue, setSelectValue] = useState('All');
    const [opened, setOpened] = useState(false);

    const toggleOpen = () => {
        setOpened(!opened);
    };

    const handleSelectChange = (keyname, value) => {
       // console.log('filterselect',keyname,value)
        setSelectValue(value.name);
        filterChanged(keyname, value);
    };

    const renderFilters = () => {
        return filters[keyname].map((filter, idx) => {
            // return <option key={`filter` + Math.random()}>{filter}</option>;
            return (
                <div
                    key={`filter` + idx}
                    onClick={(e) => handleSelectChange(keyname, filter)}
                    className={`cust-filter-item ${
                        selectValue === filter.name ? 'selected' : ''
                    }`}
                >
                    {filter.name}
                </div>
            );
        });
    };

    return (
        <div className="custom-filter-select" onClick={toggleOpen}>
            <label className={`mb-0 ${opened ? 'open' : ''}`}>
                {keyname} <img src={opened ? arrow_orange : arrow_black} />
            </label>
            <div
                className={`filter-dropdown`}
                onMouseLeave={() => setOpened(false)}
            >
                <div
                    onClick={(e) => handleSelectChange(keyname, 'All')}
                    className={`cust-filter-item ${
                        selectValue === 'All' ? 'selected' : ''
                    }`}
                >
                    All
                </div>
                {renderFilters()}
            </div>

            <div className="filter-value">
                {selectValue ? selectValue : 'All'}
            </div>

            {/* <div className="select-parent">
      
        <select
          value={selectValue}
          onChange={(e) => handleSelectChange(keyname, e)}
        >
          <option>All</option>
          {renderFilters()}
        </select>
      </div> */}
        </div>
    );
};

export default CustomFilterSelect;
