import React from 'react';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import alpha from '../../../assets/images/current-projects/trait-icons/alpha-up.svg';
import art from '../../../assets/images/current-projects/trait-icons/art-drop.svg';
import forge from '../../../assets/images/current-projects/trait-icons/forge-protection.svg';
import golden from '../../../assets/images/current-projects/trait-icons/goldengoose.svg';
import good from '../../../assets/images/current-projects/trait-icons/good-company.svg';
import og from '../../../assets/images/current-projects/trait-icons/og-up.svg';
import swap from '../../../assets/images/current-projects/trait-icons/swap.svg';

import access from '../../../assets/images/current-projects/trait-icons/access.svg';
import badge from '../../../assets/images/current-projects/trait-icons/badge.svg';
import digital from '../../../assets/images/current-projects/trait-icons/digital.svg';
import discount from '../../../assets/images/current-projects/trait-icons/discount.svg';
import drop from '../../../assets/images/current-projects/trait-icons/drop.svg';
import modifier from '../../../assets/images/current-projects/trait-icons/modifier.svg';
import physical from '../../../assets/images/current-projects/trait-icons/physical.svg';
import { useEffect } from 'react';
import { ModeCtx } from '../../../context/modeCtx';
import { useContext } from 'react';

const BP2 = '@media (max-width: 1148px)';
const BP3 = '@media (max-width: 599px)';
const BP4 = '@media (max-width: 899px)';
const BP5 = '@media (max-width: 779px)';

const sx = {
  root: {
    width: '100%',
    // maxWidth: '396px',
    pl: '20.3px',
    pr: '13.7px',
    py: '27px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    borderRadius: '18px',
    border: 'solid 2px #000',
    '& img': {
      width: '41px',
      height: '41px',
    },
  },
  title: {
    pr: '6.6px',
    fontFamily: 'poppins-medium',
    minHeight: '50px',
    fontSize: '22px',
    lineHeight: '22px',
    color: '#030000',
    mt: '12px',
    textAlign: 'center',
    [BP3]: {
      minHeight: 'unset',
      mb: '20px',
    },
  },
  text: {
    width: '100%',
    minHeight: '110px',
    maxHeight: '110px',
    fontFamily: 'bau',
    fontSize: '16px',
    lineHeight: '22px',
    textAlign: 'left',
    pr: '13.6px',
    color: '#030000',
    overflowX: 'hidden',
    scrollBehavior: 'smooth',
    '&::-webkit-scrollbar': {
      width: '0.3em',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#fe2c85',
    },
    [BP3]: {
      minHeight: 'unset',
      maxHeight: 'unset',
      textAlign: 'center',
      pr: '6.6px',
    },
  },
};

const UtilityCard = ({ title, text, icon }) => {
  useEffect(() => {
    var typoElemnt = document.getElementById(text);
    typoElemnt.innerHTML = text;
  }, []);
  const modeCtx = useContext(ModeCtx);

  return (
    <Box sx={sx.root} style={{background: !modeCtx.lightTheme && "linear-gradient(rgb(20, 33, 46), rgb(14, 25, 36))"}}>
      <Box style={{ paddingRight: '6.6px' }}>
        <img src={icon} alt={title} 
        />
      </Box>
      <Typography sx={sx.title} >{title}</Typography>
      <Typography sx={sx.text} id={text} variant='bannerText' style={{background: !modeCtx.lightTheme && "linear-gradient(rgb(20, 33, 46), rgb(14, 25, 36))"}}>
        {text}
      </Typography>
    </Box>
  );
};

UtilityCard.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
};

export default UtilityCard;
