import React, { useEffect, useState } from "react";
import "./Counter.css";

const MIN = 60000;
const HOUR = MIN * 60;
const DAY = HOUR * 24;

const INIT = { d: 0, h: 0, m: 0, s: 0 };

const Counter = ({ date, onFinish, id, isActive}) => {
  const [values, setValues] = useState(INIT);


  const setRotation = (element, rotationRation, type) => {
    if (type === 'hour') {
      element.style.transform = `rotate(${-((rotationRation) * 15) + 45}deg)`;
    } else {
      element.style.transform = `rotate(${-((rotationRation) * 6) + 45}deg)`;
    }
    //  console.log(element.style.transform)
  }

  useEffect(() => {
    const dayHand = document.getElementById(id ? `${id}_days` : "days");
    const hourHand = document.getElementById(id ? `${id}_hours` : 'hours');
    const minuteHand = document.getElementById(id ? `${id}_mins` : 'mins');
    const secondHand = document.getElementById(id ? `${id}_sec` : 'sec');


    const interval = setInterval(() => {
      if (date != null) {
        let dif = date.getTime() - new Date().getTime();
        if (dif > 0) {
          setValues({
            d: Math.floor(dif / DAY),
            h: Math.floor((dif % DAY) / HOUR),
            m: Math.floor((dif % HOUR) / MIN),
            s: Math.floor((dif % MIN) / 1000),
          });
          setRotation(secondHand, Math.floor(((dif % MIN) / 1000)), 'sec');
          setRotation(minuteHand, Math.floor((dif % HOUR) / MIN), 'min');
          setRotation(hourHand, Math.floor((dif % DAY) / HOUR), 'hour');
        } else {
          setValues(INIT);
          clearInterval(interval);
          if (onFinish) {
            onFinish()
          }
        }
      } else {
        setValues(INIT);
        clearInterval(interval);
      }
    }, 500);
    return () => {
      clearInterval(interval);
    };
  }, [date]);

  return (
    <div className={`counter-container pt-4 ${!isActive?'disabled':''}`}>
      <CounterCircle name="DAYS" value={values.d} type={id ? `${id}_days` : "days"} />
      <CounterCircle name="HOURS" value={values.h} type={id ? `${id}_hours` : 'hours'} />
      <CounterCircle name="MINS" value={values.m} type={id ? `${id}_mins` : 'mins'} />
      <CounterCircle name="SEC" value={values.s} type={id ? `${id}_sec` : 'sec'} />
    </div>
  );
};

const CounterCircle = ({ name, value, type }) => (
  <>
    <div className="counter-circle">
      <div className="counter-rotary-holder" id={type}>
        <div className="counter-rotary-item" />
      </div>
      <div className="counter-shadow">
        <div className="counter-content-holder">
          <div className="counter-text">{name}</div>
          <div className="counter-text-value">{value}</div>
        </div>
      </div>

    </div>
  </>
);

export default Counter;
