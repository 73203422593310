import CurrentProjectSubpage from '../views/current-projects/CurrentProjectSubpage';
import OurProjects from '../views/ourProjects/OurProjects';
import Redirect from '../views/redirect/Redirect';
import CardDetails from '../views/selected-card-details/CardDetails';


const routes = [
  {
    path: '/projects/:project',
    component: CurrentProjectSubpage,
    exact: true,
  },
  {
    path: '/projects/:project/:id',
    component: CardDetails,
    exact: true,
  },
  {
    path: '/preview/projects/:project/',
    component: CurrentProjectSubpage,
    exact: true,
  },
  {
    path: '/review/projects/approved/:project/',
    component: CurrentProjectSubpage,
    exact: true,
  },
  {
    path: '/review/projects/pending-approval/:project/',
    component: CurrentProjectSubpage,
    exact: true,
  },
  {
    path: '/preview/projects/:projectId/:cardId',
    component: CardDetails,
    exact: true,
  },
  {
    path: '/communities',
    component: OurProjects,
    exact: true
  },
  {
    path: '/',
    component: Redirect,
    exact: true
  },
];

export default routes;
