import React, { useState, useEffect } from "react";
import useWeb3Ctx from "../../../hooks/useWeb3Ctx";
import {useZoom2Contract} from "../../../hooks/useContract";
import { getTokenIds, zoomFetchTokenUris } from "../../../utils/zoom2";
import FullscreenModal from "./FullscreenModal";

import { SpinnerCircularFixed as Spinner } from "spinners-react";
import { Box, Typography, Button, Grid } from "@mui/material";
import NftGallery from "../../../views/current-projects/NftGallery";

import tokenABI from '../abi/Token.json';
import { Contract } from "ethers";
import { useContext } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { ModeCtx } from '../../../context/modeCtx';

const sx = {
  root: {
    position: "relative",
    // minHeight: "30vh",
    width: "100%",
    height: "100%"
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "top",
    textAlign: "center",
    gap: "0 25px",
    mb: 4
  },
  title: {
    marginTop: "8px",
    textAlign: "center",
    fontFamily: "poppins-semibold",
    fontSize: "36px",
  },
  connectBtn: {
		height: '35px',
		borderRadius: '5px',
		fontWeight: 'bold',
		whiteSpace: 'nowrap',
		backgroundColor: 'unset',
		border: '2px solid #fe2c85',
		color: '#fe2c85',
		'&:hover': {
		  border: '2px solid #fe2c85',
		  backgroundColor: '#fe2c85',
		  color: '#ffffff',
		}
  }
};

const Wallet = ({tokenContract, tokenAddress, openseaCollection,saleChain, zoomFromSale }) => {
  const { handleConnect, address, ethersProvider } = useWeb3Ctx();
  const [loading, setLoading] = useState(false);
  const [fullscreenSrc, setFullscreenSrc] = useState(null);
  const [isOpen, setOpen] = useState(false);

  const [metadata, setMetadata] = useState([]);

  const zoomContract = useZoom2Contract();

  const [cardIds,setCardIds] = useState([]);
  const [cards, setCards] = useState([])
  const [osCollection, setOsCollection] = useState(null);
  const modeCtx = useContext(ModeCtx);

  useEffect(() => {
    if (address) {

      console.log(address)
     // console.log('ZOOM FROM SALE',zoomFromSale,zoomContract);


      if(tokenContract){
        //console.log('about to get tokens', tokenContract)
        getTokens();
        getTokensURIsFunc();

      }else{
        if(tokenAddress){
          let token = new Contract (tokenAddress, tokenABI.abi, ethersProvider);
          if(!token){
            console.error('Token contract not found on address',tokenAddress);
            return;
          }
          tokenContract = token;
          getTokens();
          getTokensURIsFunc();
        }else{
          console.log('Wallet','no token contract or address');
        }
      }

      if(openseaCollection){
        setOsCollection(openseaCollection);
      }

    }
  }, [address,tokenContract,tokenAddress]);

  useEffect(() => {
    if (!isOpen) {
      setFullscreenSrc(null);
    }
  }, [isOpen]);

  const getTokens = async () => {
    setLoading(true);

    const tokens = await getTokenIds(
      tokenContract,
      zoomFromSale?zoomFromSale:zoomContract,
      address
    );

    console.log('TOKENS',tokens);


    if (tokens) {

      setCardIds(tokens);
    } else {
      setCardIds([]);
    }
    setLoading(false);
  };

  const getTokensURIsFunc = async () => {
   // console.log('uris????????')
    setLoading(true);

    const tokens = await zoomFetchTokenUris(
      tokenContract,
      zoomFromSale?zoomFromSale:zoomContract,
      address
    );

    if (tokens) {
     /*  let cids = [];

      tokens.forEach(token => {
        cids.push({cardID:Number(token.id)});
      });

      console.log(cids); */
      setCards(tokens)
      // setCardIds(tokens);
    } else {
      setCards([]);
      setCardIds([]);
    }
    setLoading(false);
  };



  const setFullscreen = (e) => {
    setOpen(true);
    setFullscreenSrc(e.target.src);
  };

  return (
    <Box sx={sx.root}>
      <Box sx={sx.content}>
        <Typography sx={sx.title} variant="heading" style={{color: modeCtx.lightTheme ? '#000407' : "#fff"}}>
          Wallet
        </Typography>

        {loading || cardIds.length>0?
          <Typography variant="text" component="p" sx={{ my: 4 }}>
            Here you can view all your Digital Collectibles from this collection
          </Typography>
          :
          
          <Typography variant="text" component="p" sx={{ mt: 4 }}>
          {(!loading && cardIds.length === 0 && address)&&<>Your wallet is empty!</>}{" "}
          </Typography>

          
        }


        {!address && (
          <Box sx={{ boxSizing: "border-box"}}>
            <Typography variant="text" component="p" sx={{ mb: 4 }}>
              In order to see your tokens, you need to connect your wallet
            </Typography>
            <Button
              variant="banner"
              sx={sx.connectBtn}
              onClick={handleConnect}
            >
              Connect Wallet
            </Button>
          </Box>
        )}

        {loading && (
          <Box sx={{ textAlign: "center" }}>
            <Spinner color="#fe2c85" />
          </Box>
        )}

        {address && !loading && (
          <>
            {cards.length>0?
              <NftGallery
                style={{width:"100%"}}
                // cardIDs={cardIds}
                cards={cards}
                etherscanLink={tokenContract?tokenContract.address:tokenAddress}
                detailsDisabled={false}
                chainId={saleChain}
              />
              :

              <>
                {osCollection&&<Box mt={'20px'}>
                  <Button
                    variant="banner"
                    sx={sx.connectBtn}     
                    onClick={() => {
                      window.open(
                        `https://opensea.io/collection/${osCollection}?search[sortAscending]=true&search[sortBy]=PRICE&search[toggles][0]=BUY_NOW`,
                        '_blank'
                      );
                    }}
                  >
                    Buy Now
                  </Button>
                </Box>}
              </>
            }
          </>
        )}
      </Box>
      
      
      <FullscreenModal
        setOpen={setOpen}
        isOpen={isOpen}
        imgSrc={fullscreenSrc}
      />

    </Box>
  );
};

export default Wallet;
