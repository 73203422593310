import './FilterHeader.css';
import CustomFilterSelect from './ExplorerComponents/CustomFilterSelect';
import FilterCheckbox from './FilterCheckBox/FilterCheckbox';
import ShowTokenCheckbox from './FilterCheckBox/ShowTokenCheckbox';
import mag from '../../assets/images/Explorer/mag.svg';
import { useState } from 'react';
import { toast } from 'react-toast';
import { KeyboardCommandKey } from '@mui/icons-material';
const FilterHeader = ({ filters, filterChanged, projectName, showToken }) => {
    const [mintedOnlyProject, setmintedOnlyProject] = useState(
        window.location.hash ===
            '#/collie-buddz-metadata-staging.herokuapp.com/api' ||
            window.location.hash ===
                '#/collie-buddz-metadata-server.herokuapp.com/api' ||
            window.location.hash ===
                '#/collie-buddz-metadata-server.ether.cards/api'
            ? true
            : false
    );

    const [idQuery, setIdQuery] = useState('');

    const parseQueryString = (rawQuery) => {
        if (rawQuery === '') {
            return;
        }

        _parseQueryString(rawQuery);
    };

    const _parseQueryString = (queryString) => {
        let rawInput = queryString.replace(/ /g, ',');

        let idArray = rawInput.split(',');

        let cards = [];
        for (let i = 0; i < idArray.length; i++) {
            let item = idArray[i];
            if (item) {
                if (item.indexOf('-') > 0) {
                    let range = item.split('-');
                    if (range[range.length - 1] === '') {
                        toast.warn("Missing 'to' param.");
                    } else {
                        let s = parseInt(range[0]);
                        let e = parseInt(range[range.length - 1]);

                        if (!isNaN(s) && !isNaN(e)) {
                            if (e < s) {
                                let t = s;
                                s = e;
                                e = t;
                            }

                            if (s === e) {
                                toast.warn(
                                    'Range ' + s + ' to ' + e + ' selected. :)'
                                );
                            }

                            if (e > 9999 || s < 0) {
                                toast.warn('Out of range.');
                            } else {
                                for (let j = s; j <= e; j++) {
                                    cards.push(j);
                                }
                            }
                        } else {
                            toast.warn(
                                "Wrong param format. Range should be like '23-42'"
                            );
                        }
                    }
                } else {
                    if (!isNaN(parseInt(item))) {
                        if (parseInt(item) >= 0) {
                            if (parseInt(item) > 9999) {
                                toast.warn('Out of range.');
                            } else {
                                cards.push(parseInt(item));
                            }
                        } else {
                            toast.warn('The card ID must be positive: ' + item);
                        }
                    } else {
                        toast.warn('Invalid value: ' + item);
                    }
                }
            }
        }

        console.log(cards);
    };

    const GetFilters = () => {
        return filters
            ? Object.keys(filters).map((keyname) => {
                  return (
                      <CustomFilterSelect
                          key={keyname}
                          keyname={keyname}
                          filters={filters}
                          filterChanged={filterChanged}
                      />
                  );
              })
            : 'No filters loaded!';
    };

    return (
        <>
            <h1
                className="explorerTitle"
            >
                Collection explorer
            </h1>
            <div
            className="filter-container-sticky"

            >
                <div className="filter-container d-flex flex-row justify-content-between">
                    <div className="filter-container-inner">
                        {/* {mintedOnlyProject && (
            <div>
              <FilterCheckbox filterChanged={filterChanged} />
            </div>
          )} */}
                        {/* <div>
            <ShowTokenCheckbox showToken={showToken} />
          </div> */}
                        <div className="the-filters">{GetFilters()}</div>
                    </div>
                    {/* <div className="id-input-filter d-flex align-items-center">
                        <input
                            type="text"
                            value={idQuery}
                            placeholder="Search ID"
                            onChange={(e) => setIdQuery(e.target.value)}
                            onBlur={(e) => parseQueryString(e.target.value)}
                            onKeyUp={(e) => {
                                if (e.key === 'Enter') {
                                    e.target.blur();
                                }
                            }}
                        />
                        <img src={mag} width="22" />
                    </div> */}
                </div>
            </div>
        </>
    );
};
export default FilterHeader;
