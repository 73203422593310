import { Box, Typography, TextField, Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { GalleryComponent } from 'gallery-component';
import axios from 'axios';
import './MarketplaceSection.css';

import config from '../../config/config';
import { curency } from '../../components/SalePage/abi/constants/curencies';

const MarketPlaceSection = ({ communityId, collectionId, projectChain }) => {
  const BP3 = '@media (max-width: 384px)';

  const sx = {
    button: {
      height: '35px',
      borderRadius: '5px',
      fontWeight: 'bold',
      whiteSpace: 'nowrap',
      backgroundColor: 'unset',
      border: '2px solid #fe2c85',
      color: '#fe2c85',
      margin: '30px 10px 0px 10px',
      '&:hover': {
        border: '2px solid #fe2c85',
        backgroundColor: '#fe2c85',
        color: '#ffffff',
      },
      '&:disabled': {
        border: '2px solid rgba(255, 255, 255, 0.26);',
        color: '#ffffff30',
      },
      [BP3]: {
        width: '100%',
        px: '14px',
        margin: '0',
        mt: '20px',
      },
    },
  };

  const [marketplaceItems, setMarketplaceItems] = useState(null);

  useEffect(() => {
    const getDataForMarketplace = async () => {
      try {
        const resp = await axios.post(
          `${config.APP_API_URL}${communityId}/${collectionId}/metadata/filter?page=1`
        );
        setMarketplaceItems(resp.data.slice(0, 4));
        console.log(resp.data, ' meta');
      } catch (error) {
        console.log(error, ' error');
      }
    };

    getDataForMarketplace();
  }, []);

  return (
    <>
      {marketplaceItems && (
        <>
          <Typography
            align='center'
            variant='bannerTitle'
            sx={{ mt: '20px', mb: '30px' }}
          >
            Marketplace
          </Typography>
          {/* {sortButton()} */}
          <GalleryComponent
            // apiBaseUrl={apiBaseUrl}
            displayTraits={true}
            displayFilters={false}
            displaySelectedFilters={false}
            // openseaUrl={openseaUrl}
            // etherscanUrl={etherscanUrl}
            cardArray={marketplaceItems}
            handleCardClick={(id) => {
              window.open(
                config.APP_API_URL + communityId + `/marketplace/1/card/${id}`
              );
            }}
            handleOwnerClick={(address) => {
              //   searchParams.delete("token_id");
              //   searchParams.set("wallet", address);
              //   setSearchParams(searchParams);
            }}
            // scrollCallback={(nextPage: number) => {
            //   console.log("next page", nextPage);
            //   setCurrentPage(nextPage);
            // }}
            isMarketplace={true}
            erc777Symbol='Dust'
            chainDefaultToken={curency[projectChain]}
            done={true}
          />
          <Box sx={{ textAlign: 'center' }}>
            <Button
              variant='banner'
              sx={sx.button}
              // style={{ margin: '10px' }}
              onClick={() =>
                window.open(
                  config.APP_API_URL + communityId + '/marketplace/' + '1'
                )
              }
            >
              See more
            </Button>{' '}
          </Box>
        </>
      )}
    </>
  );
};

export default MarketPlaceSection;
