import axios from 'axios';
import config from '../../config/config';
const urlParam = window.location.hash.slice(2);


export const getFilters = async (BASE_URL) => {
  if (!urlParam){r
    console.log("reload")
    window.location.reload();
  }
  return new Promise((resolve, reject) => {
    axios.get(`${BASE_URL}/filters`).then((response) => {
      console.log('FILTERS', response.data);
      resolve(response.data);
    });
  });
};

export const getFiltersV2 = async (projectId) => {
  return new Promise((resolve, reject) => {
    axios.get(`${config.LAUNCHPAD_API_URL}/projects/${projectId}/filters`,{headers:{'Authorization':'Bearer 94|fthSA1if31CkN11Q00syd5LRBgUcjfTr7RtyDkBC'}}).then((response) => {
     // console.log('FILTERS V2', response.data);
      resolve(response.data);
    }).catch((e)=> {
      reject(e)
    });
  });
};

export const getFiltersV2Community = async (projectId) => {
  return new Promise((resolve, reject) => {
    axios.get(`${config.LAUNCHPAD_API_URL}/projects/cid/${projectId}/filters`,{headers:{'Authorization':'Bearer 94|fthSA1if31CkN11Q00syd5LRBgUcjfTr7RtyDkBC'}}).then((response) => {
     // console.log('FILTERS V2', response.data);
      resolve(response.data);
    }).catch((e)=> {
      reject(e)
    });
  });
};

export const getFilteredCards = async (BASE_URL,filters) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${BASE_URL}/metadata/filter`, filters)
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(e.message);
      });
  });
};

export const getFilteredCardsV2 = async (projectId,filters,page) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${config.LAUNCHPAD_API_URL}/projects/${projectId}/filter?page=${page}`, filters)
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(e.message);
      });
  });
};
export const getFilteredCardsV2Community = async (projectId,filters,page) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${config.LAUNCHPAD_API_URL}/projects/cid/${projectId}/filter?page=${page}`, filters)
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(e.message);
      });
  });
};

export const getTraitTypes = async() =>{
  return new Promise((resolve, reject) => {
    axios.get(`${config.API_URL}/trait_types`).then((response) => {
      resolve(response.data);
    });
  });
}

export const getMetadata = async(metadataUrl) =>{
  return new Promise((resolve, reject) => {
    axios.get(`${metadataUrl}`).then((response) => {
      resolve(response.data);
    });
  });
}