import { Zoom } from 'zoom-next';
import { getTokenUri } from '.';

export const zoomFetchTokenUris = async (contract, zoom2, address) => {
    const nt = await contract.balanceOf(address);
    
    const ZoomLibraryInstance = new Zoom({ use_reference_calls: true });
    if (nt > 0) {
        const calls = [];
        for (let i = 0; i < nt; i += 1) {
            const tId = ZoomLibraryInstance.addMappingCountCall(
                contract,
                ['tokenOfOwnerByIndex', [address, i]],
                'tokenOfOwnerByIndex(address,uint256) returns (uint256)',
                [{ contract: contract, mapAndParams: ['tokenURI(uint256)', [i]] }],
            );
            calls.push(tId);

            const tUri = ZoomLibraryInstance.addType5Call(
                contract,
                ['tokenURI(uint256)', [i]],
                'tokenURI(uint256) returns (string)',
            );
            calls.push(tUri);
        }
        const ZoomQueryBinary = ZoomLibraryInstance.getZoomCall();
        const combinedResult = await zoom2.combine(ZoomQueryBinary);
        ZoomLibraryInstance.resultsToCache(combinedResult, ZoomQueryBinary);
        const tokenIds = [];
        for (let i = 0; i < nt * 2; i += 2) {
            const id = ZoomLibraryInstance.decodeCall(calls[i]).toString();
            const tokenURI = ZoomLibraryInstance.decodeCall(calls[i + 1]).toString();
            tokenIds.push({ id, tokenURI });
        }

        const newMetadata = [];

        if (tokenIds.length > 0) {
            const promises = [];
            for (var i = 0; i < tokenIds.length; i++) {
                const { id, tokenURI } = tokenIds[i];
                promises.push(new Promise(async (resolve) => {
                    const metadata = await getTokenUri(id, tokenURI);
                    newMetadata.push(metadata);
                    resolve();
                }))
            }
            await Promise.all(promises)
        }

        return newMetadata.sort((a, b) => {
            return Number(a.tokenId) - Number(b.tokenId)
        })
    }
}

export const getTokenUrisForCardDetails = async(contract, zoomContract, cardIDs) =>{


    // mintedsupply doesnt exist in every contract, 
    // so we have to check the the cardId - 1 and the cardID + 1 token URI

    // zoom contract call removed because we need 
    // the try-catch functions



    // let lastToken  = 10001;
    // let firstToken = 0;

    let tokens = []
    try
    {  
       const firstTkn = await contract.tokenURI(cardIDs.prevCardId);
       console.log('firstTkn',firstTkn);
       var obj = {
        [cardIDs.prevCardId]: firstTkn,
      }
      tokens.push(obj)
    }
    catch (error)
    {
        // firstToken = cardIDs.prevCardId;
    }
    try
    {
        const currentTkn = await contract.tokenURI(cardIDs.currentCardId);
        console.log('currentTkn',currentTkn);
        var obj = {
            [cardIDs.currentCardId]: currentTkn,
          }
          tokens.push(obj)
    }
    catch (error)
    {
    }
    try
    {
        const secondTkn = await contract.tokenURI(cardIDs.nextCardId);
        console.log('secondTkn',secondTkn);
        var obj = {
            [cardIDs.nextCardId]: secondTkn,
          }
          tokens.push(obj)
    }
    catch (error)
    {
    //    lastToken = cardIDs.nextCardId
    }
    
    // const cardLength =  Object.keys(cardIDs).length;
    // const ZoomLibraryInstance = new Zoom({ use_reference_calls: true });

    // let calls = {};
    // const metaDatas = [];
    // if(cardLength > 0){
    //     Object.values(cardIDs).forEach(cardID => {
    //         if(cardID>firstToken && cardID <  lastToken){

            
    //         const tokenIdCall = ZoomLibraryInstance.addCall(
    //             // the contract we're calling
    //             contract,
    //             // the method that is returing our ID
    //             ["tokenURI", [cardID]],
    //             // signature used to decode the result
    //             "tokenURI(uint256) returns (string)"
    //             // array of next method calls that will use the result of this current call
    //         );
    //         calls[cardID] = tokenIdCall;
    //     }
    //     });
    //     const ZoomQueryBinary = ZoomLibraryInstance.getZoomCall();
    //     const combinedResult = await zoomContract.combine(ZoomQueryBinary);
    //     ZoomLibraryInstance.resultsToCache(combinedResult, ZoomQueryBinary);

    //     for (let i = 0; i < Object.keys(calls).length; i++) {
    //         let cardMetadata = ZoomLibraryInstance.decodeCall(Object.values(calls)[i]).toString();
    //         let key =  Object.keys(calls)[i];
    //         var obj = {
    //             [key]: cardMetadata,
    //           }
    //         metaDatas.push(obj);
    //     }
    //     return metaDatas;
    // }

    return tokens;
}


// Zoom - get tokens for community gallery
export const getTokenUrisForGallery = async(contract, zoomContract, cardIDs) =>{
    const cardLength =  Object.keys(cardIDs).length;
    const ZoomLibraryInstance = new Zoom({ use_reference_calls: true });
    let calls = {};
    const metaDatas = [];

        cardIDs.forEach(cardID => {
            const tokenIdCall = ZoomLibraryInstance.addCall(
                // the contract we're calling
                contract,
                // the method that is returing our ID
                ["tokenURI", [cardID.cardID]],
                // signature used to decode the result
                "tokenURI(uint256) returns (string)"
                // array of next method calls that will use the result of this current call
            );
            calls[cardID.cardID] = tokenIdCall;
        });
        const ZoomQueryBinary = ZoomLibraryInstance.getZoomCall();
        const combinedResult = await zoomContract.combine(ZoomQueryBinary);
        ZoomLibraryInstance.resultsToCache(combinedResult, ZoomQueryBinary);

        for (let i = 0; i < Object.keys(calls).length; i++) {
            let cardMetadata = ZoomLibraryInstance.decodeCall(Object.values(calls)[i]).toString();
            let key =  Object.keys(calls)[i];
            var obj = {
                [key]: cardMetadata,
              }
            metaDatas.push(obj);
        }
        
        return metaDatas;
}

export const getTokenIds = async (contract, zoomContract, ownerAddress) => {

    const numberOfTokens = await contract.balanceOf(ownerAddress).catch(e=>{return 0});

    const ZoomLibraryInstance = new Zoom();
    const tokenIds = [];
    const item_identifiers = [];
    let callNum = 0;

    for (let i = 0; i < numberOfTokens; i++) {
        // request the token ID
        const tokenIdCall = ZoomLibraryInstance.addCall(
            // the contract we're calling
            contract,
            // the method that is returing our ID
            ["tokenOfOwnerByIndex", [ownerAddress, i]],
            // signature used to decode the result
            "tokenOfOwnerByIndex(address,uint256) returns (uint256)"
            // array of next method calls that will use the result of this current call
        );
        item_identifiers.push(tokenIdCall);
        callNum++;
    }

    // Prepare the binary call
    const ZoomQueryBinary = ZoomLibraryInstance.getZoomCall();
    const combinedResult = await zoomContract.combine(ZoomQueryBinary);
    ZoomLibraryInstance.resultsToCache(combinedResult, ZoomQueryBinary);

    for (let i = 0; i < callNum; i++) {
        let tokenId = ZoomLibraryInstance.decodeCall(item_identifiers[i]).toString();
        tokenIds.push({cardID:Number(tokenId)});
    }
    return tokenIds;
};
