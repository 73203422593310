import React, { useState, useEffect } from 'react';
import { Box, Button } from '@mui/material';
import StickerBookItem from './StickerBookItem';

const BP1 = '@media (max-width: 619px)';
const BP2 = '@media (max-width: 1270px)';

const sx = {
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    [BP2]: {
      justifyContent: 'center',
    },
  },
  button: {
    borderRadius: '5px',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    backgroundColor: 'unset',
    border: '2px solid #fe2c85',
    color: '#fe2c85',
    '&:hover': {
      border: '2px solid #fe2c85',
      backgroundColor: '#fe2c85',
      color: '#ffffff',
    },
    '&:active': {
      border: '2px solid #fe2c85',
      backgroundColor: '#fe2c85',
      color: '#ffffff',
    },
    mt: '20px',
    mx: 'auto',
  },
  buttonHolder: {
    // pb: '10px',
    pt: '20px',
    display: 'flex',
    justifyContent: 'center',
    [BP1]: {
      flexDirection: 'column',
      maxWidth: 'fit-content',
      margin: '0 auto',
      '& button': {
        margin: '0px 0',
      },
    },
  },
};

const StickerBooks = ({ cardList = [] }) => {
  const [allCardsShown, setAllCardsShown] = useState(false);
  const [numberOfCards, setNumberOfCards] = useState(4);

  const renderedCardList = allCardsShown
    ? cardList
    : cardList.slice(0, numberOfCards);

  useEffect(() => {
    if (window.innerWidth < 1280) {
      setNumberOfCards(2);
    }
  }, []);

  return (
    <>
      <Box sx={sx.root}>
        {renderedCardList.map((item, index) => (
          <>
            {item.isActive && (
              <StickerBookItem
                image={item.icon || null}
                isNew={item.isNew || false}
                title={item.name}
                communityName={item.communityName || ''}
                key={index}
                price={item.reward || null}
                instantPlay={item.isActive}
                stickerBookLenght={item.stickers}
                collectionId={item.collectionId}
                communityID={item.communityID}
                stickerBookID={item.stickerBookID}
                maxRedemptions={item.maxRedemptions}
                numberRedeemed={item.numberRedeemed}
              />
            )}
          </>
        ))}
      </Box>
      {cardList?.length > numberOfCards && (
        <Box sx={sx.buttonHolder}>
          <Button
            sx={sx.button}
            onClick={() => setAllCardsShown(!allCardsShown)}
            variant="banner"
          >
            {!allCardsShown ? 'Show all' : 'Hide'}
          </Button>
        </Box>
      )}
    </>
  );
};

export default StickerBooks;
