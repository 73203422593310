import axios from "axios";

export { getContract } from "./contract"
// export { zoomFetchTokenUris } from "./zoom2"

export const getTokenUri = async (tokenId, tokenUri) => {
    //console.log(tokenUri)
    const metadata = await fetch(tokenUri)// await fetch(`https://toddlerpillars-metadata-server.herokuapp.com/api/metadata/${tokenId % 100}/${tokenId}`)
        .then((res) => res.json())
        .catch((err) => console.error(err));
    if (metadata) {
        if (!metadata.tokenId) {
            metadata.tokenId = tokenId;
        }
        if (!metadata.id) {
            metadata.id = tokenId;
        }
        return metadata;
    } else
        // Fetching metadata fail, return an object anyway
        return {
            tokenId: tokenId,
            id: tokenId
        }
};

export const fetchUris = async (tokenUri) => {
    const metadataArray = await axios.all(tokenUri.map((object) =>
        axios.get(Object.values(object)[0]).then((response) => {
            console.log(response.data)
            if (response.data) {
                if (!response.data.tokenId) {
                    response.data.tokenId = Object.keys(object)[0];
                }
                if (!response.data.id) {
                    response.data.id = Object.keys(object)[0];
                }
                return response.data;
            } else
                // Fetching metadata fail, return an object anyway
                return {
                    tokenId: Object.keys(object)[0],
                    id: Object.keys(object)[0]
                }
        })));

        return metadataArray.sort((a, b) => {
            return Number(a.tokenId) - Number(b.tokenId)
        })
    }
