import React from "react";
import { Modal, Box } from "@mui/material";

const BP1 = "@media (max-width: 899px)";
const BP2 = "@media (max-width: 719px)";

const BackdropModal = ({ isOpen }) => {
  return (
    <Modal
      open={isOpen}
      BackdropProps={{ style: { backgroundColor: "rgba(0,0,0,0.8)" } }}
    >
      <Box></Box>
    </Modal>
  );
};

export default BackdropModal;
