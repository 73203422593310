import { useParams, useHistory } from 'react-router-dom';
import axios from 'axios';
import React, { useEffect, useState, useRef, useContext } from 'react';
import { Box, Typography, Button, Grid, ButtonGroup } from '@mui/material';
import { RenderOpenseaStats } from './CurrProjectUtils';
import NftGallery from './NftGallery';
import UtilityCard from './components/UtilityCard';
import SocialButton from '../../components/SocialButton';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import '../Home/Home.css';
//import { useZoom2Contract } from '../../hooks/useContract';
import leftTrick from '../../assets/images/leftTrick.svg';
import rightTrick from '../../assets/images/rightTrick.svg';
import config from '../../config/config';
import SalesV2 from '../../components/SalePage/SalesV2';
import useWeb3Ctx from '../../hooks/useWeb3Ctx';
import { toast } from 'react-toast';
import Wallet from '../../components/SalePage/components/Wallet';
import SalesV3 from '../../components/SalePage/SalesV3';
import CollectionExplorer from '../../components/Explorer/CollectionExplorer';
import { ModeCtx } from '../../context/modeCtx';
import { ethers } from 'ethers';
import { abi } from '../../components/SalePage/abi/Token.json';
import { getContract } from '../../utils';
import { getTokenUrisForGallery } from '../../utils/zoom2';
import { fetchUris } from '../../utils';
import GalaxisRegistry from '../../components/Abis/GalaxisRegistry.json';
import CommunityList from '../../components/Abis/CommunityList.json';
import CommunityRegistry from '../../components/Abis/CommunityRegistry.json';
import StickerbookUtils from '../../components/Abis/StickerbookUtils.json';
import ZoomAbi from '../../components/SalePage/abi/Zoom2.json';
import CommunitiesStickerBookSection from '../../components/stickerbook-section/CommunitiesStickerBookSection';
import { SpinnerCircularFixed as Spinner } from 'spinners-react';
import MarketPlaceSection from './MarketPlaceSection';
import SectionDividers from '../../components/SalePage/components/SectionDividers';

const IPFSURL = 'https://ether-cards.mypinata.cloud/ipfs';
const ERROR_DOWNLOAD_STATS = 'ERROR_DOWNLOAD_STATS';

const BP2 = '@media (max-width: 1345px)';
const BP4 = '@media (max-width: 600px)';
const BP5 = '@media (max-width: 899px)';
const BP6 = '@media (max-width: 700px)';

const sx = {
  root: {
    pt: '120px',
    position: 'relative',
    width: '100%',
    backgroundColor: 'background.main',
    boxSizing: 'border-box',
    mb: '100px',
  },
  content: {
    boxSizing: 'border-box',
    px: '20px',
    maxWidth: '1140px',
    margin: 'auto',
  },
  container: {
    mt: '-5px',
    position: 'relative',
    width: '100%',
    pt: '50px',
    overflow: 'hidden',
    transition: 'all .3s',
    px: '20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [BP4]: {
      pt: '39px',
    },
  },
  descriptionContainer: {
    mt: '50px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flexWrap: 'wrap',
    gap: '20px 20px',
    width: '100%',
  },
  nameContainer: {
    marginTop: '50px',
    position: 'absolute',
    mx: 'auto',
    display: 'flex',
    maxWidth: '1140px',
    width: '100%',
    zIndex: '1',
    backgroundColor: '#FAF9F5',
    [BP4]: {
      marginTop: '35px',
    },
  },
  name: {
    position: 'absolute',
    height: '130px',
    width: 'fit-content',
    backgroundColor: '#fe2c85',
    borderRadius: '15px',
    bottom: '4px',
    left: '0',
    right: '0',
    marginRight: 'auto',
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
    px: '35px',
    [BP5]: {
      height: '90px',
      width: 'fit-content',
      py: '10px',
    },
    [BP4]: {
      px: '25px',
      py: '20px',
      mt: '-60px',
      bottom: '-4px',
    },
  },
  nameText: {
    [BP5]: {
      fontSize: '25px',
    },
  },
  badgeContainer: {
    margin: 'auto',
    maxWidth: '400px',
    borderRadius: '12px',
    padding: '0',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: '15px',
  },
  cardContainer: {
    mx: 'auto',
    mb: '20px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap',
    gap: '40px 16px',
  },
  socialContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: '18px',
    mt: '10px',
    mb: '30px',
  },
  description: {
    fontFamily: 'bau',
    color: '#1C1E23',
    fontSize: '16px',
    lineHeight: '28px',
    maxWidth: '856px',
    width: '100%',
    margin: 'auto',
    mt: '27px',
    ['& p']: {
      my: '16px',
    },
  },
  nftContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
  },
  largeBanner: {
    display: 'block',
    [BP6]: {
      display: 'none',
    },
  },
  smallBanner: {
    display: 'none',
    [BP6]: {
      display: 'block',
    },
  },
  error: {
    paddingTop: '200px',
    minHeight: '650px',
    backgroundColor: '#FAF9F5',
    textAlign: 'center',
    fontSize: '30px',
  },

  tabContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    pb: '30px',
    mx: 'auto',
  },
  tabBtns: {
    color: '#000',
    borderColor: '#000',
    borderWidth: '2px',
    fontFamily: 'poppins-semibold',
    padding: '14px',
    '&:hover': {
      borderColor: '#000',
      backgroundColor: '#000',
      borderWidth: '2px',
      color: '#fff',
      borderRightColor: '#000 !important',
    },
    borderRadius: '10px',
  },
  tabBtnsDark: {
    color: '#fff',
    borderColor: '#fff',
    borderWidth: '2px',
    backgroundColor: '#000',
    fontFamily: 'poppins-semibold',
    padding: '14px',
    '&:hover': {
      borderColor: '#fff',
      backgroundColor: '#fff',
      borderWidth: '2px',
      color: '#000',
      borderRightColor: '#fff !important',
    },
    borderRadius: '10px',
  },
  tabSelectedDark: {
    backgroundColor: '#fff',
    color: '#000',
  },
  tabSelected: {
    backgroundColor: '#000',
    color: '#fff',
  },
  footerDark: {
    fontFamily: 'bau',
    color: '#1C1E23',
    fontSize: '16px',
    lineHeight: '28px',
    maxWidth: '856px',
    width: '100%',
    margin: 'auto',
    ['& p']: {
      my: '30px',
      color: '#FFF9',
    },
    ['& strong']: {
      color: '#FFF',
    },
    textAlign: 'center',
  },
  footerLight: {
    fontFamily: 'bau',
    color: '#1C1E23',
    fontSize: '16px',
    lineHeight: '28px',
    maxWidth: '856px',
    width: '100%',
    margin: 'auto',
    ['& p']: {
      my: '16px',
      color: '#000',
    },
    textAlign: 'center',
  },
};

const CurrentProjectSubpage = () => {
  const [projectChain, setProjectChain] = useState(config.DEPLOYED_CHAIN_ID);
  const [zoomContract, setZoomContract] = useState(null); //useZoom2Contract(projectChain);
  const [marketPlaceAddress, setMarketPlaceAddress] = useState(null);
  const {
    address,
    handleDisconnect,
    ethersProvider,
    defaultProvider,
    getProvider,
    chainId,
    setChain,
  } = useWeb3Ctx();
  const paramm = useParams();
  const param = paramm.project;
  const history = useHistory();
  const [projectStats, setProjectStats] = useState(null);
  const [projectDetails, setProjectDetails] = useState({});
  const [previewProjectId, setPreviewProjectId] = useState(null);
  const [errormsg, setErrormsg] = useState('');
  const [error, setError] = useState(false);
  const [nftID, setNftID] = useState([]);
  const backRef = useRef(null);
  const [mdGrid, setMdGrid] = useState(4);
  const modeCtx = useContext(ModeCtx);
  const [tokenAddress, setTokenAddress] = useState(null);
  const [saleAddress, setSaleAddress] = useState(null);
  const [whiteList, setWhiteList] = useState(null);
  const [tab, setTab] = useState(0);
  const [nfts, setNfts] = useState([]);
  const [projectsStickerbooks, setProjectStickerbooks] = useState(null);
  const [stickerbooksWithData, setStickerbooksWithData] = useState([]);
  const [loading, setLoading] = useState(false);

  const route = history.location.pathname;
  const routeArray = route.split('/');

  const [isReview, setIsReview] = useState(false);

  console.log('route array', routeArray);
  let projectUrl =
    routeArray[routeArray.length - 3] === 'projects'
      ? routeArray[routeArray.length - 4]
      : routeArray[routeArray.length - 3];
  let projectStatus =
    projectUrl === 'review' ? routeArray[routeArray.length - 2] : '';

  // console.log('project url', projectUrl, projectStatus)

  // ********!!!!!!!******** remove once we have the real thing
  // const [salePageActiveHack, setSalePageActiveHack] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 1140) {
      setMdGrid(3);
    }
    if (projectUrl == '') {
      console.log('project');
      axios.get(`${config.API_URL}/projects/${param}`).then(async (res) => {
        if (res.data.message === config.NO_PROJECT_FOUND) {
          toast.error('No project found');
          history.push('/');
        }

        let communityChainId = Object.values(res.data)[0].chain_id;
        let provider = getProvider(communityChainId);
        let galaxisRegistry = getContract(
          config.GALAXIS_REGISTRY,
          GalaxisRegistry.abi,
          provider,
          false
        );
        console.log('galaxis registry', galaxisRegistry);

        let zoomAddress = null;

        if (galaxisRegistry) {
          zoomAddress = await galaxisRegistry
            .getRegistryAddress('ZOOM')
            .catch((e) => {
              console.log('registry error', e);
            });
          console.log('ZOOM ADDRESS', zoomAddress);
        }

        if (zoomAddress) {
          let contract = getContract(zoomAddress, ZoomAbi.abi, provider, false);
          if (contract) {
            setZoomContract(contract);
            console.log('zoom contract', contract);
          }
        }

        setProjectDetails(Object.values(res.data)[0]);
        setProjectChain(communityChainId);

        setNftID(res.data.nft_card_ids);
        // console.log('>>>>>',Object.values(res.data)[0])
      });
    }

    if (projectUrl == 'preview') {
      let projectSessionId = routeArray[routeArray.length - 1];
      axios
        .get(`${config.PREVIEW_URL}/${projectSessionId}/details`)

        .then((res) => {
          // console.log('res PREVIEW', res);
          setProjectDetails(Object.values(res.data)[0]);
          //console.log('project id:', Object.keys(res.data)[0]);
          setIsReview(false);
          setPreviewProjectId(Object.keys(res.data)[0]);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            console.log(error);
            setErrormsg('Invalid session!');
            setError(true);
          } else {
            console.log(error);
            setErrormsg('Project is not found!');
            setError(true);
          }
        });
    }
    if (projectUrl === 'review') {
      console.log('!!!!!!!!!!!!!');

      let projectSessionId = routeArray[routeArray.length - 1];
      console.log('pssid', projectSessionId);

      axios
        .get(
          `${config.API_URL}/review/projects/${projectStatus}/${projectSessionId}`
        )
        .then((res) => {
          // console.log('review/projects res', res);

          setProjectDetails(Object.values(res.data)[0]);
          setIsReview(true);
          setPreviewProjectId(Object.values(res.data)[0].community_id);
        })
        .catch((error) => {
          //console.log('ERR', error);

          if (error.response?.status === 401) {
            console.log(error);
            setErrormsg('Invalid session!');
            setError(true);
          } else {
            console.log(error);
            setErrormsg('Project is not found!');
            setError(true);
          }
        });
    }
  }, []);

  useEffect(() => {
    console.log('PROJECT DETAILS', projectDetails);
    if (projectDetails.chain_id && chainId && address) {
      if (projectDetails.chain_id !== chainId) {
        setChain(projectDetails.chain_id);
        return;
      }
    }
    //--header 'X-API-KEY: 5fd8d01dff2a48ea84e807d548a7453c'

    // this data has to come from cms
    // if (projectDetails.project_name_from_opensea) {
    //   axios
    //     .get(
    //       `${config.OPENSEA_COLLECTION}/${projectDetails.project_name_from_opensea}/stats`,
    //       { headers: { 'X-API-KEY': `${config.OPENSEA_API_KEY}` } }
    //     )
    //     .then((res) => {
    //       console.log('OS STATS', res);
    //       const resData = res.data;
    //       setProjectStats(resData.stats);
    //     })
    //     .catch((error) => {
    //       setProjectStats(ERROR_DOWNLOAD_STATS);
    //       console.log('error', error);
    //     });
    // }
    // end this data has to came from cms

    var typoElemnt = document.getElementById('longDescription');
    typoElemnt.innerHTML = projectDetails.long_description;

    if (
      projectDetails.smart_contract_address &&
      projectDetails.sale_contract_address
    ) {
      setTokenAddress(projectDetails.smart_contract_address);
      setSaleAddress(projectDetails.sale_contract_address);
      setWhiteList(
        projectDetails.whitelist !== '' ? projectDetails.whitelist : null
      );
    }

    /*     if(projectDetails){
          setSaleAddress(config.COLLIE_BUDDZ_SALE_ADDRESS);
          setTokenAddress(config.COLLIE_BUDDZ_TOKEN_ADDRESS);
        } */

    if (
      projectDetails.footer !== null &&
      projectDetails.footer !== undefined &&
      projectDetails.footer !== ''
    ) {
      var typoElemntFooter = document.getElementById('footerDescription');
      typoElemntFooter.innerHTML = projectDetails.footer;
    }
  }, [projectDetails, chainId]);

  const saveScroll = () => {
    const height = document.getElementById('galeryContainer').clientHeight;
    localStorage.setItem('galeryContainer', height);
    localStorage.setItem('windowScroll', window.pageYOffset);
  };

  useEffect(() => {
    const height = localStorage.getItem('galeryContainer');
    const path = localStorage.getItem('windowScroll');
    const clickedBack = localStorage.getItem('clickedBack');
    if (path && clickedBack) {
      //  console.log('path2', path);
      document.getElementById('galeryContainer').style.height = height + 'px';
      window.scrollTo({
        left: 0,
        top: parseInt(path),
        behavior: 'smooth',
      });
      localStorage.removeItem('windowScroll');
      localStorage.removeItem('galeryContainer');
      localStorage.removeItem('clickedBack');
    } else {
      window.scrollTo(0, 0);
    }
  }, []);

  const getShrinkStyle = (bp) => ({ [bp]: { justifyContent: 'center' } });
  // const SectionDividers = () => (
  //   <Box style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '30px' }}>
  //     <img src={leftTrick} className='section-divider-img' />
  //     <img src={rightTrick} className='section-divider-img' />
  //   </Box>
  // );

  const getStyle = (num) => {
    if (modeCtx.lightTheme) {
      if (tab == num) {
        return sx.tabSelected;
      } else {
        return null;
      }
    } else {
      if (tab == num) {
        return sx.tabSelectedDark;
      } else {
        return null;
      }
    }
  };
  useEffect(async () => {
    if (
      projectDetails.smart_contract_address &&
      projectDetails.smart_contract_address !== undefined &&
      projectDetails.nft_card_ids &&
      projectDetails.nft_card_ids.length > 0
    ) {
      const contract = new ethers.Contract(
        projectDetails.smart_contract_address,
        abi,
        defaultProvider
      );

      const gallery = await getTokenUrisForGallery(
        contract,
        zoomContract,
        projectDetails.nft_card_ids
      );
      if (gallery) {
        const metadataArray = await fetchUris(gallery);
        setNfts(metadataArray);
      }
    }
  }, [projectDetails]);

  const contractAbis = {
    GalaxisRegistry,
    CommunityList,
    CommunityRegistry,
  };
  const collectionId = 1;
  const [communityRegistryForSticker, setCommunityRegistryForSticker] =
    useState(null);
  const getContractByAddress = async (contractName, address, provider) => {
    let contractAbi;
    if (!contractName) {
      contractAbi = StickerbookUtils;
    } else {
      contractAbi = contractAbis[contractName];
    }

    console.log('contractName', contractName);

    let contract = null;
    console.log(contractAbis[contractName]);
    try {
      let code = await provider.getCode(address);
      if (code !== '0x') {
        contract = new ethers.Contract(address, contractAbi.abi, provider);
      }
    } catch (e) {
      console.log(e, ' error on contract creation');
      console.log(contractName + ' contract not found');
    }

    return contract;
  };

  const getCommunity = async (provider) => {
    const galaxisRegistryOnTargetChain = await getContractByAddress(
      'GalaxisRegistry',
      config.GALAXIS_REGISTRY,
      provider
    ).catch((e) => console.log('err:', e));

    if (!galaxisRegistryOnTargetChain) {
      toast.error('Target chain not supprted.');
      return;
    }

    const communityListAddress = await galaxisRegistryOnTargetChain
      .getRegistryAddress('COMMUNITY_LIST')
      .catch((e) => console.log);

    let communityList = null;

    if (
      communityListAddress &&
      communityListAddress !== ethers.constants.AddressZero
    ) {
      communityList = await getContractByAddress(
        'CommunityList',
        communityListAddress,
        provider
      );
      console.log('community list contract', communityList);
    }

    if (!communityList) return;
    console.log(projectDetails.community_id);
    let community = await communityList
      .communities(Number(projectDetails.community_id))
      .catch((e) => console.log);
    return community;
  };

  useEffect(() => {
    const getMpa = async () => {
      if (!projectDetails.community_id) return;
      try {
        setLoading(true);
        console.log(
          '------======= getting marketplace address =========------'
        );
        let provider = await getProvider(projectDetails.chain_id);
        const community = await getCommunity(provider);

        console.log('communityt', community);

        if (community && community.registry) {
          let communityRegistry = await getContractByAddress(
            'CommunityRegistry',
            community.registry,
            provider
          );
          setCommunityRegistryForSticker(communityRegistry);
          if (communityRegistry) {
            let marketplace = await communityRegistry.getRegistryAddress(
              `MARKETPLACE_${collectionId}`
            );

            if (
              typeof marketplace === 'string' &&
              !marketplace.startsWith('0x00000')
            ) {
              setMarketPlaceAddress(marketplace);
              console.log(marketplace, ' marketplace');
              mpa = marketplace;
            }
          }
        }
      } catch {
        console.log('Error in MPA');
      }
    };
    getMpa();
  }, [projectDetails.community_id]);

  useEffect(() => {
    const getStickerbooks = async () => {
      console.log('communityRegistryForSticker', communityRegistryForSticker);
      try {
        if (!communityRegistryForSticker) return;
        setLoading(true);
        let provider = await getProvider(projectDetails.chain_id);
        let nrofStickerBookCollections =
          await communityRegistryForSticker.getRegistryUINT(
            `STICKERBOOK_COLLECTION_COUNT`
          );
        console.log('nrofStickerBookCollections', nrofStickerBookCollections);
        const stickerbookIndices = Array.from(
          { length: parseInt(nrofStickerBookCollections) },
          (_, index) => index + 1
        );

        const stickerbooks = await Promise.all(
          stickerbookIndices.map(async (index) => {
            const stickerbookAddressFromContract =
              await communityRegistryForSticker.getRegistryAddress(
                `STICKERBOOK_COLLECTION_${index}`
              );
            console.log(
              stickerbookAddressFromContract,
              ' stickerbookAddressFromContract'
            );

            const sCContrac = await getContractByAddress(
              null,
              stickerbookAddressFromContract,
              provider
            );
            console.log(sCContrac, ' sCContrac');
            try {
              const data = await sCContrac.getAllStickerbookData();
              console.log(data, ' dataaaaaaaaaaa');
              if (data?.length > 0) {
                return data.map((element) => ({
                  collectionId: index,
                  stickerBook: element,
                }));
              }
            } catch (error) {
              console.log(error);
            }

            return [];
          })
        );

        // Flatten the array of arrays into a single array of sticker books
        const flattenedStickerbooks = stickerbooks.flat();
        setProjectStickerbooks(flattenedStickerbooks);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };

    getStickerbooks();
  }, [communityRegistryForSticker]);

  useEffect(() => {
    if (!projectsStickerbooks) return;

    async function fetchStickerBookData(stickerBook) {
      console.log(stickerBook, ' stickerbook');
      const stickerBookWithData = {
        isActive: stickerBook.stickerBook.active,
        stickers: stickerBook.stickerBook.stickers.length,
        collectionId: stickerBook.collectionId,
        communityID: projectDetails.community_id,
        stickerBookID: stickerBook.stickerBook.stickerBookId,
        maxRedemptions: stickerBook.stickerBook.maxRedemptions,
        numberRedeemed: stickerBook.stickerBook.numberRedeemed,
      };

      try {
        setLoading(true);
        const response = await axios.get(
          `${IPFSURL}/${stickerBook.stickerBook.ipfsHash}/manifest.json`
        );

        stickerBookWithData.name = response.data.name;
        stickerBookWithData.reward = response.data.reward;
        stickerBookWithData.icon = `${IPFSURL}/${stickerBook.stickerBook.ipfsHash}/${response.data.icon}`;

        setLoading(false);
        return stickerBookWithData;
      } catch (error) {
        console.log('Error fetching sticker book data:', error);
        setLoading(false);
        return null;
      }
    }
    async function processStickerbooks(projectsStickerbooks) {
      if (!projectsStickerbooks || projectsStickerbooks.length === 0) return;

      try {
        setLoading(true);
        const stickerbooksWithData = await Promise.all(
          projectsStickerbooks.map((stickerBook) => {
            if (stickerBook.stickerBook?.active)
              return fetchStickerBookData(stickerBook);
            else return;
          })
        );
        const filteredSBData = stickerbooksWithData.filter(
          (item) => item !== undefined
        );

        setStickerbooksWithData((prevArray) => [
          ...prevArray,
          ...filteredSBData,
        ]);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log('Error processing sticker books:', error);
      }
    }

    // Call the function with projectsStickerbooks
    processStickerbooks(projectsStickerbooks);
  }, [projectsStickerbooks]);

  return (
    <>
      <>
        <Header disconnect={handleDisconnect} address={address} />
        {error ? (
          <Typography sx={sx.error}>{errormsg}</Typography>
        ) : (
          <Box sx={sx.root} id="galeryContainer">
            <Box sx={sx.banner}>
              <Box sx={sx.largeBanner}>
                <img
                  src={
                    projectUrl == 'review' || projectUrl == ''
                      ? `${config.AWS_URL}${projectDetails.large_image}`
                      : projectDetails.large_image
                  }
                  style={{ width: '100%' }}
                  alt="banner"
                />
              </Box>
              <Box sx={sx.smallBanner}>
                <img
                  src={
                    projectUrl == 'review' || projectUrl == ''
                      ? `${config.AWS_URL}${projectDetails.small_image}`
                      : projectDetails.small_image
                  }
                  sx={sx.smallBanner}
                  style={{ width: '100%' }}
                  alt="banner"
                />
              </Box>
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  backgroundColor: '#FAF9F5',
                }}
              >
                <Box sx={sx.nameContainer}>
                  <Box sx={sx.name}>
                    <Typography
                      sx={sx.nameText}
                      variant="bannerCubeText"
                      style={{ color: '#FFF' }}
                      align="center"
                    >
                      {projectDetails.symbol}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              sx={sx.container}
              style={{
                paddingBottom:
                  projectDetails.footer !== null ? '10px' : '115px',
                backgroundColor: modeCtx.lightTheme ? '#faf9f5' : '#050D15',
              }}
            >
              <div className="border" style={{ position: 'relative' }}>
                <Box sx={{ maxWidth: '100%', textAlign: 'center', mt: '20px' }}>
                  {projectDetails.name && (
                    <>
                      <Typography
                        align="center"
                        variant="projectSubtitle"
                        style={{
                          color: modeCtx.lightTheme ? '#000407' : '#fff',
                        }}
                      >
                        {projectDetails.name}
                      </Typography>
                    </>
                  )}
                  {projectDetails.social_media_links && (
                    <Box sx={sx.socialContainer}>
                      <>
                        {projectDetails.social_media_links.map(
                          (link, index) => (
                            <SocialButton
                              key={index}
                              url={link.url}
                              style={{ width: '26px' }}
                              icon={link.icon}
                            />
                          )
                        )}
                      </>
                    </Box>
                  )}

                  {projectDetails.project_name_from_opensea && (
                    <Box sx={sx.badgeContainer}>
                      {/* opensea stats box */}
                      {(projectDetails.opensea_stats && projectDetails.opensea_collection) ? (
                        <RenderOpenseaStats
                          stats={projectDetails.opensea_stats}
                          collection={projectDetails.opensea_collection}
                        />
                      ) : (
                        ''
                      )}
                    </Box>
                  )}

                  {tokenAddress &&
                    saleAddress &&
                    projectDetails.status === 'live' && (
                      <>
                        {projectDetails.sale_version === 3 ? (
                          <SalesV3
                            communityId={projectDetails.community_id}
                            saleChain={projectChain}
                            tokenAddress={tokenAddress}
                            saleAddress={saleAddress}
                            symbol={projectDetails.symbol}
                            labels={{
                              preSaleTitle: projectDetails.presale_name
                                ? projectDetails.presale_name
                                : 'Pre-Sale',
                              preSaleDescription: projectDetails.presale_text
                                ? projectDetails.presale_text
                                : '',
                              publicSaleTitle: projectDetails.main_sale_name
                                ? projectDetails.main_sale_name
                                : 'Public Sale',
                              publicSaleDescription:
                                projectDetails.main_sale_text
                                  ? projectDetails.main_sale_text
                                  : '',
                            }}
                            whitelist={whiteList}
                            openSeaName={
                              projectDetails?.project_name_from_opensea
                            }
                            zoomContract={zoomContract}
                          />
                        ) : (
                          <SalesV2
                            tokenAddress={tokenAddress}
                            saleAddress={saleAddress}
                            symbol={projectDetails.symbol}
                            labels={{
                              preSaleTitle: projectDetails.presale_name
                                ? projectDetails.presale_name
                                : 'Pre-Sale',
                              preSaleDescription: projectDetails.presale_text
                                ? projectDetails.presale_text
                                : '',
                              publicSaleTitle: projectDetails.main_sale_name
                                ? projectDetails.main_sale_name
                                : 'Public Sale',
                              publicSaleDescription:
                                projectDetails.main_sale_text
                                  ? projectDetails.main_sale_text
                                  : '',
                            }}
                            whitelist={whiteList}
                            openSeaName={
                              projectDetails?.project_name_from_opensea
                            }
                          />
                        )}
                      </>
                    )}
                  {projectDetails.status === 'live' && (
                    <Typography
                      align="center"
                      variant="bannerTitle"
                      style={{ color: modeCtx.lightTheme ? '#000' : '#fff' }}
                    >
                      About the project
                    </Typography>
                  )}

                  <Typography
                    sx={sx.description}
                    variant="bannerText"
                    id="longDescription"
                    className="longDescription"
                  >
                    {projectDetails.long_description}
                  </Typography>
                </Box>
              </div>
              {projectDetails.traits && (
                <div className="borderupcoming">
                  <Box sx={{ maxWidth: '100%', textAlign: 'center' }}>
                    <SectionDividers />
                    <Typography
                      align="center"
                      variant="projectSubtitle"
                      style={{ color: modeCtx.lightTheme ? '#000' : '#fff' }}
                    >
                      Utility Traits
                    </Typography>

                    <Typography
                      sx={sx.description}
                      variant="bannerText"
                      style={{
                        maxWidth: '638px',
                        marginBottom: '50px',
                        marginTop: '20px',
                      }}
                    >
                      Galaxis empowers your Digital Collectibles with the most
                      dynamic features and functions available. Trait holders
                      may gain special access, community benefits and so much
                      more!
                    </Typography>
                    <Box sx={{ ...sx.cardContainer, ...getShrinkStyle(BP4) }}>
                      <Grid
                        container
                        spacing={1.8}
                        style={{ justifyContent: 'center' }}
                      >
                        {projectDetails.traits.map((trait, index) => (
                          <Grid item xs={12} sm={6} md={mdGrid} key={index}>
                            <UtilityCard
                              type={trait.type}
                              key={index}
                              title={trait.name}
                              text={trait.description}
                              icon={trait.icon_magenta}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  </Box>
                </div>
              )}
              {projectDetails.status === 'community' && (
                <div className="borderupcoming">
                  <SectionDividers />

                  {modeCtx.lightTheme ? (
                    <ButtonGroup
                      sx={sx.tabContainer}
                      variant="outlined"
                      size="medium"
                      aria-label="medium outlined button group"
                    >
                      <Button
                        sx={{
                          ...(modeCtx.lightTheme ? sx.tabBtns : sx.tabBtnsDark),
                          ...getStyle(0),
                        }}
                        onClick={() => {
                          setTab(0);
                        }}
                      >
                        GALLERY
                      </Button>
                      <Button
                        sx={{
                          ...(modeCtx.lightTheme ? sx.tabBtns : sx.tabBtnsDark),
                          ...getStyle(1),
                        }}
                        onClick={() => {
                          setTab(1);
                        }}
                      >
                        WALLET
                      </Button>
                    </ButtonGroup>
                  ) : (
                    <ButtonGroup
                      sx={sx.tabContainer}
                      variant="outlined"
                      size="medium"
                      aria-label="medium outlined button group"
                    >
                      <Button
                        sx={{
                          ...(modeCtx.lightTheme ? sx.tabBtns : sx.tabBtnsDark),
                          ...getStyle(0),
                        }}
                        onClick={() => {
                          setTab(0);
                        }}
                      >
                        GALLERY
                      </Button>
                      <Button
                        sx={{
                          ...(modeCtx.lightTheme ? sx.tabBtns : sx.tabBtnsDark),
                          ...getStyle(1),
                        }}
                        onClick={() => {
                          setTab(1);
                        }}
                      >
                        WALLET
                      </Button>
                    </ButtonGroup>
                  )}

                  {tab === 0 ? (
                    <>
                      {projectDetails.nft_card_ids && (
                        <Box sx={sx.nftContainer}>
                          <Typography
                            align="center"
                            variant="projectSubtitle"
                            style={{
                              color: modeCtx.lightTheme ? '#000' : '#fff',
                            }}
                            sx={sx.title}
                          >
                            Digital Collectible Gallery
                          </Typography>
                          <Typography
                            sx={sx.description}
                            variant="bannerText"
                            align="center"
                            style={{
                              maxWidth: '638px',
                              marginBottom: '50px',
                              marginTop: '20px',
                            }}
                          >
                            Check out a few masterpieces from the collection.
                            These visual samples display some of the most
                            incredible artwork and features available on the
                            platform.
                          </Typography>
                          <Box sx={sx.cardContainer} className="galery">
                            {nfts && nfts.length > 0 && (
                              <NftGallery
                                // cardIDs={projectDetails.nft_card_ids}
                                cards={nfts}
                                etherscanLink={
                                  projectDetails.smart_contract_address
                                }
                                scrollTo={saveScroll}
                                chainId={projectChain}
                              />
                            )}
                          </Box>
                        </Box>
                      )}
                    </>
                  ) : (
                    <>
                      <Wallet
                        tokenAddress={projectDetails.smart_contract_address}
                        openseaCollection={
                          projectDetails.project_name_from_opensea
                        }
                        zoomFromSale={zoomContract}
                        saleChain={projectChain}
                      />
                    </>
                  )}
                </div>
              )}
              <div className="border dark" style={{ position: 'relative' }}>
                {marketPlaceAddress && (
                  <>
                    <MarketPlaceSection
                      communityId={projectDetails.community_id}
                      collectionId={collectionId}
                      projectChain={projectDetails.chain_id}
                    />
                  </>
                )}
              </div>
              {projectDetails.footer && (
                <Typography
                  sx={modeCtx.lightTheme ? sx.footerLight : sx.footerDark}
                  id="footerDescription"
                  className="footerDescription"
                ></Typography>
              )}
            </Box>
            {previewProjectId !== null && (
              <CollectionExplorer
                projectId={previewProjectId}
                reviewMode={isReview}
                metaDataServer={
                  'https://hedgiefund-metadata-staging.herokuapp.com/api'
                }
              />
            )}
          </Box>
        )}
      </>

      <Footer />
    </>
  );
};

export default CurrentProjectSubpage;
