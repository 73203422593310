import { createContext, useState, useEffect } from "react";

export const ModeCtx = createContext({
  lightTheme: false,
  setLightTheme: () => {},
});

const ModeCtxProvider = (props) => {
  const modeFromLocalStorage = JSON.parse(localStorage.getItem("lightMode"));
  const [lightMode, setLightMode] = useState(modeFromLocalStorage);

  const toggleLightMode = () => {
    console.log("lightmode!!!!")
    setLightMode((prevMode) => !prevMode);
  };

  useEffect(() => {
    if (!lightMode) {
      document
        .querySelectorAll(".light")
        .forEach((e) => e.classList.remove("light"));
      document.body.classList.add("dark");
      localStorage.setItem("lightMode", false);
    } else {
      document
        .querySelectorAll(".dark")
        .forEach((e) => e.classList.remove("dark"));
      document.body.classList.add("light");
      localStorage.setItem("lightMode", true);
    }


  }, [lightMode]);

  return (
    <ModeCtx.Provider
      value={{
        lightTheme: lightMode,
        setLightTheme: toggleLightMode,
      }}
    >
      {props.children}
    </ModeCtx.Provider>
  );
};

export default ModeCtxProvider;
