import React, { useState, useEffect } from 'react';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useParams } from 'react-router-dom';
import opensea from '../assets/images/social/osiconNEW.svg';
import etherscan from '../assets/images/social/etherscan.svg';
import { ElectricScooterSharp } from '@mui/icons-material';
import axios from 'axios';
import config from '../config/config';
import { minWidth } from '@mui/system';
import { ModeCtx } from '../context/modeCtx';
import { useContext } from 'react';

const BP1 = '@media (max-width: 299px)';
const BP2 = '@media (max-width: 470px)';
const BP3 = '@media (max-width: 385px)';
const BP4 = '@media (max-width: 1005px)';
const BP5 = '@media (max-width: 899px)';

const sx = {
  root: {
    position: 'relative',
    width: '100%',
    borderRadius: '15px',
    // border: '1px solid rgba(161, 161, 161, 0.48)',
    boxShadow: '0px 3px 6px 0px rgba(0,0,0,0.1)',
  },
  imageContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    '& img': {
      width: '100%',
    },
    cursor: 'pointer',
    borderRadius: '15px',
  },
  content: {
    width: '100%',
    mt: '0px',
    zIndex: '1',
    borderRadius: '0px 0px 15px 15px',
    px: '25px',
    pt: '20px',
    [BP3]: {
      px: '10px',
    },
    pb: '25px',
  },
  title: {
    fontFamily: 'poppins-semibold',
    color: '#000',
    width: '100%',
    zIndex: 10,
    transition: 'all .3s',
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    wordBreak: 'break-word',
    overflow: 'hidden',
    mt: '0px',
    mb: '6px',
    fontSize: '18px',
    lineHeight: '26px',
    maxHeight: '54px',
    minHeight: '54px',
    textAlign: 'left',
  },

  subTitle: {
    fontFamily: 'poppins-semibold',
    color: '#000',
    width: '100%',
    zIndex: 10,
    transition: 'all .3s',
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    wordBreak: 'break-word',
    overflow: 'hidden',
    mt: '0px',
    mb: '6px',
    fontSize: '14px',
    lineHeight: '16px',
    maxHeight: '16px',
    minHeight: '16px',
    textAlign: 'left',
  },

  socialContainer: {
    mt: '20px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: '10px',
  },
  socialIcon: {
    height: '37px',
  },
};

const Card = ({ card, etherscanLink, scrollTo, detailsDisabled, chainId }) => {
  const history = useHistory();
  const param = useParams();
  const [tokenUri, setTokenUri] = useState([]);
  const [loading, setLoading] = useState(true);
  const route = history.location.pathname;
  const routeArray = route.split('/');
  const params = routeArray[routeArray.length - 1];
  let projectUrl = routeArray[routeArray.length - 3];

  const [tokenId, setTokenId] = useState(null);
  const modeCtx = useContext(ModeCtx);

  //console.log('CCCAAARRRD',card);

  // useEffect(() => {
  //   const id = Object.values(cardID)[0];
  //   setTokenId(id);
  //   var body = {
  //     contractAddress: etherscanLink,
  //     tokenId: id,
  //   };
  //   if (projectUrl == 'preview') {
  //     axios
  //       .get(`${config.PREVIEW_URL}/${params}/nfts/${id}/details`)
  //       .then((res) => {
  //         setTokenUri(res.data);
  //       })
  //       .catch((error) => {
  //         console.log('error', error);
  //       });
  //   }
  //   if (projectUrl == '') {
  //     axios
  //       .post(`${config.AGGREGATOR_URL}`, body)
  //       .then((res) => {
  //         setTokenUri(Object.values(res.data)[0]);
  //       })
  //       .catch((error) => {
  //         console.log('error', error);
  //       });
  //   }
  // }, []);

  const openCardDetails = () => {
    console.log('carddetails');

    if (detailsDisabled) {
      return;
    }

    if (projectUrl == '') {
      history.push(`/projects/${param.project}/${card.tokenId}`);
    }
    if (projectUrl == 'preview') {
      history.push(`${param.project}/${card.tokenId}`);
    }
    if (scrollTo) {
      localStorage.setItem('windowScroll', window.scrollY);
      scrollTo();
    }
  };

  return (
    <Box sx={sx.root}>
      <Box sx={sx.imageContainer}>
        <CircularProgress
          style={{ display: loading ? 'block' : 'none', margin: '15px auto' }}
        />

        <img
          style={{
            opacity: loading ? 0 : 1,
            zIndex: '0',
            borderRadius: '15px 15px 0px 0px',
          }}
          src={card.image}
          onClick={() => openCardDetails()}
          onLoad={() => setLoading(false)}
          alt="Card"
        />

        {card.overlays && card.overlays.length > 0 && (
          <img
            style={{
              opacity: loading ? 0 : 1,
              zIndex: '0',
              borderRadius: '15px 15px 0px 0px',
              pointerEvents: 'none',
              position: 'absolute',
              top: '0',
              left: '0',
            }}
            src={card.overlays[0].layers[0]}
            alt="Card overlay"
          />
        )}

        <Box
          sx={sx.content}
          style={{
            zIndex: '1',
            background: modeCtx.lightTheme
              ? '#fff'
              : 'linear-gradient(rgb(20, 33, 46), rgb(14, 25, 36))',
          }}
        >
          <Box>
            <Typography
              sx={sx.title}
              style={{ color: !modeCtx.lightTheme && '#fff' }}
            >
              {card.name}
            </Typography>
            <Typography sx={sx.subTitle}>Token ID: #{card.tokenId}</Typography>

            {etherscanLink && (
              <Box sx={sx.socialContainer}>
                <a
                  href={`${
                    chainId === 1 || chainId === 5
                      ? config.ETHERSCAN_URL
                      : config.POLYGONSCAN_URL
                  }/address/${etherscanLink}`}
                  target="_blank"
                >
                  <img src={etherscan} style={sx.socialIcon} />
                </a>
                <a
                  href={`${
                    chainId === 1 || chainId === 5
                      ? config.OPENSEA_URL + 'assets/ethereum/'
                      : config.OPENSEA_URL + 'assets/matic/'
                  }${etherscanLink}/${card.tokenId}`}
                  target="_blank"
                >
                  <img src={opensea} style={sx.socialIcon} />
                </a>
              </Box>
            )}
          </Box>
          <Box></Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Card;
