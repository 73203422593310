import { Box, Grid } from '@mui/material';
import React, { useState,useEffect} from 'react';

import Card from '../../components/Card';
const sx = {
  nftGallery: {
    maxWidth: '1140px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: '15px',
    flexWrap: 'wrap',
    mx:'auto'
  },
};

const NftGallery = ({ cards, etherscanLink, scrollTo, detailsDisabled, style, chainId }) => {
  const [mdGrid, setMdGrid] = useState(3);

  useEffect(() => {
    if (window.innerWidth < 1140) {
      setMdGrid(4);
    }

    console.log('CARDS:::',cards,chainId);
  }, []);

  return (
    <>
      {cards.length > 0 ? (
        <Box sx={sx.nftGallery} style={style}>
          <Grid container spacing={1.5}>
            {cards.map((cardMetadata,index) => (
              <Grid key={index} item xs={12} sm={6} md={mdGrid}>
                <Card
                  card={cardMetadata}
                  etherscanLink={etherscanLink}
                  scrollTo={scrollTo}
                  detailsDisabled={detailsDisabled}
                  chainId={chainId}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      ) : (
        ''
      )}
    </>
  );
};
export default NftGallery;
