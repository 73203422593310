import { Typography, Box } from "@mui/material";
import PopupModal from "./PopupModal";
import MintQuantity from "./MintQuantity";
import { SALE_STATUS } from "../Sales";

const sx = {
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "top",
    textAlign: "center",
    gap: "0 25px",
  },
  title: {
    fontFamily: "poppins",
    fontWeight: 700,
    size: 26,
    mb: 1,
  },
  text: {
    fontFamily: "poppins",
    fontSize: 12,
  },
};

const CheckoutModal = ({
  tokenName,
  setOpen,
  isOpen,
  mintPresale,
  mintSale,
  isPresale,
 
  withCreditCard,

  salePrice,
  presalePrice,
  tokenPrice,
  presaleTokenPrice,
  
  whitelistLimit,
  withToken,
  erc677Symbol,
  chain,

  maxMintPerTx
}) => {
  
  const headerText = isPresale ? "Presale Checkout" : "Checkout";
  const nativeTokenSymbol = (chain===1||chain===5)?'ETH':'MATIC';

//0.000000000000000001

  return (
    <PopupModal setOpen={setOpen} isOpen={isOpen} header={headerText}>
      <Box sx={sx.content}>
        <Typography sx={sx.title}>{tokenName} Token</Typography>
        <Typography sx={sx.text}>
          Please select the number of Digital Collectible(s) you wish to mint.
        </Typography>

        {isPresale && (
          <MintQuantity
            price={Number(withToken?presaleTokenPrice:presalePrice)}
            originalPrice={withToken?presaleTokenPrice:presalePrice}
            maxAmount={whitelistLimit}
            onClickMint={mintPresale}
            tokenSymbol={withToken?erc677Symbol:nativeTokenSymbol}
            withToken={withToken}
            withCreditCard={withCreditCard}
            unlimitedMint = {whitelistLimit===maxMintPerTx}
          />
        )}
        {!isPresale && (
          <MintQuantity
            price={Number(withToken?tokenPrice:salePrice)}
            originalPrice={withToken?tokenPrice:salePrice}
            maxAmount={whitelistLimit}
            onClickMint={mintSale}
            tokenSymbol={withToken?erc677Symbol:nativeTokenSymbol}
            withToken={withToken}
            withCreditCard={withCreditCard}
            unlimitedMint = {whitelistLimit===maxMintPerTx}
          />
        )}
      </Box>
    </PopupModal>
  );
};

export default CheckoutModal;
