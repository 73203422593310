import { Box } from "@mui/material";
import leftTrick from '../../../assets/images/leftTrick.svg';
import rightTrick from '../../../assets/images/rightTrick.svg';

const SectionDividers = () => (
	<div className="section-divider">
		<Box style={{ display: 'flex', justifyContent: 'space-between'}} mt={'30px'} mb={'30px'}>
		<img src={leftTrick}	className='section-divider-img' />
		<img src={rightTrick} className='section-divider-img' />
		</Box>
	</div>
  );

export default SectionDividers;