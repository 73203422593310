import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import eths from '../../../assets/images/eth-icon.svg';
import matic from '../../../assets/images/matic-icon.svg';
import { ModeCtx } from '../../../context/modeCtx';
import { useContext } from 'react';
const innerSx = {
  root: {
    minHeight: '80px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '0 8px',
  },
  value: {
    color: '#1C1E23',
    fontSize: '26px',
    lineHeight: '28px',
    color: '#000407',
    fontFamily: 'poppins-medium',
  },
  label: {
    color: '#000407',
    fontSize: '12px',
    fontFamily: 'poppins',
    lineHeight: '20px',
    mt: '5px',
  },
};

const InfoBox = ({ value, label, eth, style, sx , chain, tokenName }) => {
  const modeCtx = useContext(ModeCtx);

  const [icon,setIcon] = useState(chain?(chain===1||chain===5?eths:matic):eths);

  return <Box sx={{ ...innerSx.root, ...sx }} style={{ ...style }}>
    <Box>
      <Box sx={{...innerSx.content, display:"inline-flex"}}>
          {eth ? <img src={icon} style={{ width: (chain===1|chain===5)?'10px':'15px',filter: modeCtx.lightTheme ? "none" : "brightness(0) invert(1)" }} alt="eth" /> : ''}
        <Typography sx={innerSx.value}>
          {tokenName&&
            <span style={{fontSize:'65%'}}>{tokenName} </span>
          }
          {value?value:(eth?'---':0)} 
        </Typography>
      </Box>

      <Typography sx={innerSx.label}>{label}</Typography>
    </Box>
  </Box>
}

InfoBox.propTypes = {
  value: PropTypes.any.isRequired,
  label: PropTypes.string.isRequired,
  eth: PropTypes.any,
};

export default InfoBox;
