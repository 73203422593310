import React from "react";
import { Box, ThemeProvider } from "@mui/material";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  HashRouter,
} from "react-router-dom";
import theme from "./theme";
import routes from "./navigation/routes";
import { ToastContainer } from "react-toast";
// import Web3Manager from './components/Web3Manager/Web3Manager';
import { Suspense } from "react";
import NotFound from "./NotFound";
import ModeCtxProvider from "./context/modeCtx";

const Web3Manager = React.lazy(() =>
  import("./components/Web3Manager/Web3Manager")
);

const sx = {
  root: {
    height: "100%",
    position: "relative",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    // backgroundColor: '#FAF9F5',
  },
};

const App = () => {
  return (
    <ModeCtxProvider>
      <ThemeProvider theme={theme}>
        <Box sx={sx.root}>
          <HashRouter basename="/">
            <Suspense fallback={<div>Loading...</div>}>
              <Web3Manager>
                <Switch>
                  {routes.map(({ path, component, exact }) => (
                    <Route
                      key={path}
                      path={path}
                      component={component}
                      exact={exact}
                    />
                  ))}
                  <Route component={NotFound} />
                </Switch>
              </Web3Manager>
            </Suspense>
          </HashRouter>
          <ToastContainer position="bottom-right" delay={4000} />
        </Box>
      </ThemeProvider>
    </ModeCtxProvider>
  );
};
export default App;
