import { getFilteredCardsV2, getFilteredCardsV2Community } from "./Api";

const ITEMS_PER_PAGE = 100;

export const loadNext = (nfts,ITEMS_PER_PAGE,currentPageRef, setCurrentPage,setCards) => {
    let c = [];
    let end = nfts.length < ITEMS_PER_PAGE ? nfts.length : ITEMS_PER_PAGE;
    for (let i = 0; i < end; i++) {
      if (currentPageRef.current * ITEMS_PER_PAGE + i < nfts.length) {
        c.push(nfts[currentPageRef.current * ITEMS_PER_PAGE + i]);
      }
    }
    setCards((cards) => cards.concat(c));
    setCurrentPage(currentPageRef.current + 1);
  };

export const loadNextV2 =  (cards, currentPageRef, setCurrentPage, setCards, setResultCount, projectId, mappedFilters, reviewMode)=>{
  //console.log('filters in loadnext',mappedFilters)
  let filterFn = reviewMode?getFilteredCardsV2Community:getFilteredCardsV2;
  let page = currentPageRef.current + 1;
  filterFn(projectId, mappedFilters,page).then(res=>{
    console.log('loadnextV2 res',res)
    setCards((cards) => cards.concat(res.data.nfts));
    setResultCount(res.data.result_count);
    setCurrentPage(page);


  });



}