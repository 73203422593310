import { useContext, useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
//import config from '../../config/index';
import './ExplorerCard.css';
import ExplorerCard from './ExplorerCard';
import { loadNext } from './InfiniteScrollHelpers';
import { Data } from './DataContext';
import { removeFilter } from './ExplorerHelpers';
import { loadNextV2 } from './InfiniteScrollHelpers';
import { SpinnerCircular } from 'spinners-react';

const Explorer = ({ filtersAreActive, projectId, showToken , reviewMode}) => {
    //const LAYER_SERVER = config.LAYER_SERVER;

    const ITEMS_PER_PAGE = 20;
    const [cards, setCards] = useState([]);
    const [currentPage, _setCurrentPage] = useState(0);
    const currentPageRef = useRef(currentPage);
    const setCurrentPage = (val) => {
        currentPageRef.current = val;
        _setCurrentPage(val);
    };

    const [resultCount, setResultCount] = useState(null);
    const [mappedFilters, setMappedFilters] = useState({ sides: {} });

    const { traitTypes } = useContext(Data);

    useEffect(() => {
        let filters = filtersAreActive ? removeFilter(filtersAreActive) : {};
       // console.log('explorer filter',filters);
        setCards([]);
        setCurrentPage(0);

        // console.log('ACTIVE FILTERS', filters);

        let mf = { sides: {} };

        Object.keys(filters).map((item, idx) => {
            // console.log(item, idx);

            let order = filters[item].layer.order;
            let side = [filters[item].layer.side.order];
            let dna = filters[item].dna;

            if (!mf['sides'][side]) {
                mf['sides'][side] = {};
            }

            mf['sides'][side][order] = dna;
        });

        //console.log('MAPPED FILTERS',mf);
        setMappedFilters(mf);

        if(resultCount===0){
            //Force to load next batch
            loadNextV2(
                cards,
                currentPageRef,
                setCurrentPage,
                setCards,
                setResultCount,
                projectId,
                mf,
                reviewMode
            )
        }

        // loadNextV2(cards,currentPageRef,setCurrentPage, setCards, setResultCount, projectId, mf);
    }, [filtersAreActive]);

    /* useEffect(() => {
    setCards([]);
    console.log('LOADNEXT FIRST',currentPageRef)
    setCurrentPage(0);
    loadNext(nfts, ITEMS_PER_PAGE, currentPageRef,setCurrentPage, setCards);
  }, [nfts]); */

    const renderCards = () => {
        return cards.map((card, i) => {
            return (
                <div
                    key={i}
                    className="col-lg-3 col-md-4 mb-4"
                    style={{ marginBottom: '2rem' }}
                >
                    <div className="layer-image-preview">
                        <div>
                            <ExplorerCard
                                card={card}
                                showToken={showToken}
                                projectId={projectId}
                                reviewMode={reviewMode}
                            />
                        </div>
                    </div>
                </div>
            );
        });
    };

    return (
        <>
            <div style={{ paddingTop: '60px' }}></div>
            {filtersAreActive && (
                <p style={{ marginLeft: '5px', marginBottom: '10px' }} className='filter-text'>
                    Filter result:{' '}
                    <span style={{ fontWeight: '500' }}>{resultCount} </span>
                </p>
            )}
            <div>
                <InfiniteScroll
                    dataLength={cards.length}
                    next={() =>
                        loadNextV2(
                            cards,
                            currentPageRef,
                            setCurrentPage,
                            setCards,
                            setResultCount,
                            projectId,
                            mappedFilters,
                            reviewMode
                        )
                    }
                    pullDownToRefreshThreshold={500}
                    // hasMore={currentPageRef.current * ITEMS_PER_PAGE < nfts.length}
                    hasMore={
                        resultCount == null
                            ? true
                            : currentPageRef.current * 100 < resultCount
                    }
                    // scrollThreshold="200px"
                    // scrollableTarget="content-container"
                    // initialScrollY={1000}
                    loader={
                        <div style={{ textAlign: 'center', marginTop: '30px' }}>
                            <SpinnerCircular
                                size={100}
                                color="#fe2c85"
                            />
                        </div>
                    }
                >
                    <div className={`row small-gutters px-0 mx-0`}>

                        {renderCards()}
                    </div>
                </InfiniteScroll>
            </div>
        </>
    );
};

export default Explorer;
