import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography, Grid, Button} from '@mui/material';
import leftTrick from './assets/images/leftTrick.svg';
import rightTrick from './assets/images/rightTrick.svg';
import leftTopTrick from './assets/images/leftTopTrick.svg';
import rightTopTrick from './assets/images/rightTopTrick.svg';
import Header from './components/Header';
import Footer from './components/Footer';
import './NotFound.css'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useContext } from 'react';
import { ModeCtx } from './context/modeCtx';


const sx = {
  root: {
    position: 'relative',
    width: '100%',
    pt: '150px',
    pb: '115px',
    mb: '100px',
    overflow: 'hidden',
    transition: 'all .3s',
    backgroundColor: '#FAF9F5',
    px: '20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  connectBtn:{
    borderRadius: '5px',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    backgroundColor: 'unset',
    border: '2px solid #fe2c85',
    color: '#fe2c85',
    marginTop: '20px',
    marginLeft: '5px',
    marginBottom:"20px",
    '&:hover': {
      border: '2px solid #fe2c85',
      backgroundColor: '#fe2c85',
      color: '#ffffff',
  }}
}

const TopSectionDividers = () => (
  
  <Box
    style={{
      display: 'flex',
      justifyContent: 'space-between',
    }}
  >
    <img src={leftTopTrick}  className='section-divider-img' />
    <img src={rightTopTrick} className='section-divider-img'/>
  </Box>
);
const BottomSectionDividers = () => (
  <Box
    style={{
      display: 'flex',
      transform: "rotateX(180deg)",
      justifyContent: 'space-between',
    }}
  >
    <img src={leftTopTrick} className='section-divider-img' />
    <img src={rightTopTrick} className='section-divider-img' />
  </Box>
);

const NotFound = () => {
  const history = useHistory();
  const modeCtx = useContext(ModeCtx)
  const navigateBack = () =>{
    history.push('/communities')
  }
  
   return <>
            
    <Header />
    <Box sx={sx.root} id="homeContainer" style={{backgroundColor: !modeCtx.lightTheme && '#050D15'}}> 
      <div className="borderupcoming" style={{marginLeft:"auto", marginRight:"auto"}}>
      <TopSectionDividers />
      
      <div className='not-found-container'>      
       <span style={{color: !modeCtx.lightTheme && '#FFF'}}>404</span>
       <p style={{textAlign:"center", fontFamily: 'poppins-semibold',fontSize:"50px", color:"#fe2c85"}}> Oops! </p>
       <Typography style={{ margin:"20px",}}>
       Sorry! The page you requested could not be found.
       </Typography>
       <Button
                variant="banner"
                sx={sx.connectBtn}
                onClick={navigateBack}
              >
                GET BACK TO HOME
          </Button>
      </div>
      <BottomSectionDividers />
      </div>   
    </Box>
     <Footer />
  </>
}

export default NotFound;