import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import InfoBox from '../../components/SalePage/components/InfoBox';
import eth from '../../assets/images/eth-black.svg';
import { ModeCtx } from '../../context/modeCtx';
import { useContext } from 'react';
const BP2 = '@media (max-width: 1345px)';
const BP3 = '@media (max-width: 384px)';

const sx = {
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    textAlign: 'center',
    paddingTop: '30px',
  },
  badgeContainer: {
    position: 'relative',
    margin: 'auto',
    // maxWidth: "300px",
    borderRadius: '12px',
    border: 'solid 2px #000',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: '0',
    px: '0',
  },
  badgeLeft: {
    borderRight: 'solid 2px #000',
    px: '25px',
    textTransform: 'uppercase',
    [BP3]: {
      borderRight: 'unset',
      borderBottom: 'solid 2px #000',
      width: '100%',
      paddingRight: '0',
    },
  },
  badgeCenter: {
    borderRight: 'solid 2px #000',
    px: '25px',
    textTransform: 'uppercase',
    [BP3]: {
      borderRight: 'unset',
      borderBottom: 'solid 2px #000',
      width: '100%',
      paddingRight: '0',
    },
  },
  badgeRight: {
    px: '25px',
    textTransform: 'uppercase',
    [BP3]: {
      width: '100%',
    },
  },
};

export const RenderOpenseaStats = ({ stats,collection }) => {
  const modeCtx= useContext(ModeCtx);
  return <Box sx={sx.badgeContainer} style={{border: !modeCtx.lightTheme && "2px solid #fff" }}>
   <InfoBox
      label="Minted"
      value={collection?.total_supply?(collection.total_supply >= 10000 ? collection.total_supply / 1000 + 'K' : collection.total_supply):'---'}
      sx={sx.badgeLeft}
      style={{borderRight: !modeCtx.lightTheme && "2px solid #fff" }}
    />
    <InfoBox label="Owners" value={stats?.total.num_owners?stats.total.num_owners:'---'} sx={sx.badgeCenter} style={{borderRight: !modeCtx.lightTheme && "2px solid #fff" }}/>
    <InfoBox
      label="Price"
      value={stats?.total.floor_price?stats.total.floor_price.toFixed(4):'---'}
      sx={sx.badgeRight}
      eth
    />
  </Box>
}