import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Grid, Button } from '@mui/material';
import eth from '../../../assets/images/eth-black.svg';
import { ModeCtx } from '../../../context/modeCtx';
import { useContext } from 'react';
const sx = {
  border: {
    width: '100%',
    maxWidth: '124px',
    backgroundColor: '#FFFFFF',
    padding: '10px 5px 10px 15px',
    borderRadius: '5px',
    boxShadow: '0px 3px 6px 0px rgb(0 0 0 / 10%)',
    borderRadius: '10px',
  },
  value: {
    color: '#1C1D22',
    fontSize: '18px',
    fontFamily: 'poppins',
    opacity: '1',
    lineHeight: '18px',
  },
  label: {
    color: '#707070',
    textTransform: 'uppercase',
    fontSize: '12px',
    lineHeight: '20px',
  },
  generalInfo: {
    mt: '10px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    maxWidth: '280px',
    gap: '15px',
  },
  buyButtonHolder: {
    width: '100%',
    maxWidth: '124px',
  },
  buyButton: {
    maxWidth: '124px',
    width: '100%',
    borderRadius: '5px',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    backgroundColor: 'unset',
    border: '2px solid #fe2c85',
    color: '#fe2c85',
    py: '15px',
    '&:hover': {
      py: '16px',
      border: '1px solid #fe2c85',
      backgroundColor: '#fe2c85',
      color: '#ffffff',
    },
  },
};

const DiagramInfo = ({ stats, openseaLink }) => {
  const modeCtx = useContext(ModeCtx);
  return <Box sx={sx.generalInfo} >
    <Box sx={sx.border} style={{background: !modeCtx.lightTheme ? "linear-gradient(rgb(20, 33, 46), rgb(14, 25, 36))" : "#fff"}}>
      <Typography sx={sx.value}>
        {stats.count > 1000 ? `${stats.count / 1000}K` : stats.count}
      </Typography>
      <Typography sx={sx.label}>MINTED</Typography>
    </Box>
    <Box sx={sx.border} style={{background: !modeCtx.lightTheme ? "linear-gradient(rgb(20, 33, 46), rgb(14, 25, 36))" : "#fff"}}>
      <Typography sx={sx.value}>{stats.num_owners}</Typography>
      <Typography sx={sx.label}>Nr. of Owners </Typography>
    </Box>
    <Box sx={sx.border} style={{background: !modeCtx.lightTheme ? "linear-gradient(rgb(20, 33, 46), rgb(14, 25, 36))" : "#fff"}}>
      <Box style={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
        <img src={eth} alt="" style={{ width: '8px' }} />{' '}
        <Typography sx={sx.value}>
          {stats.floor_price ? stats.floor_price.toFixed(2) : 0}
        </Typography>
      </Box>
      <Typography sx={sx.label}>Floor Price</Typography>
    </Box>
    <Box sx={sx.buyButtonHolder}>
      <Button
        variant="banner"
        sx={sx.buyButton}
        onClick={() => {
          window.open(
            `https://opensea.io/collection/${openseaLink}?search[sortAscending]=true&search[sortBy]=PRICE&search[toggles][0]=BUY_NOW`,
            '_blank'
          );
        }}
      >
        Buy Now
      </Button>
    </Box>
  </Box>
}

export default DiagramInfo;
