import { Typography, Box, Button, Grid } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

import { useHistory, useParams } from 'react-router-dom';
import { useZoom2Contract } from '../../hooks/useContract';
import axios from 'axios';
import Header from '../../components/Header';
import DiagramInfo from './components/DiagramInfo';
import TraitsCard from './components/AttributesCard';
import backButtonArrow from '../../assets/images/backButtonArrow.svg';
import Footer from '../../components/Footer';
import { useLocation } from 'react-router-dom';
import PreviousNextBox from './components/PreviousNextBox';
import config from '../../config/config';
import { toast } from 'react-toast';
import { GalaxisCard } from 'galaxis-components';
import { ModeCtx } from '../../context/modeCtx';
import { useContext } from 'react';
import { ethers } from 'ethers';
import { abi } from '../../components/SalePage/abi/Token.json';
import useWeb3Ctx from '../../hooks/useWeb3Ctx';
import { getTokenUrisForCardDetails } from '../../utils/zoom2';
import { fetchUris } from '../../utils';
import TraitBox from './components/TraitBox/TraitBox';

const BP1 = '@media (max-width: 871px)';
const BP2 = '@media (max-width: 470px)';
const BP3 = '@media (max-width: 520px)';
const BP4 = '@media (max-width: 900px)';
const BP5 = '@media (max-width: 600px)';

const ERROR_DOWNLOAD_STATS = 'ERROR_DOWNLOAD_STATS';

const sx = {
  root: {
    paddingTop: '100px',
    mb: '100px',
    px: '20px',
    width: '100%',
    pb: '115px',
  },
  content: {
    maxWidth: '1140px',
    width: '100%',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    gap: '30px',
  },
  generalContainer: {
    maxWidth: '848px',
    width: '100%',
    minHeight: '400px',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    flexWrap: 'wrap',
    marginBottom: '60px',
  },
  descriptionContainer: {
    maxWidth: '848px',
    width: '100%',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    gap: '30px',
  },
  inf: {
    display: 'flex',
    flexDirection: 'column',
    mb: '5px',
  },
  projectOwner: {
    mt: '10px',
    display: 'flex',
    flexDirection: 'row',
    gap: '20px',
    flexWrap: 'wrap',
  },
  imageContainer: {
    width: '100%',
    height: '100%',
    [BP5]: {
      margin: 'auto',
      minHeight: '300px',
      width: '300px',
    },
  },
  generalInfo: {
    [BP1]: {
      width: '100%',
    },
  },
  image: {
    margin: 'auto',
    maxWidth: '360px',
  },
  cornerTrickholder: {
    margin: 'auto',
    maxWidth: '848px',
    width: '100%',
  },
  cornerTrick: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  backButton: {},
  // titleText: {
  //     color: '#000000',
  // },
  collectionName: {
    fontSize: '18px',
    fontFamily: 'poppins-medium',
  },
  nameText: {
    fontSize: '32px',
    lineHeight: '35px',
  },
  detailsTitle: {
    fontSize: '20px',
  },
  buyingCard: {
    position: 'relative',
    margin: 'auto',
    maxWidth: '848px',
    minHeight: '86px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '22px',
    alignItems: 'center',
    pr: '14px',
    // backgroundColor: '#FFF',
    // backgroundColor: 'hsla(0,0%,100%,.88)',
    webkitBoxShadow: '0px 3px 6px 0px rgba(0,0,0,0.1)',
    mozBoxShadow: '0px 3px 6px 0px rgba(0,0,0,0.1)',
    boxShadow: '0px 3px 6px 0px rgba(0,0,0,0.1)',
    borderRadius: '10px',
    overflow: 'hidden',
    [BP2]: {
      justifyContent: 'center',
    },
  },
  buyingImage: {
    height: '86px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    objectFit: 'contain',
    backgroundPosition: '50%',
    mr: '16px',
    [BP3]: {
      display: 'none',
    },
  },
  buyingDetails: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [BP3]: {
      display: 'none',
    },
  },
  buyingContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    [BP3]: {
      justifyContent: 'center',
      flexWrap: 'wrap',
    },
  },
  buyButton: {
    width: '105px',
    height: '35px',
    borderRadius: '5px',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    backgroundColor: 'unset',
    border: '2px solid #fe2c85',
    color: '#fe2c85',
    '&:hover': {
      border: '1px solid #fe2c85',
      backgroundColor: '#fe2c85',
      color: '#ffffff',
    },
    [BP3]: {
      width: '100%',
      px: '14px',
    },
  },
  buyButtonHolder: {
    [BP3]: {
      width: '100%',
      pl: '14px',
    },
  },
  buyTitle: {
    [BP2]: {
      textAlign: 'center',
    },
  },
  buyNameText: {
    fontSize: '24px',
    fontFamily: 'poppins-semibold',
    lineHeight: '35px',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '640px',
    wordBreak: 'unset',
    [BP2]: {
      textAlign: 'center',
    },
  },
  backButtonHolder: {
    display: 'flex',
    mb: '20px',
    mt: '30px',
    cursor: 'pointer',
  },
  utilityImageHolder: {
    width: '31px',
    height: '31px',
    border: '1px solid #030000',
    borderRadius: '7px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  rightGrid: {
    height: '96%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [BP5]: {
      height: 'auto',
    },
  },
  utilityTraitHolder: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    mb: '5px',
  },
  error: {
    paddingTop: '200px',
    minHeight: '650px',
    backgroundColor: '#FAF9F5',
    textAlign: 'center',
    fontSize: '30px',
  },
};

const CardDetails = () => {
  const { project } = useParams();
  const [projectStats, setProjectStats] = useState(null);
  const [prevProject, setPrevProject] = useState(null);
  const [nextProject, setNextProject] = useState(null);
  const [buyCard, setBuyCard] = useState(true);
  const history = useHistory();
  const location = useLocation();
  const [tokenUri, setTokenUri] = useState([]);
  const [projectDetails, setProjectDetails] = useState(null);
  const [projectCard, setProjectCard] = useState({});
  const [image, setImage] = useState('');
  const [ipfImage, setIpfImage] = useState(false);
  const [error, setError] = useState(false);
  const [errormsg, setErrormsg] = useState('');
  const path = location.pathname;
  const cardId = path.substring(path.lastIndexOf('/') + 1);
  const routeArray = path.split('/');
  const projectId = routeArray[routeArray.length - 2];
  const previewCardId = routeArray[routeArray.length - 1];
  const projectUrl = routeArray[1];
  const prevCardId = cardId - 1;
  const nextCardId = parseInt(cardId) + 1;
  const [traitTypes, setTraitTypes] = useState(null);
  const imageContainer = useRef();
  const [imageContainerWidth, setImageContainerWidth] = useState(null);
  const modeCtx = useContext(ModeCtx);
  const zoomContract = useZoom2Contract();
  const { getProvider } = useWeb3Ctx();
  const [ownerOfToken, setOwnerOfToken] = useState(undefined);

  useEffect(async () => {
    if (projectUrl == 'projects') {
      if (projectDetails?.smart_contract_address) {
        let provider = getProvider(projectDetails.chain_id);
        const contract = new ethers.Contract(
          projectDetails.smart_contract_address,
          abi,
          provider
        );
        await getTokenUrisForCardDetails(contract, zoomContract, {
          currentCardId: parseInt(cardId),
          prevCardId: parseInt(cardId) - 1,
          nextCardId: parseInt(cardId) + 1,
        })
          .then(async (response) => {
            //console.log('RESPONSE',response,response.length);

            if (response.length >= 1) {
              // response.map((item)=> {
              //   getTokenUri(Object.keys(item)[0],Object.values(item)[0]);
              // })
              const metadataArray = await fetchUris(response);

              // console.log('metadataArray',metadataArray)
              if (metadataArray.length === 1) {
                setProjectCard(metadataArray[0]);
              } else if (
                metadataArray.length === 2 &&
                parseInt(cardId) + 1 !==
                  parseInt(metadataArray[metadataArray.length - 1].tokenId)
              ) {
                setPrevProject(metadataArray[0]);
                setProjectCard(metadataArray[1]);
                setNextProject(null);
              } else if (
                metadataArray.length === 2 &&
                parseInt(cardId) - 1 !== parseInt(metadataArray[0].tokenId)
              ) {
                setProjectCard(metadataArray[0]);
                setNextProject(metadataArray[1]);
                setPrevProject(null);
              } else {
                setPrevProject(metadataArray[0]);
                setProjectCard(metadataArray[1]);
                setNextProject(metadataArray[2]);
              }
            } else {
              toast.error('Token does not exist!');
              goBack();
            }
            // console.log(response, ' response');
          })
          .then(async () => {
            const ownerOfToken = await contract.ownerOf(Number(cardId));
            setOwnerOfToken(ownerOfToken);
          })
          .catch((e) => console.log(e, ' error'));
      }
    }
  }, [cardId, projectDetails?.smart_contract_address]);

  useEffect(() => {
    if (projectUrl == 'projects') {
      axios
        .get(`${config.API_URL}/projects/${project}`)
        .then((res) => {
          console.log('RES', res);
          if (res.data.message === config.NO_PROJECT_FOUND) {
            toast.error('Card doesn`t exist');
            history.push('/');
          }
          setProjectDetails(Object.values(res.data)[0]);
          // setLongDescription(res.data.long_description);
        })
        .catch((error) => {
          console.log('error', error);
        });
    }

    if (projectUrl == 'preview') {
      axios
        .get(`${config.PREVIEW_URL}/${projectId}/nfts/${previewCardId}/details`)
        .then((res) => {
          res.data.image = res.data.image.replace('/thumb/', '/original/');
          for (let i = 0; i < res.data.sides.length; i++) {
            let tmpImg = res.data.sides[i].image.replace(
              '/thumb/',
              '/original/'
            );
            console.log('replaced', tmpImg);
            res.data.sides[i].image = tmpImg;
            //res.data.sides[i].image = es.data.sides[i].image.replace('/thumb/','/original/');
          }

          setProjectCard(res.data);
          console.log('PREVIEW', res.data);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            console.log(error);
            setErrormsg('Invalid session!');
            setError(true);
          } else {
            console.log(error);
            setErrormsg('Card is not found!');
            setError(true);
          }
        });
      axios
        .get(`${config.PREVIEW_URL}/${projectId}/nfts/${prevCardId}/details`)
        .then((res) => {
          setPrevProject(res.data);
        });
      axios
        .get(`${config.PREVIEW_URL}/${projectId}/nfts/${nextCardId}/details`)
        .then((res) => {
          setNextProject(res.data);
        });
    }
  }, [cardId]);

  useEffect(() => {
    console.log('route array');
  }, [routeArray]);

  useEffect(() => {
    document.getElementsByClassName('longDescriptions')[0].innerHTML =
      projectCard.about;
    if (!error) {
      if (!projectCard.about || projectCard.about == '') {
        document.getElementsByClassName('longDescriptions')[0].innerHTML =
          projectDetails?.long_description;
      } else if (!projectCard.description || projectCard.description === '') {
        document.getElementsByClassName('shortDescription')[0].innerHTML =
          projectDetails?.long_description;
      }
    }
  }, [projectCard]);
  useEffect(() => {
    window.scroll(0, 0);
    axios.get(`${config.API_URL}/trait_types`).then((res) => {
      setTraitTypes(res.data);
    });
  }, []);

  const goBack = () => {
    localStorage.setItem('clickedBack', true);
    let path = location.pathname;
    var rest = path.substring(0, path.lastIndexOf('/'));
    history.push(rest);
  };

  useEffect(() => {
    setImageContainerWidth(imageContainer.current.clientWidth);
  }, []);
  // console.log(projectDetails)
  return (
    <>
      <Header />
      {error ? (
        <Typography sx={sx.error}>{errormsg}</Typography>
      ) : (
        <Box sx={sx.root}>
          <Box sx={sx.cornerTrickholder}>
            <Box
              sx={sx.backButtonHolder}
              onClick={() => {
                goBack();
              }}
            >
              <img
                src={backButtonArrow}
                alt=""
                style={{ marginRight: '5px' }}
              />
              <Typography
                fontSize={14}
                variant="heading"
                style={{ color: !modeCtx.lightTheme && '#FFF' }}
              >
                Back
              </Typography>
            </Box>
          </Box>
          <Box sx={sx.content}>
            <div className="bordertrait" style={{ position: 'relative' }}>
              <Box sx={sx.generalContainer}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box sx={sx.imageContainer} ref={imageContainer}>
                      {projectCard.collection_type == 'galaxis' &&
                      traitTypes ? (
                        <GalaxisCard
                          metadata={projectCard}
                          traitTypes={traitTypes}
                          horizontalPadding={68}
                          imageContainerWidth={imageContainerWidth}
                          apiUrl={config.API_URL}
                        />
                      ) : projectCard.animation_url &&
                        projectCard.animation_url !== projectCard.image ? (
                        <iframe
                          src={projectCard.animation_url}
                          frameBorder={0}
                          scrolling="no"
                          style={{
                            zIndex: '0',
                            width: '100%',
                            height: '100%',
                            minHeight: '408px',
                            borderRadius: '15px',
                            backgroundColor: 'white',
                            border: '0.5px solid #707070',
                          }}
                        />
                      ) : (
                        <img
                          src={projectCard.image}
                          alt="Card"
                          style={{
                            zIndex: '0',
                            width: '100%',
                            height: 'auto',
                            borderRadius: '15px',
                            backgroundColor: 'white',
                            border: '0.5px solid #707070',
                          }}
                        />
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} sx={sx.rightGrid}>
                    <Box sx={sx.generalInfo}>
                      <Grid container>
                        <Grid item xs={12} sm={12}>
                          <Box sx={sx.inf}>
                            <Typography
                              variant="minuscule"
                              sx={sx.titleText}
                              style={{ color: !modeCtx.lightTheme && '#fff9' }}
                            >
                              COLLECTION
                            </Typography>
                            <Typography
                              variant="heading"
                              sx={sx.collectionName}
                              style={{ color: !modeCtx.lightTheme && '#fff' }}
                            >
                              {projectUrl == 'preview'
                                ? projectCard.collectionName
                                : projectDetails?.name}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <Box sx={sx.projectOwner}>
                            <Box sx={sx.inf}>
                              <Typography
                                variant="minuscule"
                                style={{
                                  color: !modeCtx.lightTheme && '#fff9',
                                }}
                                sx={sx.titleText}
                              >
                                NAME
                              </Typography>
                              <Typography
                                variant="projectSubtitle"
                                sx={sx.nameText}
                                style={{ color: !modeCtx.lightTheme && '#fff' }}
                              >
                                {projectCard.name}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <Box sx={sx.projectOwner}>
                            <Box sx={sx.inf}>
                              <Typography
                                variant="minuscule"
                                style={{
                                  color: !modeCtx.lightTheme && '#fff9',
                                }}
                                sx={sx.titleText}
                              >
                                TOKEN ID
                              </Typography>
                              <Typography
                                variant="projectSubtitle"
                                sx={sx.nameText}
                                style={{ color: !modeCtx.lightTheme && '#fff' }}
                              >
                                #{cardId}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        {projectDetails &&
                        projectDetails.opensea_stats !== null &&
                        projectDetails.opensea_stats !== undefined ? (
                          <Grid item xs={12} sm={12}>
                            <Box
                              sx={sx.inf}
                              style={{
                                marginTop: '10px',
                              }}
                            >
                              <Typography
                                variant='minuscule'
                                style={{
                                  color: !modeCtx.lightTheme && '#fff9',
                                }}
                                sx={sx.titleText}
                              >
                                COLLECTION INFO
                              </Typography>
                              <DiagramInfo
                                stats={projectDetails.opensea_stats}
                                openseaLink={
                                  projectDetails.project_name_from_opensea
                                }
                              />
                            </Box>
                          </Grid>
                        ) : (
                          ''
                        )}
                      </Grid>
                    </Box>
                    <Box>
                      {projectCard.traits &&
                        // projectUrl === 'preview' &&
                        projectCard.traits.length > 0 && (
                          <TraitBox
                            traits={projectCard.traits}
                            trait_types={traitTypes}
                            ownerOfToken={ownerOfToken}
                          />
                        )}
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <PreviousNextBox
                nextId={nextCardId}
                prevId={prevCardId}
                nextProject={nextProject}
                previousProject={prevProject}
                url={`/projects/${project}`}
              />
              <Box sx={sx.descriptionContainer}>
                {(!projectCard.description && !projectCard.about) ||
                (!projectCard.about && projectCard.description === '') ||
                (!projectCard.description && projectCard.about === '') ||
                (projectCard.description === '' && projectCard.about === '') ? (
                  <Box>
                    <Typography
                      variant="heading"
                      sx={sx.detailsTitle}
                      style={{ color: !modeCtx.lightTheme && '#fff9' }}
                    >
                      Description
                    </Typography>
                    {/* {!projectCard.about || projectCard.about === ''
                    ? (document.getElementsByClassName(
                        'longDescriptions'
                      )[0].innerHTML = projectDetails.long_description)
                    : ''} */}
                    <Typography
                      variant="bannerText"
                      fontSize={14}
                      marginTop={'10px'}
                      className="longDescriptions"
                    ></Typography>
                  </Box>
                ) : (
                  <>
                    <Box>
                      <Typography
                        variant="heading"
                        sx={sx.detailsTitle}
                        style={{ color: !modeCtx.lightTheme && '#fff9' }}
                      >
                        Description
                      </Typography>
                      <Typography
                        variant="bannerText"
                        fontSize={14}
                        marginTop={'10px'}
                        className="shortDescription"
                        style={{ color: !modeCtx.lightTheme && '#fff9' }}
                      >
                        {projectCard.description}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        variant="heading"
                        sx={sx.detailsTitle}
                        style={{ color: !modeCtx.lightTheme && '#fff9' }}
                      >
                        About
                      </Typography>
                      <Typography
                        variant="bannerText"
                        fontSize={14}
                        marginTop={'10px'}
                        className="longDescriptions"
                        style={{ color: !modeCtx.lightTheme && '#fff9' }}
                      >
                        {projectCard.about}
                      </Typography>
                    </Box>
                  </>
                )}

                <Box>
                  {projectCard.attributes &&
                    projectCard.attributes.length > 0 && (
                      <>
                        <Typography
                          variant="heading"
                          sx={sx.detailsTitle}
                          marginBottom={'10px'}
                          style={{ color: !modeCtx.lightTheme && '#fff9' }}
                        >
                          Properties
                        </Typography>
                        <Box marginTop={'10px'}>
                          <Grid container spacing={1}>
                            {projectCard.attributes.map((attributes) => (
                              <Grid item xs={6} sm={4} md={3}>
                                <TraitsCard
                                  key={attributes}
                                  trait={attributes}
                                />
                              </Grid>
                            ))}
                          </Grid>
                        </Box>
                      </>
                    )}
                </Box>
                {/* <Box
                style={{
                  position: 'fixed',
                  bottom: buyCard ? '143px' : '220px',
                  zIndex: '111111111',
                  width: '100%',
                  paddingRight: '39px',
                }}
              >
                <Box
                  style={{
                    position: 'relative',
                    width: '100%',
                  }}
                > */}
                {tokenUri.image && projectDetails?.status !== 'live' && (
                  <Box
                    sx={sx.buyingCard}
                    style={{
                      background:
                        !modeCtx.lightTheme &&
                        'linear-gradient(rgb(20, 33, 46), rgb(14, 25, 36))',
                    }}
                  >
                    <Box sx={sx.buyingContent}>
                      <Box sx={sx.buyingImage}>
                        {' '}
                        <img src={tokenUri.image} alt="" height={'100%'} />
                      </Box>
                      <Box sx={sx.buyingDetails}>
                        <Typography
                          variant="minuscule"
                          sx={sx.buyTitle}
                          style={{ color: !modeCtx.lightTheme && '#fff9' }}
                        >
                          COLLECTION
                        </Typography>
                        <Typography
                          variant="projectSubtitle"
                          sx={sx.buyNameText}
                          style={{ color: !modeCtx.lightTheme && '#fff9' }}
                        >
                          {projectDetails?.name}
                        </Typography>
                      </Box>
                    </Box>
                    <Box sx={sx.buyButtonHolder}>
                      <Button
                        variant="banner"
                        sx={sx.buyButton}
                        onClick={() => {
                          window.open(
                            `https://opensea.io/collection/${projectDetails.project_name_from_opensea}?search[sortAscending]=true&search[sortBy]=PRICE&search[toggles][0]=BUY_NOW`,
                            '_blank'
                          );
                        }}
                      >
                        Buy Now
                      </Button>
                    </Box>
                  </Box>
                )}
                {/* </Box>
              </Box> */}
              </Box>
            </div>
          </Box>
        </Box>
      )}
      <Footer />
    </>
  );
};

export default CardDetails;
