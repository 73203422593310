import { Box, Button, ButtonGroup, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { Contract } from '@ethersproject/contracts';
import { DateTime } from 'luxon';
import config from '../../config/config';
import { BigNumber, ethers } from 'ethers';
import { toast } from 'react-toast';

import useInterval from '../../hooks/useInterval';

import galaxis from '../../assets/images/galaxis.svg';
import InfoBox from './components/InfoBox';
import leftTrick from '../../assets/images/leftTrick.svg';
import rightTrick from '../../assets/images/rightTrick.svg';
import Counter from './components/Counter/Counter';
import Timer from './components/Timer';
import useWeb3Ctx from '../../hooks/useWeb3Ctx';

import saleABI from './abi/SaleV3.json';
import tokenABI from './abi/TokenV3.json';
import erc1820RegistryABI from './abi/ERC1820Registry.json';
import erc677TokenABI from './abi/ERC677Token.json';
import erc777TokenABI from './abi/ERC777Token.json';
import CheckoutModal from './components/CheckoutModal';
import BackdropModal from './components/BackdropModal';
import TxProgressModal from './components/TxProgressModal';
import PermissionErrorModal from './components/PermissionErrorModal';
import './Sales.css';
//import sigs from "./signatures/sigs"; // will be downloaded in the future
import { width } from '@mui/system';
import Wallet from './components/Wallet';
import SectionDividers from './components/SectionDividers';
import axios from 'axios';
// import CarouselCounter from './CarouselCaounter';
import { ModeCtx } from '../../context/modeCtx';
import { useContext } from 'react';
import {
  ConnectingAirportsOutlined,
  ConstructionOutlined,
} from '@mui/icons-material';
import { ERC1820_REGISTRY_ADDRESS } from './abi/constants/addresses';
import PopupModal from './components/PopupModal';
const BP2 = '@media (max-width: 1345px)';
const BP4 = '@media (max-width: 600px)';
const BP5 = '@media (max-width: 899px)';
const BP6 = '@media (max-width: 700px)';
const BP3 = '@media (max-width: 384px)';
const BP7 = '@media (max-width: 820px)';
const BP1 = '@media (max-width: 384px)';

const sx = {
  saleRoot: {
    mt: '30px',
    mb: '60px'
  },
  container: {
    margin: 'auto',
    mt: '-5px',
    position: 'relative',
    pt: '50px',
    pb: '100px',
    maxWidth: '1233px',
    overflow: 'hidden',
    transition: 'all .3s',
    backgroundColor: '#FFF',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    px: '20px',
    [BP4]: {
      pt: '39px',
    },
  },
  nameContainer: {
    marginTop: '50px',
    position: 'absolute',
    mx: 'auto',
    display: 'flex',
    maxWidth: '1140px',
    width: '100%',
    zIndex: '1',
    backgroundColor: '#511BA3',
    [BP4]: {
      marginTop: '35px',
    },
  },
  name: {
    position: 'absolute',
    height: '135px',
    width: '135px',
    backgroundColor: '#511BA3',
    borderRadius: '15px',
    bottom: '4px',
    left: '0',
    right: '0',
    marginRight: 'auto',
    marginLeft: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    textAlign: 'center',
    //   paddingTop: "30px"
  },
  badgeContainer: {
    position: 'relative',
    margin: 'auto',
    // maxWidth: "300px",
    borderRadius: '12px',
    border: 'solid 2px #000',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: '12px',
    px: '20px',
  },
  badgeLeft: {
    borderRight: 'solid 2px #000',
    paddingRight: '15px',
    [BP1]: {
      borderRight: 'unset',
      borderBottom: 'solid 2px #000',
      width: '100%',
      paddingRight: '0',
    },
  },
  badgeCenter: {
    borderRight: 'solid 2px #000',
    paddingRight: '15px',
    [BP1]: {
      borderRight: 'unset',
      borderBottom: 'solid 2px #000',
      width: '100%',
      paddingRight: '0',
    },
  },

  badgeCenterSaleOver: {
    borderRight: 'unset',
    paddingRight: '0px',
    [BP1]: {
      borderRight: 'unset',
      borderBottom: 'unset',
      width: '100%',
      paddingRight: '0',
    },
  },

  badgeRight: {
    [BP1]: {
      width: '100%',
    },
  },
  timeContainer: {
    padding: '4px',
    margin: 'auto',
    mt: '20px',
    maxWidth: '512px',
    borderRadius: '12px',
    webkitboxShadow: '0px 4px 20px -3px rgba(0,0,0,0.1)',
    boxShadow: '0px 4px 20px -3px rgba(0,0,0,0.1)',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: '12px',
    [BP4]: {
      padding: '10px',
    },
  },
  cardContainer: {
    mx: 'auto',
    mt: '50px',
    mb: '20px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap',
    gap: '40px 16px',
  },
  title: {
    fontFamily: 'poppins-semibold',
    fontSize: '36px',
    lineHeight: '38px',
  },
  socialContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: '17px',
    mt: '20px',
    mb: '30px',
  },
  description: {
    fontFamily: 'bau',
    color: '#030000',
    fontSize: '16px',
    lineHeight: '24px',
    maxWidth: '606px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    margin: 'auto',
    mt: '25px',
  },
  nftContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  largeBanner: {
    display: 'block',
    [BP7]: {
      display: 'none',
    },
  },
  smallBanner: {
    display: 'none',
    [BP7]: {
      display: 'block',
    },
  },
  button: {
    backgroundColor: '#fe2c85',
    color: '#FAFAFA',
    fontSize: '14px',
    fontFamily: 'poppins-semibold',
    padding: '14px',
    borderRadius: '8px',
    '&:hover': {
      backgroundColor: '#fe2c85',
    },
  },
  buttonOutlined: {
    backgroundColor: 'transparent',
    color: '#fe2c85',
    fontSize: '14px',
    fontFamily: 'poppins-semibold',
    padding: '14px',
    borderRadius: '8px',
    '&:hover': {
      backgroundColor: '#fe2c85',
      color: 'white',
    },
  },

  tabContainer: {
    justifyContent: 'center',
    paddingTop: '30px',
  },
  tabBtns: {
    color: '#000',
    borderColor: '#000',
    borderWidth: '2px',
    backgroundColor: '#fff',
    fontFamily: 'poppins-semibold',
    padding: '14px',
    '&:hover': {
      borderColor: '#000',
      backgroundColor: '#000',
      borderWidth: '2px',
      color: '#fff',
      borderRightColor: '#000 !important',
    },
    borderRadius: '10px',
  },
  tabBtnsDark: {
    color: '#fff',
    borderColor: '#fff',
    borderWidth: '2px',
    backgroundColor: '#000',
    fontFamily: 'poppins-semibold',
    padding: '14px',
    '&:hover': {
      borderColor: '#fff',
      backgroundColor: '#fff',
      borderWidth: '2px',
      color: '#000',
      borderRightColor: '#fff !important',
    },
    borderRadius: '10px',
  },
  tabSelected: {
    backgroundColor: '#000',
    color: '#fff',
  },
  tabSelectedDark: {
    backgroundColor: '#fff',
    color: '#000',
  },

  buyButton: {
    height: '35px',
    borderRadius: '5px',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    backgroundColor: 'unset',
    border: '2px solid #fe2c85',
    color: '#fe2c85',
    margin: '10px 30px 10px 0',
    '&:hover': {
      border: '2px solid #fe2c85',
      backgroundColor: '#fe2c85',
      color: '#ffffff',
    },
    '&:disabled': {
      border: '2px solid rgba(0, 0, 0, 0.26);',
    },
    [BP3]: {
      width: '100%',
      px: '14px',
    },
  },

  buyButtonDark: {
    height: '35px',
    borderRadius: '5px',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    backgroundColor: 'unset',
    border: '2px solid #fe2c85',
    color: '#fe2c85',
    margin: '30px 10px 0px 10px',
    '&:hover': {
      border: '2px solid #fe2c85',
      backgroundColor: '#fe2c85',
      color: '#ffffff',
    },
    '&:disabled': {
      border: '2px solid rgba(255, 255, 255, 0.26);',
      color: '#ffffff30',
    },
    [BP3]: {
      width: '100%',
      px: '14px',
      margin: '0',
      mt: '20px',
    },
  },
  url: {
    textDecoration: 'none',
    color: '#fe2c85',
    fontWeight: '700',
  },
  description: {
    fontFamily: 'bau',
    color: '#1C1E23',
    fontSize: '16px',
    lineHeight: '28px',
    maxWidth: '856px',
    width: '100%',
    margin: 'auto',
    mt: '27px',
    mb: '40px',
  },
};

export const SALE_STATUS = {
  UPCOMING_PRESALE: 0,
  PRESALE: 1,
  PRESALE_ENDED: 2,
  SALE: 3,
  SALE_ENDED: 4,
};

const SalesV3 = ({
  communityId,
  tokenAddress,
  saleAddress,
  symbol,
  whitelist,
  labels,
  saleChain,
  openSeaName,
  zoomContract,
}) => {
  const BUY_TYPE_APSALE = 1;
  const BUY_TYPE_SALE = 2;

  const {
    onboard,
    address,
    handleConnect,
    ethersProvider,
    defaultProvider,
    getProvider,
    chainId,
    setChain,
  } = useWeb3Ctx();

  const [tab, setTab] = useState(0);

  const [minted, setMinted] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);

  const [freeWhitesaleMint, setFreeWhitesaleMint] = useState(false);
  const [whiteListSaleEthPrice, setWhiteListSaleEthPrice] = useState(
    BigNumber.from(0)
  );
  const [whiteListSaleTokenPrice, setWhiteListSaleTokenPrice] = useState(
    BigNumber.from(0)
  );

  const [publicSaleEthPrice, setPublicSaleEthPrice] = useState(
    BigNumber.from(0)
  );
  const [publicSaleTokenPrice, setPublicSaleTokenPrice] = useState(
    BigNumber.from(0)
  );

  //sale states needed a different approach, because the sales can overlap each other :/

  const [preSaleStarted, setPreSaleStarted] = useState(false);
  const [preSaleFinished, setPreSaleFinished] = useState(false);

  const [mainSaleStarted, setMainSaleStarted] = useState(false);
  const [mainSaleFinished, setMainSaleFinished] = useState(false);

  const [presaleStartTime, setPresaleStartTime] = useState(null);
  const [presaleEndTime, setPresaleEndTime] = useState(null);

  const [presaleStartTimeForUser, setPresaleStartTimeForUser] = useState(null);
  const [presaleEndTimeForUser, setPresaleEndTimeForUser] = useState(null);

  const [saleStartTime, setSaleStartTime] = useState(null);
  const [saleEndTime, setSaleEndTime] = useState(null);

  const [presaleTimeCounter, setPresaleTimeCounter] = useState(null);
  const [saleTimeCounter, setSaleTimeCounter] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const [tokenContract, setTokenContract] = useState(null);
  const [saleContract, setSaleContract] = useState(null);

  const [txEtherScan, setTxEtherScan] = useState('');
  const [showCheckout, setShowCheckout] = useState(false);
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [txInProgress, setTxInProgress] = useState(false);
  const [approveInProgress, setApproveInProgress] = useState(false);
  const [checkoutIsPresale, setCheckoutIsPresale] = useState(true);
  const [isCreditCard, setIsCreditCard] = useState(false);

  const [maxTokenPerAddress, setMaxTokenPerAddress] = useState(10);
  const [maxDiscountMintable, setMaxDiscountMintable] = useState(10);

  const [maxMintableDuringMainSale, setMaxMintableDuringMainSale] =
    useState(10);
  const [userMaxDiscountMintable, setUserMaxDiscountMintable] = useState(0);
  const [availableTokens, setAvailableTokens] = useState(null);

  const [refreshInterval, setRefreshInterval] = useState(null);

  const [signatures, _setSignatures] = useState(null);
  const sigRef = useRef(signatures);
  const setSignatures = (val) => {
    sigRef.current = val;
    _setSignatures(val);
  };

  const [saleLabels, setSaleLabels] = useState({
    preSaleTitle: 'Pre-Sale',
    preSaleDescription: '',
    publicSaleTitle: 'Public Sale',
    publicSaleDescription: '',
  });

  const [maxMintPerTransaction, setMaxMintPerTransaction] = useState(10);
  const [maxSalePerAddress, setMaxSalePerAddress] = useState(0);

  const [tokenSaleActive, setTokenSaleActive] = useState(false);

  const [ethSaleActive, setEthSaleActive] = useState(false);
  const [approvedSaleIsActive, setApprovedSaleIsActive] = useState(false);

  const [userTokenAddress, setUserTokenAddress] = useState(null);
  const [userToken, setUserToken] = useState(null);
  const [ERC677Symbol, setERC677Symbol] = useState(null);
  const [tokenIsERC777, setTokenIsERC777] = useState(false);

  const [buyWithToken, setBuyWithToken] = useState(false);

  const [userOnList, setUserOnList] = useState(false);

  const [userData, setUserData] = useState(null);

  const [salesActive, setSalesActive] = useState(false);

  const [connectedUserCanMint, setConnectedUserCanMint] = useState(false);

  const [connectedUserMintPeriodOver, setConnectedUserMintPeriodOver] =
    useState(false);
  const [connectedUserMintPeriodStarted, setConnectedUserMintPeriodStarted] =
    useState(false);

  const [userDateReason, setUserDateReason] = useState('');
  const [showUserDateError, setShowUserDateError] = useState(false);

  const AbiCoder = new ethers.utils.AbiCoder();

  const modeCtx = useContext(ModeCtx);
  useEffect(() => {
    if (whitelist) {
      axios
        .get(config.AWS_URL + whitelist + '?noCache=' + new Date().getTime())
        .then((res) => {
          // console.log(res.status);

          if (res && res.status === 200) {
            setSignatures(res.data);
          } else {
            toast.error(res.message);
          }
        })
        .catch((e) => console.log);
    } else {
      setSignatures(null);
    }
    setSaleLabels(labels);
  }, []);

  useEffect(() => {
    if (address && chainId && saleChain) {
      console.log(chainId === saleChain ? 'chain OK' : 'WRONG CHAIN');
      if (chainId !== saleChain) {
        setChain(saleChain);
      }
    }
  }, [address, chainId, saleChain]);

  useEffect(() => {
    (async () => {
      if (sessionStorage.getItem('selectedWallet')) {
        let t = Number(localStorage.getItem('activeTab'));
        if (t === 1) {
          setTab(1);
        } else {
          setTab(0);
        }
      }
    })();
  }, [onboard]);

  useInterval(
    () => {
      if (mainSaleFinished && preSaleFinished) {
        setRefreshInterval(null);
      } else {
        console.log('get sale interval started');
        getSaleInfo();
      }
    },
    refreshInterval,
    false
  );

  useEffect(() => {
    if (address && signatures) {
      setUserData(null);
      let userData = getUserParams();

      if (userData) {
        // console.log('userData', userData);
        setUserData(userData);
        setUserOnList(true);
        setFreeWhitesaleMint(userData.params.free_mint);

        setWhiteListSaleEthPrice(
          ethers.BigNumber.from(userData.params.eth_price)
        );
        setWhiteListSaleTokenPrice(
          ethers.BigNumber.from(userData.params.token_price)
        );

        let now = Date.parse(new Date()) / 1000;
        let canMint =
          now > userData.params.valid_from && now < userData.params.valid_to;
        //console.log('USER CAN MINT:',canMint);

        setPresaleStartTimeForUser(new Date(userData.params.valid_from * 1000));
        setPresaleEndTimeForUser(new Date(userData.params.valid_to * 1000));
        setConnectedUserCanMint(canMint);
        setConnectedUserMintPeriodOver(now > userData.params.valid_to);
        setConnectedUserMintPeriodStarted(now > userData.params.valid_from);
      } else {
        setWhiteListSaleEthPrice(publicSaleEthPrice);
        //
        setWhiteListSaleTokenPrice(publicSaleTokenPrice);
        setUserOnList(false);
        setPresaleStartTimeForUser(null);
        setPresaleEndTimeForUser(null);
        setConnectedUserCanMint(false);
        setConnectedUserMintPeriodOver(true);
        setConnectedUserMintPeriodStarted(false);
      }
    }
  }, [address, signatures]);

  useEffect(() => {
    const initContracts = async () => {
      let provider = getProvider(saleChain);
      if (
        (tokenContract == null || saleContract === null) &&
        provider !== null
      ) {
        //console.log('EP in contract init',provider);

        let token = new Contract(tokenAddress, tokenABI.abi, provider);
        if (!token) {
          console.error('Token contract not found on address', tokenAddress);
          return;
        }

        let sale = new Contract(saleAddress, saleABI.abi, provider);
        if (!sale) {
          console.error('Sale contract not found on address', saleAddress);
        }

        // console.log('CONTRACTS INITIATED', token, sale);
        setTokenContract(token);
        setSaleContract(sale);
      }
    };
    // console.log('chains',typeof saleChain,saleChain,chainId)
    if (saleAddress && tokenAddress) {
      //console.log('props changed',saleAddress,tokenAddress,ethersProvider)

      initContracts();
    }
  }, [saleAddress, tokenAddress, ethersProvider, chainId, saleChain]);

  useEffect(() => {
    const getInfo = async () => {
      let ms = await tokenContract.maxSupply().catch((e) => console.log);

      if (ms) {
        console.log('MAX SUPPPPPLY', Number(ms));
        setTotalAmount(Number(ms));
      }
      await getSaleInfo();
      //console.log('getting sale info end');
    };

    // console.log('getting sale info start',tokenContract,saleContract,signatures);

    if (
      tokenContract !== null &&
      saleContract !== null &&
      !refreshInterval /*  && signatures != null */
    ) {
      //console.log('+++++++++++++++++++++get sale info in effect', tokenContract,saleContract);
      getInfo();
      setRefreshInterval(20000);

      let t = Number(localStorage.getItem('activeTab'));
      if (t === 1) {
        setTab(1);
      } else {
        setTab(0);
      }
    }
  }, [tokenContract, saleContract, signatures]);

  useEffect(() => {
    const initUserToken = async () => {
      let provider =
        chainId && chainId === saleChain
          ? ethersProvider
          : getProvider(saleChain);

      let is777 = false;
      let erc1820 = new Contract(
        ERC1820_REGISTRY_ADDRESS,
        erc1820RegistryABI.abi,
        provider
      );

      //console.log('USETOKENADDRESS',userTokenAddress);
      if (erc1820) {
        const res = await erc1820
          .getInterfaceImplementer(
            userTokenAddress,
            '0xac7fbab5f54a3ca8194167523c6753bfeb96a445279294b6125b68cce2177054'
          )
          .catch((e) => console.log);
        // console.log(res);

        if (res !== ethers.constants.AddressZero) {
          is777 = true;
        }
      }

      setTokenIsERC777(is777);

      let token = new Contract(
        userTokenAddress,
        is777 ? erc777TokenABI.abi : erc677TokenABI.abi,
        provider
      );

      if (!token) {
        console.error('Token contract not found on address', userTokenAddress);
        return;
      } else {
        console.log(`the user's token is a ${is777 ? '777' : '677'}`);
        setUserToken(token);
        let symbol = await token.symbol().catch((e) => console.log);
        if (symbol) {
          setERC677Symbol(symbol);
        } else {
          setERC677Symbol('N/A');
        }

        console.log('SYMBOL', symbol);
      }
    };

    if (userTokenAddress !== null) {
      initUserToken();
    }
  }, [userTokenAddress]);

  const getUserParams = () => {
    let up = null;
    if (address && sigRef.current) {
      const key = Object.keys(sigRef.current).find((key) => {
        return key.toLowerCase() == address.toLowerCase();
      });

      if (key) {
        const userParams = sigRef.current[key].params;

        up = {
          params: {
            free_mint: userParams[2],
            max_mint: userParams[3],
            receiver: userParams[4],
            valid_from: userParams[5],
            valid_to: userParams[6],
            eth_price: userParams[7],
            token_price: userParams[8],
          },
          raw_params: userParams,
          signature: sigRef.current[key].signature,
        };
      }
    }
    return up;
  };

  const getSaleInfo = async () => {
    //console.log('saleInfo',saleContract,chainId,saleChain);
    setIsLoading(true);
    const info = await saleContract
      .tellEverything()
      .catch((e) => console.log('TE err:', e));

    if (!info) {
      console.log('no info');
      return;
    }
    console.log('****info', info);

    const totalSupply = await tokenContract
      .totalSupply()
      .catch((e) => console.log('TS err:', e));

    if (!totalSupply) {
      console.log('no tsply');
      return;
    }

    const presaleStart = Number(info.config.approvedsaleStart);
    const presaleEnd = Number(info.config.approvedsaleEnd);
    const saleStart = Number(info.config.saleStart);
    const saleEnd = Number(info.config.saleEnd);

    const fullPrice = info.config.fullPrice;

    const tokensLeft = info.config.maxUserMintable.sub(info.userMinted);

    setAvailableTokens(Number(tokensLeft));

    setMaxDiscountMintable(Number(info.config.maxApprovedSale));
    setMaxTokenPerAddress(Number(info.config.maxSalePerAddress));
    setMaxMintPerTransaction(Number(info.config.maxMintPerTransaction));
    setMinted(Number(totalSupply));

    let now = Date.parse(new Date()) / 1000;

    let presaleIsOver = presaleEnd - now <= 0;
    let saleIsOver = saleEnd - now <= 0;
    let saleIsOn = now >= saleStart && !saleIsOver;
    let presaleIsOn = now >= presaleStart && !presaleIsOver;

    //	let _discountPrice = 0;

    if (presaleIsOn) {
      let userParams = getUserParams();
      console.log('userParams', userParams);
      if (userParams) {
        setFreeWhitesaleMint(userParams.params.free_mint);
        setWhiteListSaleEthPrice(
          ethers.BigNumber.from(userParams.params.eth_price)
        );

        setWhiteListSaleTokenPrice(
          ethers.BigNumber.from(userParams.params.token_price)
        );
        let canMint =
          now > userParams.params.valid_from &&
          now < userParams.params.valid_to;

        setConnectedUserMintPeriodOver(now > userParams.params.valid_to);
        setConnectedUserMintPeriodStarted(now > userParams.params.valid_from);

        setPresaleStartTimeForUser(
          new Date(userParams.params.valid_from * 1000)
        );
        setPresaleEndTimeForUser(new Date(userParams.params.valid_to * 1000));

        setConnectedUserCanMint(canMint);
      } else {
        setWhiteListSaleEthPrice(fullPrice);
        setWhiteListSaleTokenPrice(ethers.BigNumber.from(0));
        setPresaleStartTimeForUser(null);
        setPresaleEndTimeForUser(null);
        setConnectedUserCanMint(false);
        setConnectedUserMintPeriodOver(true);
        setConnectedUserMintPeriodStarted(false);
      }
    }

    setPublicSaleEthPrice(ethers.BigNumber.from(fullPrice));
    setPublicSaleTokenPrice(ethers.BigNumber.from(info.config.fullDustPrice));

    if (info.config.erc777SaleEnabled) {
      setTokenSaleActive(true);
      if (userTokenAddress === null) {
        setUserTokenAddress(info.config.erc777tokenAddress);
        //setERC677TokenPrice(info.config.fullDustPrice);
      }
    }
    setEthSaleActive(info.config.ethSaleEnabled);
    setApprovedSaleIsActive(info.approvedSaleIsActive);

    setPreSaleStarted(presaleIsOn);
    setPreSaleFinished(presaleIsOver);

    setMainSaleStarted(saleIsOn);
    setMainSaleFinished(saleIsOver);

    setPresaleStartTime(new Date(presaleStart * 1000));
    setPresaleEndTime(new Date(presaleEnd * 1000));

    setSaleStartTime(new Date(saleStart * 1000));
    setSaleEndTime(new Date(saleEnd * 1000));

    if (presaleStart > 0 || saleStart > 0) {
      setSalesActive(true);
    }

    if (!presaleIsOn && !presaleIsOver) {
      setPresaleTimeCounter(new Date(presaleStart * 1000));
    } else {
      //console.log('presale over, or on');
      if (!presaleIsOver) {
        setPresaleTimeCounter(new Date(presaleEnd * 1000));
      }
    }

    if (!saleIsOn && !saleIsOver) {
      setSaleTimeCounter(new Date(saleStart * 1000));
    } else {
      //console.log('sale over, or on');
      if (!saleIsOver) {
        setSaleTimeCounter(new Date(saleEnd * 1000));
      }
    }
    setIsLoading(false);
  };

  const handleDiscountMint = async (withToken) => {
    if (chainId !== saleChain) {
      toast.error('Unsupported chain.');

      return;
    }

    let maxMintable = 0;
    let discountLeft = 0;
    //mintInfo =  await saleContract.checkDiscountAvailable(address);
    const userParams = getUserParams();

    if (!userParams) {
      setShowErrorPopup(true);
      return;
    }

    let now = Date.parse(new Date()) / 1000;
    let canMint =
      now > userParams.params.valid_from && now < userParams.params.valid_to;

    if (!canMint) {
      if (now < userParams.params.valid_from) {
        //not started yet...
        let d = new DateTime.fromMillis(userParams.params.valid_from * 1000);
        console.log(d.toFormat('dd. MM. hh:mma'));
        setUserDateReason(
          'Your mint period is not started yet. It starts at ' +
            d.toFormat('dd. MM. hh:mma')
        );
      } else {
        //over...
        setUserDateReason('Your mint period is over.');
      }

      setShowUserDateError(true);

      return;
    }

    const alreadyMintedByWallet = await saleContract
      ._mintedByWallet(address)
      .catch((e) => console.log);

    //console.log('minted by wallet',alreadyMintedByWallet);

    if (alreadyMintedByWallet) {
      maxMintable = userParams.params.max_mint - Number(alreadyMintedByWallet);

      discountLeft = maxDiscountMintable - minted;
      console.log(
        'max presale mitble',
        maxDiscountMintable,
        minted,
        Number(alreadyMintedByWallet),
        discountLeft
      );

      if (maxMintable > discountLeft) {
        maxMintable = discountLeft;
      }

      if (maxMintable > 0) {
        maxMintable =
          maxMintable < maxMintPerTransaction
            ? maxMintable
            : maxMintPerTransaction;
      }
    }

    if (maxMintable < 1) {
      if (discountLeft === 0) {
        toast.error('Approved sale supply is sold out.');
      } else {
        toast.error('You have already used up your presale quota.');
      }
      return;
    }

    let tokensLeft = availableTokens - minted;

    if (tokensLeft === 0) {
      toast.error('Sold out.');
      return;
    }

    //console.log('maxMintable', maxMintable,tokensLeft);

    /*  if(tokensLeft<maxMintable  ){
      maxMintable = tokensLeft;
    } */

    setBuyWithToken(withToken ? true : false);
    // console.log('TOKEN PRICE', ERC677TokenPrice, Number(ERC677TokenPrice));

    setUserMaxDiscountMintable(maxMintable);
    setCheckoutIsPresale(true);
    setIsCreditCard(false);
    setShowCheckout(true);
  };

  const handleMint = async (withToken) => {
    if (chainId !== saleChain) {
      toast.error('Unsupported chain.');
      return;
    }

    setApproveInProgress(true);
    //console.log('!!!!!!!max tokens per adddres', maxTokenPerAddress);
    const alreadyMintedByWallet = await saleContract
      ._mintedByWallet(address)
      .catch((e) => console.log);

    console.log(
      '==============MINTED BY WALLET================',
      alreadyMintedByWallet
    );
    console.log(
      '==============AVAILABLE TOKENS================',
      availableTokens
    );
    if (alreadyMintedByWallet) {
      let maxMintableMainSale =
        maxTokenPerAddress - Number(alreadyMintedByWallet);

      if (maxMintableMainSale > availableTokens) {
        maxMintableMainSale = availableTokens;
      }

      if (maxMintableMainSale > 30) {
        maxMintableMainSale = 30;
      }

      /*
      if(maxTokenPerAddress < maxMintableMainSale){
        maxMintableMainSale=maxTokenPerAddress-Number(alreadyMintedByWallet);
      }

       if(Number(alreadyMintedByWallet)>=maxTokenPerAddress){
        maxMintableMainSale=0;
      } */

      console.log(
        'maxMintableMainSale',
        maxTokenPerAddress,
        maxMintableMainSale,
        maxMintableDuringMainSale
      );

      if (maxMintableMainSale > 0) {
        setMaxMintableDuringMainSale(
          maxMintableMainSale < maxMintPerTransaction
            ? maxMintableMainSale
            : maxMintPerTransaction
        );

        setBuyWithToken(withToken ? true : false);

        setCheckoutIsPresale(false);
        setIsCreditCard(false);
        setApproveInProgress(false);
        setShowCheckout(true);
      } else {
        setApproveInProgress(false);
        if (availableTokens === 0) {
          toast.error('Sold out.');
        } else {
          toast.error('Already minted your quota.');
        }
      }
    } else {
      setApproveInProgress(false);
      console.log("can't get already minted tokens");
    }
  };

  const mintDisco = async (amount, withToken) => {
    setShowCheckout(false);
    setApproveInProgress(true);
    let userParams = getUserParams();
    if (!userParams) {
      return;
    }

    let tx = null;

    if (withToken) {
      //console.log('with token');

      let token = userToken.connect(ethersProvider.getSigner());
      if (token) {
        let payload = await encodeUserDataForTokenTransfer([
          BUY_TYPE_APSALE,
          amount,
          [...userParams.raw_params, userParams.signature],
        ]);

        if (tokenIsERC777) {
          tx = await token
            .send(
              saleAddress,
              whiteListSaleTokenPrice.mul(amount), //default_sale_tokenPrice,
              payload
              // {gasLimit: maxBlockGas}
            )
            .catch(handleError);
        } else {
          tx = await token
            .transferAndCall(
              saleAddress,
              whiteListSaleTokenPrice.mul(amount), //default_sale_tokenPrice,
              payload
              // {gasLimit: maxBlockGas}
            )
            .catch(handleError);
        }
        setApproveInProgress(false);
      }
    } else {
      let sc = saleContract.connect(ethersProvider.getSigner());

      tx = await sc
        .mint_approved(
          [...userParams.raw_params, userParams.signature],
          amount,
          {
            value: whiteListSaleEthPrice.mul(amount),
          }
        )
        .catch(handleError);

      setApproveInProgress(false);
    }

    if (tx) {
      let explorerBase =
        saleChain === 1 || saleChain === 5
          ? config.ETHERSCAN_URL
          : config.POLYGONSCAN_URL;
      setTxEtherScan(`${explorerBase}/tx/${tx.hash}`);

      setTxInProgress(true);
      await tx.wait().catch((e) => {
        handleError(e);
        setTxInProgress(false);
      });
      setTxInProgress(false);
      getSaleInfo();
      setTab(1); //-> wallet
      localStorage.setItem('activeTab', 1);
    }
  };

  const encodeUserDataForTokenTransfer = async (params) => {
    //console.log('encode params',params);
    const encoded = AbiCoder.encode(
      [
        'uint256',
        'uint256',
        'tuple(uint256,uint256,bool,uint16,address,uint256,uint256,uint256,uint256,bytes)',
      ],
      params
    );

    return encoded;
  };

  const handleCreditCard = async () => {
    console.log('CC');
    setMaxMintableDuringMainSale(5);
    setCheckoutIsPresale(false);
    setApproveInProgress(false);
    setIsCreditCard(true);
    setShowCheckout(true);
  };

  const mintRegular = async (amount, withToken) => {
    // console.log(amount * price);
    let tx = null;
    if (withToken) {
      let token = userToken.connect(ethersProvider.getSigner());
      if (token) {
        /* 
        projectID:  _projectID,
        chainID:    _chainID,
        free:       _free, 
        max_mint:   _max_mint,
        receiver:   _receiver,
        valid_from: _valid_from,
        valid_to:   _valid_to,
        eth_price:  _eth_price,
        dust_price: _dust_price
        */

        let params = [
          0,
          0,
          false,
          0,
          ethers.constants.AddressZero,
          0,
          0,
          0,
          0,
          0,
        ];
        let payload = await encodeUserDataForTokenTransfer([
          BUY_TYPE_SALE,
          amount,
          params,
        ]);
        //console.log('PAYLOAD',payload);
        setShowCheckout(false);
        setApproveInProgress(true);

        if (tokenIsERC777) {
          tx = await token
            .send(
              saleAddress,
              publicSaleTokenPrice.mul(amount), //default_sale_tokenPrice,
              payload
              // {gasLimit: maxBlockGas}
            )
            .catch(handleError);
        } else {
          tx = await token
            .transferAndCall(
              saleAddress,
              publicSaleTokenPrice.mul(amount), //default_sale_tokenPrice,
              payload
              // {gasLimit: maxBlockGas}
            )
            .catch(handleError);
        }

        //console.log('TX',tx);
      }
    } else {
      let sc = saleContract.connect(ethersProvider.getSigner());
      setShowCheckout(false);
      setApproveInProgress(true);
      tx = await sc
        .mint(amount, { value: publicSaleEthPrice.mul(amount) })
        .catch(handleError);
    }
    setApproveInProgress(false);

    if (tx) {
      console.log('salechain', saleChain);

      let explorerBase =
        saleChain === 1 || saleChain === 5
          ? config.ETHERSCAN_URL
          : config.POLYGONSCAN_URL;

      setTxEtherScan(`${explorerBase}/tx/${tx.hash}`);
      setTxInProgress(true);
      await tx.wait().catch((e) => {
        handleError(e);
        setTxInProgress(false);
      });
      setTxInProgress(false);
      getSaleInfo();
      setTab(1); //-> wallet
      localStorage.setItem('activeTab', 1);
    }
  };

  const handleError = (e) => {
    console.log('FULL', e);
    console.log('DATA', e.data);
    console.log('REASON', e.reason);
    console.log('ERROR', e.error);

    if (e.data && e.data.message) {
      if (e.data.message.indexOf('insufficient funds') > -1) {
        toast.error('Insufficient funds.');
      } else {
        toast.error(e.data.message);
      }
    } else if (e.reason) {
      toast.error(e.reason);
    } else if (e.message) {
      toast.error(e.message);
    } else if (e.error && e.error.message) {
      toast.error(e.error.message);
    }
  };

  const getStyle = (num) => {
    if (modeCtx.lightTheme) {
      if (tab == num) {
        return sx.tabSelected;
      } else {
        return null;
      }
    } else {
      if (tab == num) {
        return sx.tabSelectedDark;
      } else {
        return null;
      }
    }
  };

  return (
    <Box sx={sx.saleRoot}>
      {salesActive && (
        <Box sx={sx.infoContainer}>
          <Box
            sx={sx.badgeContainer}
            style={{ border: !modeCtx.lightTheme && '2px solid #fff' }}
          >
            <InfoBox
              label='ITEMS'
              value={communityId === 7 ? minted : totalAmount * 0 + availableTokens + minted}
              sx={
                (!preSaleFinished || !mainSaleFinished) && availableTokens > 0
                  ? sx.badgeLeft
                  : sx.badgeCenterSaleOver
              }
              style={{ borderColor: !modeCtx.lightTheme && '#fff' }}
            />

            {minted > 0 &&
              (!preSaleFinished || !mainSaleFinished) &&
              availableTokens > 0 && (
                <InfoBox
                  label='SOLD'
                  value={minted}
                  sx={
                    (preSaleFinished || availableTokens === 0) &&
                    (mainSaleFinished || availableTokens === 0)
                      ? sx.badgeRight
                      : sx.badgeCenter
                  }
                  style={{ borderColor: !modeCtx.lightTheme && '#fff' }}
                />
              )}

            {((!preSaleFinished && presaleEndTime.getTime() > 0) ||
              (!mainSaleFinished && saleEndTime.getTime() > 0) ||
              freeWhitesaleMint) &&
              availableTokens > 0 && (
                <InfoBox
                  label='PRICE'
                  value={
                    preSaleFinished ||
                    (whiteListSaleEthPrice.eq(0) && !freeWhitesaleMint)
                      ? saleEndTime.getTime() > 0
                        ? ethers.utils.formatEther(publicSaleEthPrice)
                        : 0
                      : connectedUserCanMint || !connectedUserMintPeriodStarted
                      ? ethers.utils.formatEther(whiteListSaleEthPrice)
                      : ethers.utils.formatEther(publicSaleEthPrice)
                  }
                  eth
                  chain={saleChain}
                  sx={
                    (!preSaleFinished &&
                      availableTokens > 0 &&
                      whiteListSaleTokenPrice.gt(0) &&
                      !connectedUserMintPeriodOver) ||
                    (!mainSaleFinished &&
                      availableTokens > 0 &&
                      publicSaleTokenPrice.gt(0))
                      ? sx.badgeCenter
                      : sx.badgeRight
                  }
                  style={{ borderColor: !modeCtx.lightTheme && '#fff' }}
                />
              )}

            {(((publicSaleTokenPrice.gt(0) ||
              (whiteListSaleTokenPrice.gt(0) &&
                !connectedUserMintPeriodOver)) &&
              !preSaleFinished &&
              availableTokens > 0 &&
              whiteListSaleTokenPrice.gt(0)) ||
              (!mainSaleFinished &&
                availableTokens > 0 &&
                publicSaleTokenPrice.gt(0) &&
                availableTokens > 0)) && (
              <InfoBox
                label='PRICE'
                value={
                  !preSaleFinished &&
                  whiteListSaleTokenPrice.gt(0) &&
                  !connectedUserMintPeriodOver
                    ? ethers.utils.formatEther(whiteListSaleTokenPrice)
                    : //presale is over
                      ethers.utils.formatEther(publicSaleTokenPrice)
                }
                tokenName={ERC677Symbol}
                chain={saleChain}
                sx={sx.badgeRight}
              />
            )}
          </Box>
        </Box>
      )}

      {salesActive && (
        <>
          <ButtonGroup
            sx={sx.tabContainer}
            variant='outlined'
            size='medium'
            aria-label='medium outlined button group'
          >
            <Button
              sx={{
                ...(modeCtx.lightTheme ? sx.tabBtns : sx.tabBtnsDark),
                ...getStyle(0),
              }}
              onClick={() => {
                setTab(0);
                localStorage.setItem('activeTab', 0);
              }}
            >
              SALE INFO
            </Button>
            <Button
              sx={{
                ...(modeCtx.lightTheme ? sx.tabBtns : sx.tabBtnsDark),
                ...getStyle(1),
              }}
              onClick={() => {
                setTab(1);
                localStorage.setItem('activeTab', 1);
              }}
            >
              COLLECTION WALLET
            </Button>
          </ButtonGroup>

          <SectionDividers />
        </>
      )}

      {tab === 0 && salesActive ? (
        <>
          {/* PRESALE */}

          {!preSaleFinished && availableTokens > 0 && (
            <>
              <Typography align='center' sx={sx.title}>
                {saleLabels.preSaleTitle}{' '}
                {!preSaleStarted && !preSaleFinished && (
                  <>
                    <Box component='span'>is Coming Soon</Box>
                    {!address && (
                      <Typography
                        sx={sx.description}
                        style={{ marginTop: 0 }}
                      ></Typography>
                    )}
                  </>
                )}
                {preSaleStarted && (
                  <>
                    <Box
                      sx={sx.titleLive}
                      style={{ color: modeCtx.lightTheme ? '#000' : '#fff' }}
                      component='span'
                    >
                      is Live
                    </Box>
                  </>
                )}
                {preSaleFinished && <Box component='span'>Ended</Box>}
              </Typography>
              {preSaleStarted && (
                <p style={{ color: modeCtx.lightTheme ? '#000' : '#fff' }}>
                  It ends in
                </p>
              )}

              <Counter
                date={presaleTimeCounter}
                isActive={preSaleStarted}
                onFinish={() => {
                  console.log('presale timer is up');
                  getSaleInfo();
                }}
                id='presale'
              />

              <Box
                sx={sx.timeContainer}
                style={{
                  border: modeCtx.lightTheme
                    ? '2px solid #000'
                    : '2px solid #fff',
                }}
              >
                {presaleStartTimeForUser && presaleEndTimeForUser ? (
                  <Timer
                    time={
                      <>
                        {presaleStartTimeForUser
                          ? new DateTime.fromMillis(
                              presaleStartTimeForUser.getTime()
                            ).toFormat('dd. MM. hh:mma')
                          : ''}{' '}
                        -{' '}
                        {presaleEndTimeForUser
                          ? new DateTime.fromMillis(
                              presaleEndTimeForUser.getTime()
                            ).toFormat('dd. MM. hh:mma')
                          : ''}
                      </>
                    }
                  />
                ) : (
                  <Timer
                    time={
                      <>
                        {presaleStartTime
                          ? new DateTime.fromMillis(
                              presaleStartTime.getTime()
                            ).toFormat('dd. MM. hh:mma')
                          : ''}{' '}
                        -{' '}
                        {presaleEndTime
                          ? new DateTime.fromMillis(
                              presaleEndTime.getTime()
                            ).toFormat('dd. MM. hh:mma')
                          : ''}
                      </>
                    }
                  />
                )}
              </Box>
              {saleLabels.preSaleDescription && (
                <p
                  style={{
                    marginTop: '15px',
                    color: modeCtx.lightTheme ? '#000' : '#fff',
                  }}
                >
                  {saleLabels.preSaleDescription}
                </p>
              )}
              {address && (
                <p
                  style={{
                    marginTop: '15px',
                    color: '#fe2c85',
                  }}
                >
                  {userData
                    ? "Your wallet " + String(address).substring(0, 6) + "..." + String(address).slice(-4) + " is eligible to mint "
                    + userData.params.max_mint + " collectible(s) for the price of " + ethers.BigNumber.from(userData.params.eth_price) + " "
                    + (saleChain === 1 || saleChain === 5 || saleChain === 11155111 ? 'ETH' : 'MATIC')
                    + " starting at "
                      + new DateTime.fromMillis(
                        new Date(userData.params.valid_from * 1000).getTime()
                        ).toFormat('dd. MM. hh:mma')
                  : "Your wallet is not eligible to mint!"}
                </p>
              )}
              {preSaleStarted && (
                <Box mb={5}>
                  {(whiteListSaleEthPrice.gt(0) || freeWhitesaleMint) && (
                    <Button
                      sx={modeCtx.lightTheme ? sx.buyButton : sx.buyButtonDark}
                      style={{ opacity: userOnList ? '1' : '0.5' }}
                      variant='banner'
                      onClick={(e) => handleDiscountMint(false)}
                      disabled={!address || isLoading || totalAmount === minted}
                    >
                      BUY WITH{' '}
                      {saleChain === 1 || saleChain === 5 || saleChain === 11155111 ? 'ETH' : 'MATIC'}
                    </Button>
                  )}

                  {tokenSaleActive && whiteListSaleTokenPrice.gt(0) && (
                    <Button
                      sx={modeCtx.lightTheme ? sx.buyButton : sx.buyButtonDark}
                      style={{ opacity: userOnList ? '1' : '0.5' }}
                      variant='banner'
                      onClick={(e) => handleDiscountMint(true)}
                      disabled={!address || isLoading || totalAmount === minted}
                    >
                      BUY WITH {ERC677Symbol}
                    </Button>
                  )}

                  {!address && (
                    <Button
                      variant='banner'
                      sx={sx.buyButtonDark}
                      onClick={handleConnect}
                    >
                      CONNECT WALLET
                    </Button>
                  )}
                </Box>
              )}
            </>
          )}

          {/* SALE */}
          {!mainSaleFinished && availableTokens > 0 && (
            <>
              <Typography
                align='center'
                mt={!mainSaleStarted && !mainSaleFinished ? 5 : 0}
                sx={sx.title}
              >
                {saleLabels.publicSaleTitle}{' '}
                {!mainSaleStarted && !mainSaleFinished && (
                  <Box component='span'>Is Coming Soon</Box>
                )}
                {mainSaleStarted && (
                  <Box
                    sx={sx.titleLive}
                    style={{ color: modeCtx.lightTheme ? '#000' : '#fff' }}
                    component='span'
                  >
                    Is Live
                  </Box>
                )}
                {mainSaleFinished && <Box component='span'>Ended</Box>}
              </Typography>

              {mainSaleStarted && (
                <p style={{ color: modeCtx.lightTheme ? '#000' : '#fff' }}>
                  It ends in
                </p>
              )}

              {preSaleFinished && (
                <Counter
                  date={saleTimeCounter}
                  isActive={mainSaleStarted}
                  onFinish={() => {
                    console.log('sale timer is up');
                    getSaleInfo();
                  }}
                  id='sale'
                />
              )}

              {!mainSaleStarted && !mainSaleFinished && (
                <Typography
                  sx={sx.description}
                  style={{ marginTop: '15px', marginBottom: '1rem' }}
                >
                  It starts at
                </Typography>
              )}

              <Box
                sx={sx.timeContainer}
                style={{
                  border: modeCtx.lightTheme
                    ? '2px solid #000'
                    : '2px solid #fff',
                }}
              >
                <Timer
                  time={
                    <>
                      {saleStartTime
                        ? new DateTime.fromMillis(
                            saleStartTime.getTime()
                          ).toFormat('dd. MM. hh:mma')
                        : ''}{' '}
                      -{' '}
                      {saleEndTime
                        ? new DateTime.fromMillis(
                            saleEndTime.getTime()
                          ).toFormat('dd. MM. hh:mma')
                        : ''}
                    </>
                  }
                />
              </Box>
              {saleLabels.publicSaleDescription && (
                <p
                  style={{
                    marginTop: '15px',
                    color: modeCtx.lightTheme ? '#000' : '#fff',
                  }}
                >
                  {saleLabels.publicSaleDescription}
                </p>
              )}

              {mainSaleStarted && (
                <Box>
                  {ethSaleActive && !publicSaleEthPrice.eq(0) && (
                    <Button
                      sx={modeCtx.lightTheme ? sx.buyButton : sx.buyButtonDark}
                      variant='banner'
                      onClick={(e) => handleMint(false)}
                      disabled={!address || isLoading || totalAmount === minted}
                    >
                      BUY WITH{' '}
                      {saleChain === 1 || saleChain === 5 || saleChain === 11155111 ? 'ETH' : 'MATIC'}
                    </Button>
                  )}

                  {tokenSaleActive && !publicSaleTokenPrice.eq(0) && (
                    <Button
                      sx={modeCtx.lightTheme ? sx.buyButton : sx.buyButtonDark}
                      // style={{ margin: '10px 10px 30px 10px'}}
                      variant='banner'
                      onClick={(e) => handleMint(true)}
                      disabled={!address || isLoading || totalAmount === minted}
                    >
                      BUY WITH {ERC677Symbol}
                    </Button>
                  )}

                  {!address && (
                    <Button
                      sx={modeCtx.lightTheme ? sx.buyButton : sx.buyButtonDark}
                      variant='banner'
                      // style={{ margin: '10px 10px 30px 10px' }}
                      onClick={handleConnect}
                    >
                      CONNECT WALLET
                    </Button>
                  )}

                  {config.CREDITCARD_ENABLED && (
                    <Button
                      sx={sx.buyButtonDark}
                      variant='banner'
                      // style={{ margin: "10px 10px 30px 10px" }}
                      onClick={handleCreditCard}
                    >
                      PAY WITH CREDIT CARD
                    </Button>
                  )}
                </Box>
              )}
              <SectionDividers />
            </>
          )}

          {preSaleFinished && mainSaleFinished && (
            <>
              <Typography
                align='center'
                sx={sx.title}
                style={{ marginBottom: '2rem' }}
              >
                <Box
                  sx={sx.titleLive}
                  component='span'
                  style={{ color: modeCtx.lightTheme ? '#000' : '#fff' }}
                >
                  The sale is over
                </Box>
              </Typography>

              {openSeaName && (
                <Typography
                  sx={sx.description}
                  style={{ marginTop: '3rem', marginBottom: '3rem' }}
                >
                  Missed our NFT sale? Check out the Collection on{' '}
                  <Box
                    component='a'
                    href={`${config.OPENSEA_URL}collection/${openSeaName}`}
                    sx={sx.url}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    OpenSea
                  </Box>
                </Typography>
              )}
            </>
          )}

          {(!preSaleFinished || !mainSaleFinished) && availableTokens === 0 && (
            <Typography
              align='center'
              sx={sx.title}
              style={{ marginBottom: '2rem' }}
            >
              <Box
                sx={sx.titleLive}
                component='span'
                style={{ color: modeCtx.lightTheme ? '#000' : '#fff' }}
              >
                Sold out.
              </Box>
            </Typography>
          )}
        </>
      ) : (
        <>
          <Wallet
            tokenContract={tokenContract}
            saleChain={saleChain}
            zoomFromSale={zoomContract}
          />
          <SectionDividers />
        </>
      )}

      <PermissionErrorModal
        isOpen={showErrorPopup}
        setOpen={setShowErrorPopup}
      />

      <PopupModal
        isOpen={showUserDateError}
        setOpen={setShowUserDateError}
        header="We're Sorry"
      >
        <Typography style={{ marginBottom: '20px' }}>
          {userDateReason}
        </Typography>
      </PopupModal>

      <CheckoutModal
        tokenName={symbol}
        isOpen={showCheckout}
        setOpen={() => {
          if (!txInProgress && !approveInProgress) {
            setShowCheckout(false);
          }
        }}
        isPresale={checkoutIsPresale}
        withCreditCard={isCreditCard}
        whitelistLimit={
          checkoutIsPresale
            ? userMaxDiscountMintable
            : maxMintableDuringMainSale
        }
        salePrice={ethers.utils.formatEther(publicSaleEthPrice)}
        presalePrice={ethers.utils.formatEther(whiteListSaleEthPrice)}
        tokenPrice={ethers.utils.formatEther(publicSaleTokenPrice)}
        presaleTokenPrice={ethers.utils.formatEther(whiteListSaleTokenPrice)}
        withToken={buyWithToken}
        erc677Symbol={ERC677Symbol}
        mintSale={mintRegular}
        mintPresale={mintDisco}
        chain={saleChain}
        maxMintPerTx={maxMintPerTransaction}
      />
      <BackdropModal isOpen={approveInProgress} />

      <TxProgressModal isOpen={txInProgress} txEtherScan={txEtherScan} />
    </Box>
  );
};
export default SalesV3;
