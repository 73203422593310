import { Box, Button, ButtonGroup, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Contract } from '@ethersproject/contracts';
import { DateTime } from 'luxon';
import config from '../../config/config';
import { ethers } from 'ethers';
import { toast } from 'react-toast';

import useInterval from '../../hooks/useInterval';

import galaxis from '../../assets/images/galaxis.svg';
import InfoBox from './components/InfoBox';
import leftTrick from '../../assets/images/leftTrick.svg';
import rightTrick from '../../assets/images/rightTrick.svg';
import Counter from './components/Counter/Counter';
import Timer from './components/Timer';
import useWeb3Ctx from '../../hooks/useWeb3Ctx';

import saleABI from './abi/Sale.json';
import tokenABI from './abi/Token.json';
import CheckoutModal from './components/CheckoutModal';
import BackdropModal from './components/BackdropModal';
import TxProgressModal from './components/TxProgressModal';
import PermissionErrorModal from './components/PermissionErrorModal';
import './Sales.css';
//import sigs from "./signatures/sigs"; // will be downloaded in the future
import { width } from '@mui/system';
import Wallet from './components/Wallet';
import SectionDividers from './components/SectionDividers';
import axios from 'axios';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { ModeCtx } from '../../context/modeCtx';

const BP2 = '@media (max-width: 1345px)';
const BP4 = '@media (max-width: 600px)';
const BP5 = '@media (max-width: 899px)';
const BP6 = '@media (max-width: 700px)';
const BP3 = '@media (max-width: 384px)';
const BP7 = '@media (max-width: 820px)';
const BP1 = '@media (max-width: 570px)';

const sx = {
  container: {
    margin: 'auto',
    mt: '-5px',
    position: 'relative',
    pt: '50px',
    pb: '100px',
    maxWidth: '1233px',
    overflow: 'hidden',
    transition: 'all .3s',
    backgroundColor: '#FFF',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    px: '20px',
    [BP4]: {
      pt: '39px',
    },
  },
  nameContainer: {
    marginTop: '50px',
    position: 'absolute',
    mx: 'auto',
    display: 'flex',
    maxWidth: '1140px',
    width: '100%',
    zIndex: '1',
    backgroundColor: '#511BA3',
    [BP4]: {
      marginTop: '35px',
    },
  },
  name: {
    position: 'absolute',
    height: '135px',
    width: '135px',
    backgroundColor: '#511BA3',
    borderRadius: '15px',
    bottom: '4px',
    left: '0',
    right: '0',
    marginRight: 'auto',
    marginLeft: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    textAlign: 'center',
    paddingTop: '30px',
  },
  badgeContainer: {
    position: 'relative',
    margin: 'auto',
    // maxWidth: "300px",
    borderRadius: '12px',
    border: 'solid 2px #000',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: '12px',
    px: '20px',
  },
  badgeLeft: {
    borderRight: 'solid 2px #000',
    paddingRight: '15px',
    [BP3]: {
      borderRight: 'unset',
      borderBottom: 'solid 2px #000',
      width: '100%',
      paddingRight: '0',
    },
  },
  badgeCenter: {
    borderRight: 'solid 2px #000',
    paddingRight: '15px',
    [BP3]: {
      borderRight: 'unset',
      borderBottom: 'solid 2px #000',
      width: '100%',
      paddingRight: '0',
    },
  },
  badgeRight: {
    [BP3]: {
      width: '100%',
    },
  },
  timeContainer: {
    padding: '4px',
    margin: 'auto',
    mt: '20px',
    maxWidth: '512px',
    borderRadius: '12px',
    webkitboxShadow: '0px 4px 20px -3px rgba(0,0,0,0.1)',
    boxShadow: '0px 4px 20px -3px rgba(0,0,0,0.1)',
    backgroundColor: '#FFFFFF',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: '12px',
    [BP4]: {
      padding: '10px',
    },
  },

  title: {
    fontFamily: 'poppins-semibold',
    fontSize: '36px'
  },
  titleLive: {
    color: '#000',
  },
  socialContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: '17px',
    mt: '20px',
    mb: '30px',
  },
  description: {
    fontFamily: 'bau',
    color: '#030000',
    fontSize: '16px',
    lineHeight: '24px',
    maxWidth: '606px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    margin: 'auto',
    mt: '25px',
  },
  nftContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  largeBanner: {
    display: 'block',
    [BP7]: {
      display: 'none',
    },
  },
  smallBanner: {
    display: 'none',
    [BP7]: {
      display: 'block',
    },
  },
  button: {
    backgroundColor: '#fe2c85',
    color: '#FAFAFA',
    fontSize: '14px',
    fontFamily: 'poppins-semibold',
    padding: '14px',
    borderRadius: '8px',
    '&:hover': {
      backgroundColor: '#fe2c85',
    },
  },
  buttonOutlined: {
    backgroundColor: 'transparent',
    color: '#fe2c85',
    fontSize: '14px',
    fontFamily: 'poppins-semibold',
    padding: '14px',
    borderRadius: '8px',
    '&:hover': {
      backgroundColor: '#fe2c85',
      color: 'white',
    },
  },

  tabContainer: {
    justifyContent: 'center',
    paddingTop: '30px',
  },
  tabBtns: {
    color: '#000',
    borderColor: '#000',
    borderWidth: '2px',
    fontFamily: 'poppins-semibold',
    backgroundColor:"#fff",
    padding: '14px',
    '&:hover': {
      borderColor: '#000',
      backgroundColor: '#000',
      borderWidth: '2px',
      color: '#fff',
      borderRightColor: '#000 !important',
    },
    borderRadius: '10px',
  },
  tabSelected: {
    backgroundColor: '#000',
    color: '#fff',
  },
  buyButton: {
    height: '35px',
    borderRadius: '5px',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    backgroundColor: 'unset',
    border: '2px solid #fe2c85',
    color: '#fe2c85',
    '&:hover': {
      border: '2px solid #fe2c85',
      backgroundColor: '#fe2c85',
      color: '#ffffff',
    },
    '&:disabled': {
      border: '2px solid rgba(0, 0, 0, 0.26);',
    },
    [BP3]: {
      width: '100%',
      px: '14px',
    },
  },
  url: {
    textDecoration: 'none',
    color: '#fe2c85',
    fontWeight: '700',
  },
  description: {
    fontFamily: 'bau',
    color: '#1C1E23',
    fontSize: '16px',
    lineHeight: '28px',
    maxWidth: '856px',
    width: '100%',
    margin: 'auto',
    mt: '27px',
    mb: '40px',
  },
};

export const SALE_STATUS = {
  UPCOMING_PRESALE: 0,
  PRESALE: 1,
  PRESALE_ENDED: 2,
  SALE: 3,
  SALE_ENDED: 4,
};

const Sales = ({ tokenAddress, saleAddress, symbol, whitelist, labels }) => {
  const { onboard, address, ethersProvider, handleConnect } = useWeb3Ctx();

  const [tab, setTab] = useState(0);

  const [minted, setMinted] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [salePrice, setSalePrice] = useState(0);
  const [discountPrice, setDiscountPrice] = useState(0);

  //sale states needed a different approach, because the sales can overlap each other :/

  const [preSaleStarted, setPreSaleStarted] = useState(false);
  const [preSaleFinished, setPreSaleFinished] = useState(false);

  const [mainSaleStarted, setMainSaleStarted] = useState(false);
  const [mainSaleFinished, setMainSaleFinished] = useState(false);

  const [presaleStartTime, setPresaleStartTime] = useState(null);
  const [saleStartTime, setSaleStartTime] = useState(null);
  const [presaleEndTime, setPresaleEndTime] = useState(null);
  const [saleEndTime, setSaleEndTime] = useState(null);

  const [presaleTimeCounter, setPresaleTimeCounter] = useState(null);
  const [saleTimeCounter, setSaleTimeCounter] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const [tokenContract, setTokenContract] = useState(null);
  const [saleContract, setSaleContract] = useState(null);

  const [txEtherScan, setTxEtherScan] = useState('');
  const [showCheckout, setShowCheckout] = useState(false);
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [txInProgress, setTxInProgress] = useState(false);
  const [approveInProgress, setApproveInProgress] = useState(false);
  const [checkoutIsPresale, setCheckoutIsPresale] = useState(true);

  const [signature, setSignature] = useState(null);

  const [userMaxDiscountMintableCard, setUserMaxDiscountMintableCard] =
    useState(0);
  const [userMaxDiscountMintableWl, setUserMaxDiscountMintableWl] = useState(0);

  const [maxDiscountMintable, setMaxDiscountMintable] = useState(10);
  const [maxTokenPerAddress, setMaxTokenPerAddress] = useState(10);
  const [maxMintableDuringMainSale, setMaxMintableDuringMainSale] =
    useState(10);

  const [refreshInterval, setRefreshInterval] = useState(null);

  const [signatures, setSignatures] = useState(null);

  const [saleLabels, setSaleLabels] = useState({
    preSaleTitle: 'Pre-Sale',
    preSaleDescription: '',
    publicSaleTitle: 'Public Sale',
    publicSaleDescription: '',
  });

  const modeCtx = useContext(ModeCtx)

  useEffect(() => {
    if (whitelist) {
      console.log('WHIIIITEEEEEE', whitelist);
      console.log('WHIIIITEEEEEE URL', config.AWS_URL + whitelist);

      axios
        .get(config.AWS_URL + whitelist)
        .then((res) => {
          console.log(res.status);
          console.log('whitelist from aws', res);
          if (res && res.status === 200) {
            setSignatures(res.data);
          } else {
            toast.error(res.message);
          }
        })
        .catch((e) => console.log);
    } else {
      setSignatures(null);
    }

    setSaleLabels(labels);
  }, []);

  useEffect(() => {
    (async () => {
      if (sessionStorage.getItem('selectedWallet')) {
        let t = Number(localStorage.getItem('activeTab'));
        if (t === 1) {
          setTab(1);
        } else {
          setTab(0);
        }
      }
    })();
  }, [onboard]);

  useInterval(
    () => {
      if (mainSaleFinished) {
        setRefreshInterval(null);
      } else {
        console.log('interval get sale');
        getSaleInfo();
      }
    },
    refreshInterval,
    false
  );

  useEffect(() => {
    if (address && whitelist && signatures) {
      let whitelistSig = null;

      const key = Object.keys(signatures).find(
        (key) => key.toLowerCase() == address.toLowerCase()
      );

      if (key) {
        whitelistSig = signatures[key];
      } else {
        console.log('sig not found');
        whitelistSig = null;
      }

      setSignature(whitelistSig);

      console.log('Signature', whitelistSig);
    }
  }, [address, signatures]);

  useEffect(() => {
    const initContracts = async () => {
      if (
        (tokenContract == null || saleContract === null) &&
        ethersProvider !== null
      ) {
        console.log('EP in contract init', ethersProvider);

        let token = new Contract(tokenAddress, tokenABI.abi, ethersProvider);
        if (!token) {
          console.error('Token contract not found on address', tokenAddress);
          return;
        }

        let sale = new Contract(saleAddress, saleABI.abi, ethersProvider);
        if (!sale) {
          console.error('Sale contract not found on address', saleAddress);
        }

        console.log('CONTRACTS INITIATED', token, sale);
        setTokenContract(token);
        setSaleContract(sale);
      }
    };

    if (saleAddress && tokenAddress) {
      console.log('props changed', saleAddress, tokenAddress, ethersProvider);
      initContracts();
    }
  }, [saleAddress, tokenAddress, ethersProvider]);

  useEffect(() => {
    const getInfo = async () => {
      console.log('getting sale info start');
      await getSaleInfo();
      console.log('getting sale info end');
    };

    if (tokenContract !== null && saleContract !== null) {
      console.log(
        '+++++++++++++++++++++get sale info in effect',
        tokenContract,
        saleContract
      );
      getInfo();
      setRefreshInterval(20000);
    }
  }, [tokenContract, saleContract]);

  const getSaleInfo = async () => {
    console.log('saleInfo', saleContract);
    setIsLoading(true);
    let addr = address ? address : ethers.constants.AddressZero;
    const info = await saleContract.tellEverything(addr);
    const totalSupply = await tokenContract.totalSupply();
    console.log('TS', totalSupply);
    console.log('info', info);

    const presaleStart = Number(info.presaleStart);
    const presaleEnd = Number(info.presaleEnd);
    const saleStart = Number(info.saleStart);
    const saleEnd = Number(info.saleEnd);

    const fullPrice = info.fullPrice;
    const _discountPrice = info.discountPrice;

    /* const maxTokens = Number(info.maxTokens);
		const userMinted = Number(info.userMinted);

		const presaleIsActive = info.presaleIsActive;
		const saleIsActive = info.saleIsActive; */

    setMaxDiscountMintable(Number(info.discountedPerAddress));
    setMaxTokenPerAddress(Number(info.maxPerSaleMint));

    setSalePrice(ethers.utils.formatEther(fullPrice));
    setDiscountPrice(ethers.utils.formatEther(_discountPrice));

    //	let reserved = info.maxTokens.sub(info.MaxUserMintable);
    //	setMinted(Number(info.userMinted.add(reserved)));
    setMinted(Number(totalSupply));
    setTotalAmount(Number(info.maxTokens));

    let now = Date.parse(new Date()) / 1000;

    console.log(now, presaleEnd);

    let presaleIsOver = presaleEnd - now <= 0;
    let saleIsOver = saleEnd - now <= 0;
    let presaleIsOn = now >= presaleStart && !presaleIsOver;
    let saleIsOn = now >= saleStart && !saleIsOver;

    setPreSaleStarted(presaleIsOn);
    setPreSaleFinished(presaleIsOver);

    setMainSaleStarted(saleIsOn);
    setMainSaleFinished(saleIsOver);

    //console.log(presaleStart,presaleEnd,saleStart,saleEnd,maxTokens,fullPrice,discountPrice,userMinted);

    console.log('presaleIsOver', presaleIsOver, presaleEnd - now);
    console.log('saleIsOver', saleIsOver, saleEnd - now);
    console.log('presaleIsOn', presaleIsOn, now >= presaleStart, presaleIsOver);
    console.log('saleIsOn', saleIsOn, now >= saleStart, !saleIsOver);

    setPresaleStartTime(new Date(presaleStart * 1000));
    setPresaleEndTime(new Date(presaleEnd * 1000));
    setSaleStartTime(new Date(saleStart * 1000));
    setSaleEndTime(new Date(saleEnd * 1000));

    if (!presaleIsOn && !presaleIsOver) {
      setPresaleTimeCounter(new Date(presaleStart * 1000));
    } else {
      console.log('presale over, or on');
      if (!presaleIsOver) {
        setPresaleTimeCounter(new Date(presaleEnd * 1000));
      }
    }

    if (!saleIsOn && !saleIsOver) {
      setSaleTimeCounter(new Date(saleStart * 1000));
    } else {
      console.log('sale over, or on');
      if (!saleIsOver) {
        setSaleTimeCounter(new Date(saleEnd * 1000));
      }
    }
    setIsLoading(false);
  };

  const handleDiscountMint = async () => {
    let mintInfo = null;
    let hasCards = false;
    let maxMintableWl = 0;
    let mintableCards = 0;

    mintInfo = await saleContract.checkDiscountAvailable(address);
    console.log('user can mint discount', mintInfo);
    hasCards = mintInfo[1];
    let whitelistMinted = Number(mintInfo[3]);
    let cardMinted = Number(mintInfo[2]);

    if (whitelistMinted + cardMinted >= maxDiscountMintable) {
      toast.error('You have alredy used up your presale quota.');
      return;
    }

    maxMintableWl = signature ? maxDiscountMintable - whitelistMinted : 0;
    mintableCards = mintInfo[1] ? maxDiscountMintable - cardMinted : 0;

    console.log('+++++++++++++++++++++++maxMintableWl', maxMintableWl);
    console.log('+++++++++++++++++++++++mintableCards', mintableCards);

    if (maxMintableWl > 10) {
      maxMintableWl = 10;
    }

    if (mintableCards > 10) {
      mintableCards = 10;
    }

    setUserMaxDiscountMintableCard(mintableCards);
    setUserMaxDiscountMintableWl(maxMintableWl);

    if (signature === null) {
      maxMintableWl = 0;
      if (!hasCards) {
        setShowErrorPopup(true);
      } else {
        //has cards, can mint
        if (mintableCards > 0) {
          setCheckoutIsPresale(true);
          setShowCheckout(true);
        } else {
          toast.error('You have alredy used up your presale quota.');
        }
      }
    } else {
      //whitelisted, can mint
      if (maxMintableWl > 0) {
        setCheckoutIsPresale(true);
        setShowCheckout(true);
      } else {
        if (hasCards && mintableCards > 0) {
          setCheckoutIsPresale(true);
          setShowCheckout(true);
        } else {
          toast.error('You have alredy used up your presale quota.');
        }
      }
    }
  };

  const handleMint = async () => {
    if (maxTokenPerAddress > 20) {
      setMaxMintableDuringMainSale(10);
      setCheckoutIsPresale(false);
      setShowCheckout(true);
    } else {
      setApproveInProgress(true);

      let mintInfo = await saleContract.checkDiscountAvailable(address);

      console.log(mintInfo);

      let publicSaleMinted = mintInfo.length > 4 ? Number(mintInfo[4]) : 0;

      console.log('public minted', publicSaleMinted);
      let maxMintableMainSale = maxTokenPerAddress - publicSaleMinted;

      if (maxMintableMainSale === 0) {
        toast.error('You have alredy used up your quota.');
        setApproveInProgress(false);
        return;
      }

      setMaxMintableDuringMainSale(
        maxMintableMainSale < 10 ? maxMintableMainSale : 10
      );
      setApproveInProgress(false);
      setCheckoutIsPresale(false);
      setShowCheckout(true);
    }
  };

  const mintDisco = async (amount, price) => {
    let sc = saleContract.connect(ethersProvider.getSigner());

    setShowCheckout(false);
    setApproveInProgress(true);

    let tx = null;
    if (userMaxDiscountMintableWl > 0 && signature !== null) {
      let mint_free = false;
      //	let max_mint = totalAmount;
      let max_mint = maxTokenPerAddress;
      let from = address;
      let start = '0';
      let end = '5555555555';
      let eth_price = ethers.utils.parseUnits(discountPrice, 'ether');
      let dust_price = '0';
      let sig = signature[0];

      let structAgg = [
        mint_free,
        max_mint,
        from,
        start,
        end,
        eth_price,
        dust_price,
        sig,
      ];

      let infoData = structAgg;

      tx = await sc
        .mint_approved(infoData, amount, {
          value: ethers.utils.parseEther(price.toString()),
        })
        .catch(handleError);
    } else if (userMaxDiscountMintableCard > 0) {
      tx = await sc
        .mintDiscountPresaleWithGalaxis(amount, {
          value: ethers.utils.parseEther(price.toString()),
        })
        .catch(handleError);
    }

    setApproveInProgress(false);
    if (tx) {
      setTxEtherScan(`${config.ETHERSCAN_URL}/tx/${tx.hash}`);
      setTxInProgress(true);
      await tx.wait().catch((e) => {
        handleError(e);
        setTxInProgress(false);
      });
      setTxInProgress(false);
      getSaleInfo();
      setTab(1); //-> wallet

      localStorage.setItem('activeTab', 1);
    }
  };

  const mintRegular = async (amount, price) => {
    console.log(amount * price);
    let sc = saleContract.connect(ethersProvider.getSigner());

    //testmode
    /* 	
		const res = await sc.testMode(1).catch(handleError);

		await res.wait();

		toast.info('done');

		return;

 */

    setShowCheckout(false);
    setApproveInProgress(true);
    const tx = await sc
      .mint(amount, { value: ethers.utils.parseEther(price.toString()) })
      .catch(handleError);
    setApproveInProgress(false);

    if (tx) {
      setTxEtherScan(`${config.ETHERSCAN_URL}/tx/${tx.hash}`);
      setTxInProgress(true);
      await tx.wait().catch((e) => {
        handleError(e);
        setTxInProgress(false);
      });
      setTxInProgress(false);
      getSaleInfo();
      setTab(1); //-> wallet
      localStorage.setItem('activeTab', 1);
    }
  };

  const handleError = (e) => {
    console.error(e);
    if (e.error && e.error.message) {
      toast.error(e.error.message);
    } else if (e.message) {
      toast.error(e.message);
    } else if (e.reason) {
      toast.error(e.reason);
    }
  };

  const resetSale = async () => {
    let sc = saleContract.connect(ethersProvider.getSigner());
    const res = await sc.testMode(1).catch(handleError);
    await res.wait();
    toast.info('done');
  };

  return (
    <>
      <Box sx={sx.infoContainer}>
        <Box sx={sx.badgeContainer} style={{background: !modeCtx.lightTheme && "linear-gradient(rgb(20, 33, 46), rgb(14, 25, 36))"}}>
          <InfoBox label="ITEMS" value={totalAmount} sx={sx.badgeLeft} />
          {minted > 0 && (
            <InfoBox label="SOLD" value={minted} sx={sx.badgeCenter} />
          )}
          <InfoBox
            label="PRICE"
            value={
              mainSaleStarted || mainSaleFinished ? salePrice : discountPrice
            }
            eth
            sx={sx.badgeRight}
          />
        </Box>
      </Box>
      {/* <Box>
			<Button
					variant="banner"
					onClick={() => {resetSale()}}
			>
				RESET SALE

			</Button>
		</Box> */}

      <ButtonGroup
        sx={sx.tabContainer}
        variant="outlined"
        size="medium"
        aria-label="medium outlined button group"
      >
        <Button
          sx={{
            ...sx.tabBtns,
            ...(tab == 0 && sx.tabSelected),
          }}
          onClick={() => {
            setTab(0);
            localStorage.setItem('activeTab', 0);
          }}
        >
          SALE INFO
        </Button>
        <Button
          sx={{ ...sx.tabBtns, ...(tab == 1 && sx.tabSelected) }}
          onClick={() => {
            setTab(1);
            localStorage.setItem('activeTab', 1);
          }}
        >
          WALLET
        </Button>
      </ButtonGroup>

      <SectionDividers />

      {tab === 0 ? (
        <>
          {/* PRESALE */}

          {!preSaleFinished && (
            <>
              <Typography align="center" sx={sx.title}>
                {saleLabels.preSaleTitle}{' '}
                {!preSaleStarted && !preSaleFinished && (
                  <>
                    <Box component="span">is Coming Soon</Box>
                    {!address && (
                      <Typography
                        sx={sx.description}
                        style={{ marginTop: 0 }}
                      ></Typography>
                    )}
                  </>
                )}
                {preSaleStarted && (
                  <>
                    <Box sx={sx.titleLive} component="span">
                      is Live
                    </Box>
                  </>
                )}
                {preSaleFinished && <Box component="span">Ended</Box>}
              </Typography>
              {preSaleStarted && <p>It ends in</p>}

              <Counter
                date={presaleTimeCounter}
                isActive={preSaleStarted}
                onFinish={() => {
                  console.log('presale timer is up');
                  getSaleInfo();
                }}
                id="presale"
              />

              <Box sx={sx.timeContainer}>
                <Timer
                  time={
                    <>
                      <>
                        {presaleStartTime
                          ? new DateTime.fromMillis(presaleStartTime.getTime())
                              .setZone('America/New_York')
                              .toFormat('dd. MM. hha') + ' EDT'
                          : ''}{' '}
                        -{' '}
                        {presaleEndTime
                          ? new DateTime.fromMillis(presaleEndTime.getTime())
                              .setZone('America/New_York')
                              .toFormat('dd. MM. hha') + ' EDT'
                          : ''}
                      </>
                    </>
                  }
                />
              </Box>
              <p style={{ marginTop: '15px', color: '#fe2c85' }}>
                {saleLabels.preSaleDescription}
              </p>
              {preSaleStarted && (
                <Box mt={2}>
                  <Button
                    sx={sx.buyButton}
                    style={{ margin: '10px' }}
                    variant="banner"
                    onClick={handleDiscountMint}
                    disabled={!address || isLoading}
                  >
                    Buy Digital Collectible(s)
                  </Button>
                  {!address && (
                    <Button
                      variant="banner"
                      sx={sx.buyButton}
                      style={{ margin: '10px' }}
                      onClick={handleConnect}
                    >
                      CONNECT WALLET
                    </Button>
                  )}
                </Box>
              )}
            </>
          )}

          {/* SALE */}
          {!mainSaleFinished && (
            <>
              <Typography align="center" mt={5} sx={sx.title}>
                {saleLabels.publicSaleTitle}{' '}
                {!mainSaleStarted && !mainSaleFinished && (
                  <Box component="span">Is Coming Soon</Box>
                )}
                {mainSaleStarted && (
                  <Box sx={sx.titleLive} component="span">
                    Is Live
                  </Box>
                )}
                {mainSaleFinished && <Box component="span">Ended</Box>}
              </Typography>

              {mainSaleStarted && <p>It ends in</p>}

              {preSaleFinished && (
                <Counter
                  date={saleTimeCounter}
                  isActive={mainSaleStarted}
                  onFinish={() => {
                    console.log('sale timer is up');
                    getSaleInfo();
                  }}
                  id="sale"
                />
              )}

              {!mainSaleStarted && !mainSaleFinished && <p>It starts at</p>}
              <Box sx={sx.timeContainer}>
                <Timer
                  time={
                    <>
                      {saleStartTime
                        ? new DateTime.fromMillis(saleStartTime.getTime())
                            .setZone('America/New_York')
                            .toFormat('dd. MM. hha') + ' EDT'
                        : ''}{' '}
                      -{' '}
                      {saleEndTime
                        ? new DateTime.fromMillis(saleEndTime.getTime())
                            .setZone('America/New_York')
                            .toFormat('dd. MM. hha') + ' EDT'
                        : ''}
                    </>
                  }
                />
              </Box>
              <p style={{ marginTop: '15px', color: '#fe2c85' }}>
                {saleLabels.publicSaleDescription}
              </p>
              {mainSaleStarted && (
                <Box mt={2}>
                  <Button
                    sx={sx.buyButton}
                    style={{ margin: '10px' }}
                    variant="banner"
                    onClick={handleMint}
                    disabled={!address || isLoading}
                  >
                    Buy Digital Collectible(s)
                  </Button>
                  {!address && (
                    <Button
                      sx={sx.buyButton}
                      variant="banner"
                      style={{ margin: '10px' }}
                      onClick={handleConnect}
                    >
                      CONNECT WALLET
                    </Button>
                  )}
                </Box>
              )}
              <SectionDividers />
            </>
          )}

          {preSaleFinished && mainSaleFinished && (
            <>
              <Typography align="center" mt={5} sx={sx.title}>
                The sale is over
              </Typography>

              <Typography sx={sx.description}>
                Missed our NFT sale? Check out the Collection on{' '}
                {tokenAddress ===
                '0xeaf10ed6e870ab0743a6bfcb9c414ceff63a3f5f' ? (
                  <Box
                    component="a"
                    href={
                      'https://opensea.io/collection/nfts-for-freedom-genesis-collection'
                    }
                    sx={sx.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    OpenSea
                  </Box>
                ) : (
                  <Box
                    component="a"
                    href={`${config.OPENSEA_URL}${tokenAddress}`}
                    sx={sx.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    OpenSea
                  </Box>
                )}
              </Typography>

              <SectionDividers />
            </>
          )}
        </>
      ) : (
        <>
          <Wallet tokenContract={tokenContract} />
          <SectionDividers />
        </>
      )}

      <PermissionErrorModal
        isOpen={showErrorPopup}
        setOpen={setShowErrorPopup}
      />

      <CheckoutModal
        tokenName={symbol}
        isOpen={showCheckout}
        setOpen={() => {
          if (!txInProgress && !approveInProgress) {
            setShowCheckout(false);
          }
        }}
        isPresale={checkoutIsPresale}
        whitelistLimit={
          checkoutIsPresale
            ? userMaxDiscountMintableWl > 0
              ? userMaxDiscountMintableWl
              : userMaxDiscountMintableCard
            : maxMintableDuringMainSale
        }
        salePrice={salePrice}
        presalePrice={discountPrice}
        mintSale={mintRegular}
        mintPresale={mintDisco}
      />
      <BackdropModal isOpen={approveInProgress} />

      <TxProgressModal isOpen={txInProgress} txEtherScan={txEtherScan} />
    </>
  );
};

export default Sales;
