import React, { useContext, useEffect, useState } from 'react';
import './ExplorerWrapper.css';
import Explorer from './Explorer';
import { getFilteredCards, getFilteredCardsV2, getFiltersV2, getFiltersV2Community } from './Api';
import { getFilters } from './Api';
import { getTraitTypes } from './Api';
import FilterHeader from './FilterHeader';
import { removeFilter } from './ExplorerHelpers';
import { SpinnerCircular } from 'spinners-react';
import { toast } from 'react-toast';
import { Data } from './DataContext';
import CardOverlay from '../Explorer/CardOverlay/CardOverlay';
import { KeyboardReturnOutlined } from '@mui/icons-material';
import { useRef } from 'react';


const customStyles = {
  content: {
	position:'absolute',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};
const ExplorerWrapper = ({projectId, reviewMode, metaDataServer}) => {
  const [filters, setFilters] = useState({});
  const [isDataLoaded, setDataLoaded] = useState(false);
  const [activeFilters, setActiveFilters] = useState(null);
  const [nfts, setNfts] = useState([]);
  const [filtering, setFiltering] = useState(false);
  const [projectName, setProjectName] = useState('Project');
  const [showTokenId, setShowTokenId] = useState(true);
  const [hasError, setHasError] = useState(false)
  const { setTraits, showCardOverlay } = useContext(Data);

  
  const explorerRef = useRef(null);
  

  // get trait and filter types
  useEffect(() => {
    (async () => {
      getTraitTypes().then((res) => {
        setTraits(res);
      });

      //getFiltersV2(projectId);


     /*  getFilters(metaDataServer).then((res) => {
        setFilters(res);
      }); */
    })();
  }, []);

  useEffect(() => {
    const getProjectFilters = async()=>{

      let filterFn = reviewMode?getFiltersV2Community:getFiltersV2;
      const res = await filterFn(projectId).then((res)=>{
        if(res.message === "Filters retrieved successfully."){
          setFilters(res.data);
        }   
      })
      .catch((error)=> {
        if(error.response.status === 422) {
          // console.log(error.response.status)
          setHasError(true);
          return;
        } 
      });

     // console.log('v2 res',res);
      
    }

    if(projectId!=null){
      getProjectFilters();
    }
  }, [projectId]);


  const filterChanged = (keyname, value) => {
    explorerRef.current.scrollIntoView();

    let updatedValue = {};

    //console.log(keyname,value);


    if (keyname === 'MintedOnly' && value === false) {
      delete activeFilters['MintedOnly'];
    } else updatedValue[keyname] = value;

  /*   console.log('activeFilters',activeFilters);
    console.log('updatedValue',updatedValue);

    console.log('updatedFilter',{...activeFilters,...updatedValue});
 */
    setActiveFilters((activeFilters) => ({
      ...activeFilters,
      ...updatedValue,
    }));
  };

  const showToken = (state) => {
    setShowTokenId(state);
  };
  console.log(hasError)
  //filtering - if activefilters object changes we call the api
  useEffect(() => {
    /* if(projectId===null){
      return;
    }
    /// remove the "All" filter before the api call
    let filters = activeFilters ? removeFilter(activeFilters) : {};
    setNfts([]);

    console.log('ACTIVE FILTERS',filters);

    let mf = {sides:{}};

    Object.keys(filters).map((item,idx)=>{
      console.log(item,idx);

      let order = filters[item].layer.order;
      let side =  [filters[item].layer.side.order];
      let dna = filters[item].dna;

      if(!mf['sides'][side]){
        mf['sides'][side]={};
      }

      mf['sides'][side][order]=dna;

    });

    console.log(mf);

    setMappedFilters(mf); */
/* 
    (async () => {
      setFiltering(true);
      getFilteredCardsV2(projectId, mappedFilters,1)
        .then((response) => {

          console.log('NFTS',response)

          if (response.data.nfts.length > 0) {
            setFiltering(false);
            setNfts(response.data.nfts);
            setResultCount(response.data.result_count);
          //  setProjectName(response[0].collection_name);
            setDataLoaded(true);
          } else {
            setFiltering(false);
            setDataLoaded(true);
          }
        })
        .catch((e) => {
          setFiltering(false);
          toast.error(e);
        });
    })(); */

   /*  (async () => {
      setFiltering(true);
      getFilteredCards(metaDataServer, filters)
        .then((response) => {
          if (response.length > 0) {
            setFiltering(false);
            setNfts(response);
            setProjectName(response[0].collection_name);
            setDataLoaded(true);
          } else {
            setFiltering(false);
            setDataLoaded(true);
          }
        })
        .catch((e) => {
          setFiltering(false);
          toast.error(e);
        });
    })(); */


  }, [activeFilters,projectId]);

  return (
    hasError ? <></> :
	<>
          <FilterHeader
            filters={filters}
            filterChanged={filterChanged}
            projectName={projectName}
            showToken={showToken}
          />
          <div ref={explorerRef} className="home">
            <div>
              
                <Explorer
                  projectId={projectId}
                  filtersAreActive={activeFilters}
                  showToken={showTokenId}
                  reviewMode={reviewMode}
                />
   
            </div>
            {/* <Modal isOpen={filtering} style={customStyles}> */}
              {filtering&&
                <div style={{textAlign:'center', marginTop:'30px'}}>
                  <SpinnerCircular size={100} color="#FE2C85" />
                </div>
              }

            
          </div>
          {showCardOverlay && <CardOverlay/>}
    </>
  );
};

export default ExplorerWrapper;
