import React, { useState, useEffect } from 'react';
import { Box, Button } from '@mui/material';
import logo from '../assets/images/header/galaxis-logo-white.png';
import HeaderButton from './HeaderButton';
import { useHistory, useLocation } from 'react-router-dom';

const BP1 = '@media (max-width: 899px)';
const BP2 = '@media (max-width: 401px)';

const sx = {
  root: {
    width: '100%',
    height: '84px',
    position: 'fixed',
    zIndex: 1001,
    px: '20px',
    transition: '0.4s all',
  },
  content: {
    width: '100%',
    maxWidth: '1140px',
    height: '100%',
    mx: 'auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  logoContainer: {
    width: '100%',
    maxWidth: '200px',
    zIndex: '21',
    '& img': {
      width: '100%',
    },
    [BP2]: {
      maxWidth: '130px',
    },
  },
  text: {
    '&:disabled': {
      color: '#fe2c85',
    },
  },
  btnContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  btn: {
    fontFamily: 'poppins',
    fontSize: '20px',
    color: '#FFF',
  },
  mobileContainer: {
    display: 'none',
    [BP1]: {
      display: 'block',
      gap: '30px',
    },
  },
  headerRoot: {
    // transition: '3s ease-out',
    position: 'fixed',
    width: '100%',
    pl: '20px',
    zIndex: '1001',
    maxWidth: '1272px',
    height: '124px',
    mx: 'auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  collapsedMenu: {
    position: 'fixed',
    zIndex: '2222',
    width: '100%',
    mt: '100px',
    pt: '10px',
    minHeight: '150px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    backgroundColor: '#FAF9F5',
    px: '20px',
    paddingBottom: '25px',
  },
  menuContainer: {
    height: '50px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    gap: '9px 0',
    zIndex: '1',
  },
  menuList: {
    textAlign: 'center',
    backgroundColor: '#FFFFFF',
    width: '155px',
    height: '168px',
    position: 'absolute',
    fontSize: '16px',
    boxShadow: '2px 2px 2px 3px rgba(0, 0, 0, 0.2)',
    borderRadius: '6px',
    pt: '10px',
    pb: '0',
  },
  socialContainer: {
    maxHeight: '30px',
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    px: '5px',
    pt: '8px',
  },
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    p: '10px',
    zIndex: '2223',
  },
  ulBox: {
    mt: '36px',
    borderRadius: '10px',
  },
  rootHorizontal: {
    height: '45px',
    px: '6.5px',
    pt: '10px',
    textTransform: 'uppercase',
    [BP1]: {
      px: '5px',
    },
    '& ul': {
      display: 'none',
      position: 'absolute',
      margin: '0',
      padding: '0',
    },
    '& li': {
      listStyle: 'none',
      height: '30px',
      '&:hover': {
        '& span': {
          color: '#FC6C03!important',
          fontWeight: 700,
        },
      },
      '& div': {
        padding: '10px',
      },
    },
    '&:hover': {
      '& ul': {
        display: 'block',
      },
    },
  },
  text: {
    fontFamily: 'poppins',
    fontSize: '18px',
    color: '#111317',
    textAlign: 'right',
    lineHeight: '21px',
    '&:hover': {
      color: '#FC6C03!important',
      fontWeight: '700',
    },
    // '&:hover': {
    //   color: '#fe2c85',
    // },
  },
  rootDisabled: {
    color: '#FC6C03',
    fontWeight: 700,
  },
  disconnectButton: {
    minHeight: '40px',
    height: '40px',
    width: '120px',
    borderRadius: '5px',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    backgroundColor: 'unset',
    border: '2px solid #fe2c85',
    color: '#fe2c85',
    marginTop: '0',
    marginLeft: '5px',
    '&:hover': {
      border: '2px solid #fe2c85',
      backgroundColor: '#fe2c85',
      color: '#ffffff',
    },
  },
};
const BUTTONS = ['Communities'];
const PATHS = ['/communities'];

const Header = ({ clickedToTheSameLink, address, disconnect }) => {
  const location = useLocation();
  const history = useHistory();
  const [headerBackground, setHeaderBackground] = useState('initial');
  const [prevScrollpos, setPrevScrollpos] = useState(0);
  const [activeTab, setActiveTab] = useState(null);

  const handleNavigation = (path) => {
    if (PATHS[path] === '/' + location.hash) {
      clickedToTheSameLink();
    }
    //  console.log(PATHS[path], location.hash);
    if (location.pathname !== path) {
      history.push(path);
      history.push(PATHS[path]);
    }
  };
  const handleMouseOver = (path) => {
    setActiveTab(path);
  };
  const handleMouseLeave = () => {
    if (location.pathname == '/communities') {
      setActiveTab(0);
    } else {
      setActiveTab(null);
    }
  };
  useEffect(() => {
    if (location.pathname == '/communities') {
      setActiveTab(0);
    } else {
      setActiveTab(null);
    }
  }, [location.hash]);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      if (prevScrollpos < 60) {
        setHeaderBackground('initial');
      } else {
        setHeaderBackground('#050d15ab');
      }
      setPrevScrollpos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollpos]);
  return (
    <>
      <Box
        sx={sx.root}
        style={{
          backgroundColor: headerBackground,
        }}
      >
        <Box sx={sx.content}>
          <Box
            onClick={() => handleNavigation(0)}
            sx={sx.logoContainer}
            style={{ cursor: 'pointer' }}
          >
            <img src={logo} alt='Galaxis' />
          </Box>
          <Box sx={sx.btnContainer}>
            <Box sx={sx.rootHorizontal} style={{ cursor: 'pointer' }}></Box>
            {location.pathname == '/communities'
              ? null
              : BUTTONS.map((btn, i) => {
                  return (
                    <HeaderButton
                      key={btn}
                      active={activeTab === i}
                      text={btn}
                      onClick={() => handleNavigation(i)}
                      onMouseOver={() => handleMouseOver(i)}
                      onMouseLeave={handleMouseLeave}
                    />
                  );
                })}
            {address && disconnect && (
              <Button
                variant='banner'
                sx={sx.disconnectButton}
                onClick={() => disconnect()}
              >
                Disconnect
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Header;
