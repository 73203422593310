import React, { useContext, useState } from 'react';
export const Data = React.createContext({});
const DataContext = (props) => {
  const [traitTypes, setTraitTypes] = useState([]);
  const [showCardOverlay, setShowCardOverlay] = useState(false);
  const [chosenCard, setChosenCard] = useState({});

  const showOverlayForCard = (bool) => {
    //console.log('CCCCCAAARD');
    setShowCardOverlay(bool);
  };

  const setTraits = (traits) => {
    setTraitTypes(traits);
  };

  const setTheChosenCard = (card) => {
    setChosenCard(card)
  };
  return (
    <Data.Provider
      value={{
        traitTypes,
        setTraits,
        showCardOverlay,
        showOverlayForCard,
        chosenCard,
        setTheChosenCard,
      }}
    >
      {props.children}
    </Data.Provider>
  );
};

export default DataContext;
