import { Button, FormControl, MenuItem, Select, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import close from '../assets/images/close.svg';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import './EmailPopup.css';
import axios from 'axios';
import config from '../config/config';
import { toast } from 'react-toast';
const BP1 = '@media (max-width: 490px)';
import { ModeCtx } from '../context/modeCtx';
import { useContext } from 'react';

const sx = {
  root: {
    maxWidth: '553px',
    maxHeight: '710px',
    width: '100%',
    height: '100%',
    borderRadius: '20px',
    backgroundColor: '#FFFFFF',
    position: 'relative',
    p: '20px',
    overflow: 'auto',
  },
  close: {
    position: 'absolute',
    top: '35px',
    right: '31px',
    cursor: 'pointer',
    [BP1]: {
      top: '15px',
      right: '15px',
    },
  },
  title: {
    fontSize: '38px',
    fontFamily: 'Poppins',
    textAlign: 'center',
    color: '#ffff!important',
    mt: '25px',
    mb: '30px',
    [BP1]: {
      marginBottom: '15px',
    },
  },
  formBox: {
    display: 'flex',
    justifyContent: 'center',
  },
  form: {
    height: '500px',
    width: '377px',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    '.MuiFormLabel-asterisk': {
      display: 'none',
    },
  },
  textarea: {
    [BP1]: {
      marginBottom: 'unset',
    },
  },
  option: {
    borderRadius: '15px',
    marginBottom: '5px',
    '&:hover': {
      backgroundColor: '#000',
    },
  },
  inputText: {
    color: 'rgba(255, 255, 255, 0.7)',
    fontSize: '12px',
    padding: '5px',
    paddingBottom: '10px',
    opacity: 0.8,
  },
  button: {
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '8px',
    fontFamily: 'poppins-semibold',
    fontSize: '14px',
    color: '#fe2c85',
    height: '40.5px',
    border: '2px solid #fe2c85',
    mt: '20px',
    '&:hover': {
      border: '2px solid #fe2c85',
      backgroundColor: '#fe2c85',
      color: '#FAF9F5',
    },
    [BP1]: {
      mt: '15px',
    },
    ['disabled']: {
      backgroundColor: '#fe2c8580',
    },
    '&:disabled': {
      backgroundColor: '#fe2c8580',
    },
  },
  recapcha: {
    fontSize: '12px',
    fontFamily: 'Poppins',
    lineHeight: '15px',
    mt: '15px',
    color: '#fff !important',
    '& a': {
      textDecoration: 'none',
      color: '#fe2c85',
    },
  },
  dropArrow: {
    position: 'absolute',
    top: '25px',
    right: '15px',
    color: '#fe2c85',
  },
  error: {
    fontSize: '10px',
    fontFamily: 'Poppins',
    color: '#F50808',
  },
  darkForm: {
    px: '20px',
    color: '#fff',
    '& input': {
      backgroundColor: 'transparent',
      color: '#fff',
      '&::placeholder': {
        color: '#fff9',
      },
    },
    ul: {
      background: 'linear-gradient(rgb(20, 33, 46), rgb(14, 25, 36))',
    },
    li: {
      '&:hover': {
        background: 'linear-gradient(rgb(20, 33, 46), rgb(14, 25, 36))',
      },
    },
    [BP1]: {
      px: 'unset',
    },
  },
};

const OPTIONS = [
  {
    id: 1,
    title: 'General Enquiry',
  },
  {
    id: 2,
    title: 'Launch NFT Project',
  },
  {
    id: 3,
    title: 'Partnership',
  },
];

const EmailPopup = ({ onClose, isSubscribe }) => {
  const [initialValue, setInitialValue] = useState({
    name: '',
    email: '',
    message: '',
    subject: OPTIONS[0].title,
  });

  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [displayValue, setDisplayValue] = useState('');
  const [loading, setLoading] = useState(false);
  const modeCtx = useContext(ModeCtx);
  useEffect(() => {
    if (isSubscribe && OPTIONS.length === 3) {
      OPTIONS.push({
        id: 4,
        title: 'Beta test application',
      });
      setInitialValue({
        name: '',
        email: '',
        message: '',
        subject: OPTIONS[3].title,
      });
    }
  }, [isSubscribe]);

  const handleOnClick = (e) => {
    setLoading(true);
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute(config.RECAPCHA_SITEKEY, { action: 'submit' }).then((token) => {
        formikHandleSubmit(token, formik.values);
      });
    });
  };
  const formikHandleSubmit = (token, values) => {
    let tmpmessage = values.message;
    let message = tmpmessage.replace(/\n/g, '<br>\n');
    let body = {
      name: values.name,
      email: values.email,
      subject: values.subject,
      message: message,
      token: `${token}`,
    };
    axios
      .post(`${config.API_URL}/email`, body, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        console.log(res);
        toast.success('Email sent successfully.');
        onClose();
      })
      .catch((error) => {
        console.log(error);
        toast.error('Something went wrong, sorry.');
        onClose();
      });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValue,
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .min(3, 'The name must contain minimum 3 characters')
        .max(37, 'The name is longer than 37 characters')
        .required('Name is required'),
      email: Yup.string().required('The email is required.').email('Invalid email format.'),
      message: Yup.string()
        .required('The message is required.')
        .min(10, 'The message must contain minimum 10 characters')
        .max(3000, 'The message is longer than 3000 characters'),
      subject: Yup.string().required('The subject is required'),
    }),
    validateOnBlur: false,
    validateOnChange: true,
    onChange: (v) => {
      console.log('CHANGEVALS', v.values);
    },
    onSubmit: handleOnClick,
  });

  return (
    <Box
      sx={sx.root}
      style={{
        background: modeCtx.lightTheme ? '#fff' : 'rgb(21, 30, 42)',
        backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16))',
      }}
      onClick={() => {
        if (dropdownVisible) setDropdownVisible(!dropdownVisible);
      }}
    >
      <Box
        sx={sx.close}
        onClick={() => {
          onClose();
        }}
      >
        <img src={close} fill='#fff' alt='anything' className='modal-close' />
      </Box>
      <Typography sx={sx.title}>Contact Us</Typography>

      <Box sx={sx.formBox}>
        <form
          onSubmit={formik.handleSubmit}
          style={{
            ...sx.form,
          }}
        >
          <TextField
            required
            className='common-input'
            type='text'
            label={
              <Typography sx={sx.inputText} variant='main'>
                NAME
              </Typography>
            }
            name='name'
            value={formik.values.name}
            onChange={(e) => {
              formik.handleChange(e);
            }}
            variant='outlined'
          />
          <TextField
            requireds
            className='common-input'
            type='email'
            variant='outlined'
            label={
              <Typography sx={sx.inputText} variant='main'>
                EMAIL
              </Typography>
            }
            name='email'
            value={formik.values.email}
            onChange={(e) => {
              formik.handleChange(e);
            }}
          />
          <FormControl fullWidth variant='outlined'>
            <Select
              className='common-input'
              required
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
              value={formik.values.subject}
              onChange={(e) => {
                formik.setFieldValue('subject', e.target.value);
              }}
            >
              {OPTIONS.map((subject, index) => (
                <MenuItem key={index} value={subject.title} sx={{ color: 'white !important' }}>
                  {subject.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            required
            multiline
            name='message'
            className='common-input'
            label={
              <Typography sx={sx.inputText} variant='main'>
                MESSAGE
              </Typography>
            }
            id='messsage'
            sx={{
              '.MuiInputBase-root': {
                height: '180px',
                '> .MuiInputBase-input': {
                  height: '100% !important',
                },
              },
            }}
            value={formik.values.message}
            onChange={(e) => {
              formik.handleChange(e);
            }}
            onBlur={formik.handleBlur}
          />
          <Typography sx={sx.recapcha} variant='bannerText'>
            This site is protected by reCAPTCHA and the Google
            <a href='https://policies.google.com/privacy'> Privacy Policy</a> and
            <a href='https://policies.google.com/terms'> Terms of Service</a> apply.
          </Typography>
          <Button sx={sx.button} type='submit' disabled={loading ? true : false}>
            SEND MESSAGE
          </Button>
        </form>
      </Box>
    </Box>
  );
};

export default EmailPopup;
