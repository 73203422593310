import { Box, Typography } from '@mui/material';
import axios from 'axios';
import React, { Suspense, lazy, useEffect, useState } from 'react';
import leftTrick from '../../assets/images/leftTrick.svg';
import rightTrick from '../../assets/images/rightTrick.svg';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import config from '../../config/config';

const BP6 = '@media (max-width: 612px)';

const sx = {
  root: {
    position: 'relative',
    width: '100%',
    pt: '150px',
    pb: '115px',
    mb: '100px',
    overflow: 'hidden',
    transition: 'all .3s',
    px: '20px',
  },
  pageContainer: {
    maxWidth: '1140px',
    margin: 'auto',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  title: {
    maxWidth: '600px',
    fontSize: '36px',
    lineHeight: '38px',
    mx: 'auto',
    mb: '40px',
    textAlign: 'center',
    [BP6]: {
      maxWidth: '300px',
      fontSize: '25px',
      lineHeight: '28px',
    },
  },
  description: {
    mx: 'auto',
    textAlign: 'center',
    maxWidth: '608px',
    marginTop: '40px',
    marginBottom: '40px',
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    wordBreak: 'break-word',
    '& span': {
      cursor: 'pointer',
      color: '#fe2c85',
    },
  },
  buttonBox: {
    mt: '50px',
    display: 'flex',
    justifyContent: 'center',
    gap: '15px',
    flexWrap: 'wrap',
    marginBottom: '15px',
  },
  button: {
    border: '2px solid #FA4D05',
    color: '#FA4D05',
    px: '20px',
    py: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'fit-content',
    minHeight: '45px',
    [':hover']: {
      border: '2px solid #FA4D05',
      color: '#FFF',
      backgroundColor: '#FA4D05',
    },
  },
};

const ProjectSection = lazy(() => import('../Home/ProjectSection'));
const OurProjects = () => {
  const [cardViewUpcoming, setCardViewUpcoming] = useState(false);
  const [numberOfCards, setNumberOfCards] = useState(4);
  const [mdGrid, setMdGrid] = useState(3);
  const [upcoming, setUpcoming] = useState([]);
  const [ongoing, setOngoing] = useState([]);
  useEffect(() => {
    (async function () {
      window.scrollTo(0, 0);
      let upcoming = [];
      let ongoingProjects = [];
      let community = [];
      if (window.innerWidth < 1140) {
        setNumberOfCards(3);
        setMdGrid(4);
      }
      if (window.innerWidth < 896) {
        setNumberOfCards(2);
      }
      if (window.innerWidth < 600) {
        setNumberOfCards(1.1);
        setCardViewUpcoming(true);
      }
      await axios.get(`${config.API_URL}/projects`).then((res) => {
        if (res.data.live) {
          ongoingProjects = res.data.live;
          // setOngoing(res.data.live)
        }
        if (res.data.upcoming) {
          // setUpcoming(res.data.upcoming);
          upcoming = res.data.upcoming;
        }
        if (res.data.community) {
          community = res.data.community;
        }
        let array = [...ongoingProjects, ...upcoming, ...community];
        setUpcoming(array);
      });
    })();
  }, []);

  const SectionDividers = () => (
    <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
      <img src={leftTrick} className='section-divider-img' />
      <img src={rightTrick} className='section-divider-img' />
    </Box>
  );
  const handleNavigation = () => {
    window.open(`https://dust.xyz/`, '_blank');
  };

  return (
    <>
      <Header />
      <Box sx={sx.root} id='homeContainer'>
        <Box sx={sx.pageContainer}>
          <div className='' style={{ marginTop: '0px' }}>
            {/* <SectionDividers /> */}
          </div>
          <Typography
            id='upcoming'
            variant='bannerTitle'
            textAlign='center'
            sx={sx.title}
            style={{
              maxWidth: '100%',
              position: 'relative',
              justifyContent: 'space-between',
              // marginTop: '30px',
              padding: '0 23px',
            }}
          >
            Communities
          </Typography>

          {/* <Typography variant='bannerText' sx={sx.description}>
            Galaxis communities utilize the Digital Collectible toolkit to create
            and manage dynamic collections. The diverse suite of products
            include: the Token Architect, Utility Traits, Community Space,
            Mission Control and access to the{' '}
            <span onClick={handleNavigation}> $DUST </span> Utility Token.
          </Typography> */}
          {/* <Suspense fallback={<div></div>}>
                        <ProjectSection
                            cardView={cardViewUpcoming}
                            collections={ongoing}
                            numberOfCards={numberOfCards}
                            styleClass="upcoming"
                        />
                    </Suspense> */}
          <Suspense fallback={<div></div>}>
            <ProjectSection
              cardView={cardViewUpcoming}
              collections={upcoming}
              numberOfCards={numberOfCards}
              styleClass='upcoming'
            />
          </Suspense>

          {/* <Typography
                        id="upcoming"
                        variant="bannerTitle"
                        textAlign="center"
                        sx={sx.title}
                        style={{
                            maxWidth: '100%',
                            position: 'relative',
                            justifyContent: 'space-between',
                            // marginTop: '30px',
                            padding: '0 23px',
                        }}
                    >
                        Ongoing Projects
                    </Typography>

                    <Typography variant="bannerText" sx={sx.description}>
                    Galaxis highly values our diverse ecosystem of partner projects. We offer both development and marketing resources to increase probability of a successful launch. Below is a list of upcoming collaborations.
                    </Typography> */}
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default OurProjects;
